var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"order-page"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"8"}},[(_vm.hasError)?_c('div',{staticClass:"q-card order-card mt-2"},[_c('div',{staticClass:"order-card__head-date"},[_c('div',{staticClass:"order-card__head-hour"},[_vm._v(" "+_vm._s(_vm.$t("Order_tech_error"))+" ")])]),_c('div',{staticClass:"order-card__issue"},[_vm._v(" "+_vm._s(_vm.$t("Order_popup_error"))+" ")]),_c('div',{staticClass:"order-card__footer q-desktop-only"},[_c('button',{staticClass:"q-btn q-btn-red"},[_vm._v(" "+_vm._s(_vm.$t("Order_btn2cabinet"))+" ")]),_c('button',{staticClass:"q-btn"},[_vm._v(_vm._s(_vm.$t("Order_btn2back")))])])]):_c('order-card')],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"q-card order-page__call"},[_c('div',[_vm._v(_vm._s(_vm.$t("Order_have_question")))]),_c('a',{attrs:{"href":"tel:77001101059"}},[_vm._v("+7 700 110 10 59")]),_c('a',{staticClass:"q-btn q-btn-green",attrs:{"href":"https://api.whatsapp.com/send/?phone=77471111818&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C+%D1%82%D0%BE%D1%80%D1%82&type=phone_number&app_absent=0","target":"_blank"}},[_vm._v("+7 (747) 111 18 18")])]),_c('div',{staticClass:"q-card order-page__status-wrap"},[(!_vm.hasError && _vm.order.status_id !== 12)?_c('div',{staticClass:"order-page__status"},[_c('div',{staticClass:"order-page__status-title"},[_vm._v(" "+_vm._s(_vm.$t("Order_status"))+" ")]),_c('div',{staticClass:"order-page__status-item",class:{
              'order-page__status-item-current': _vm.order.status_id === 2,
              'order-page__status-item-green':
                _vm.order.status_id > 2 && !_vm.canceled,
            }},[_c('div',{staticClass:"order-page__status-icon order-page__status-icon-step1"}),_c('div',{staticClass:"order-page__status-name"},[_vm._v(" "+_vm._s(_vm.$t("Order_accept"))+" "),(_vm.order.payment?.type != 'cash' && !_vm.order.payed_at)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Order_OrderTimeline_Order_Wait_Pay"))+" ")]):(_vm.order.payment?.type != 'cash' && _vm.order.payed_at)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Order_OrderTimeline_Order_Payed"))+" ")]):_vm._e()])]),_c('div',{staticClass:"order-page__status-item",class:{
              'order-page__status-item-current': _vm.order.status_id === 4,
              'order-page__status-item-green': _vm.order.status_id > 4,
              'order-page__status-item-red': _vm.canceled,
            }},[_c('div',{staticClass:"order-page__status-icon order-page__status-icon-step2"}),_c('div',{staticClass:"order-page__status-name"},[_vm._v(" "+_vm._s(_vm.canceled ? _vm.$t("Order_cancelled") : _vm.$t("Order_waitcooking"))+" ")])]),(!_vm.canceled)?[_c('div',{staticClass:"order-page__status-item",class:{
                'order-page__status-item-current': _vm.order.status_id === 5,
                'order-page__status-item-green': _vm.order.status_id > 5,
              }},[_c('div',{staticClass:"order-page__status-icon order-page__status-icon-step3"}),_c('div',{staticClass:"order-page__status-name"},[_vm._v(" "+_vm._s(_vm.$t("Order_ready"))+" ")])]),(_vm.order.is_delivery)?_c('div',{staticClass:"order-page__status-item",class:{
                'order-page__status-item-current': _vm.order.status_id === 7,
                'order-page__status-item-green': _vm.order.status_id > 7,
              }},[_c('div',{staticClass:"order-page__status-icon order-page__status-icon-step4"}),_c('div',{staticClass:"order-page__status-name"},[_vm._v(" "+_vm._s(_vm.$t("Order_delivery"))+" ")])]):_vm._e(),_c('div',{staticClass:"order-page__status-item",class:{
                'order-page__status-item-current': _vm.order.status_id === 8,
                'order-page__status-item-green': _vm.order.status_id > 8,
              }},[_c('div',{staticClass:"order-page__status-icon order-page__status-icon-step5"}),_c('div',{staticClass:"order-page__status-name"},[_vm._v(" "+_vm._s(_vm.order.is_delivery ? _vm.$t("Order_delivered") : _vm.$t("Order_closed"))+" ")])])]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"order-card__footer q-mobile-only mt-4"},[_c('router-link',{staticClass:"q-btn q-btn-red",attrs:{"to":"/cabinet"}},[_vm._v(_vm._s(_vm.$t("Order_cabinet_02")))]),_c('router-link',{staticClass:"q-btn",attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("Order_back2menu")))])],1)])])],1),(false)?_c('b-row',{staticClass:"justify-content-center mt-5"},[_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('div',{staticClass:"q-card order-success"},[_c('div',{staticClass:"order-success__title"},[_vm._v(_vm._s(_vm.$t("Order_thankyou")))]),_c('div',{staticClass:"order-success__text"},[_vm._v(_vm._s(_vm.$t("Order_payed")))]),_c('button',{staticClass:"q-btn order-success__print-check"},[_vm._v(" "+_vm._s(_vm.$t("Order_check"))+" ")]),_c('button',{staticClass:"q-btn q-btn-teal"},[_vm._v(_vm._s(_vm.$t("Order_statusses")))])])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }