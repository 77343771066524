import Vue from 'vue'
import Vuex from 'vuex'

//modules
import auth from "@/store/modules/auth";
import products from "@/store/modules/products";
import cart from "@/store/modules/cart";
import reviews from "@/store/modules/reviews";
import carousel from "@/store/modules/carousel";
import articles from "@/store/modules/articles";
import filial from "@/store/modules/filial";
import paymentTypes from "@/store/modules/paymentTypes";
import stopList from "@/store/modules/stopList";
import order from "@/store/modules/order";
import loader from '@/store/modules/loader'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    products,
    cart,
    reviews,
    carousel,
    articles,
    filial,
    paymentTypes,
    stopList,
    order,
    loader
  }
})
