<template>
  <b-container>
    <b-row>
      <b-col>
        <div class="page-404">
          <div class="page-404__img">
            <img src="@/assets/images/img_404.png" alt="404">
          </div>
          <h3 class="page-404__title">{{ $t('NotFound_none_page') }}</h3>
          <div class="page-404__button">
            <router-link to="/" class="q-btn q-btn-teal">{{ $t('NotFound_btn_to_main') }}</router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
    name: "NotFound",
    metaInfo: {
      title: 'Я заблудился.... | Доставка кондитерских изделий в Алмате',
      meta: [
        { vmid: 'description', property: 'description', content: 'Qulpynai кабинет' },
        { vmid: 'og:title', property: 'og:title', content: 'Cabinet Qulpynai' },
        { vmid: 'og:description', property: 'og:description', content: 'Qulpynai кабинет' },
      ],
    },
}
</script>
