<template>
  <b-modal
    ref="customModal"
    id="customModal"
    size="xl"
    hide-header
    hide-footer
    body-class="custom-modal"
  >
    <div class="custom-modal__close-button" @click="hideModal"></div>
    <b-row>
      <b-col cols="12">
        <div class="custom-modal__info">
          <div class="custom-modal__back" @click="hideModal">
            {{ $t('Modals_CustomModal_back') }}
          </div>
          <div class="custom-modal__info-head">
            <div class="custom-modal__title">
              {{ $t('Modals_CustomModal_modifier') }}
            </div>
          </div>
          <div class="custom-modal__item">
            <div class="custom-modal__item-img q-card">
              <img
                v-if="product.images && product.images[0]"
                :src="product.images[0].img"
                :alt="product.name"
              >
            </div>
            <div class="custom-modal__item-content">
              <div class="custom-modal__item-title">
                {{ product.name }}
              </div>
              <div class="custom-modal__item-description">
                <div class="custom-modal__item-description-text">
                  {{ product.description }}
                </div>
                <div class="custom-modal__item-price">{{ numberWithSpaces(product.price) }} ₸</div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="custom-modal__body">
          <div class="custom-modal__input">
            <div class="custom-modal__input-title">
              {{ $t('Modals_CustomModal_inscription_cake') }}
            </div>
            <div
              :class="{
              'custom-modal__input-field-error': inputOk === false,
              'custom-modal__input-field-ok': inputOk === true,
              }"
              class="custom-modal__input-field"
            >
              <input
                v-model="inputText"
                :disabled="!editable"
                type="text"
                :placeholder="$t('Modals_CustomModal_write2text')"
              >
              <button
                class="q-btn q-desktop-only custom-modal__input-field-btn"
                @click="checkSize"
              >
                {{ $t('Modals_CustomModal_check2text_01') }}
              </button>
            </div>
            <div class="custom-modal__input-warning">
              <span>{{ $t('Modals_CustomModal_attention_01') }}</span>
              {{ $t('Modals_CustomModal_attention_02') }}
            </div>
            <div class="">
              <button
                class="q-btn q-mobile-only custom-modal__input-field-btn"
                @click="checkSize"
              >
                {{ editable ? $t('Modals_CustomModal_check2text_02') : $t('Modals_CustomModal_change2text_01') }}
              </button>
            </div>
          </div>
          <div class="custom-modal__price">
            <div class="custom-modal__price-key">
              {{ $t('Modals_CustomModal_price_for_modifier') }}
            </div>
            <div class="custom-modal__price-value">
              500 ₸
            </div>
          </div>
          <div class="custom-modal__total">
            <div class="custom-modal__total-key">{{ $t('Modals_CustomModal_price') }}</div>
            <div class="custom-modal__total-value">{{ numberWithSpaces(product.price + 500) }} ₸</div>
          </div>
          <div v-if="!product.is_special" class="custom-modal__buttons">
            <button
              class="q-btn mr-2"
              @click="buyNow"
            >
              {{ $t('Modals_CustomModal_buy2cart') }}
            </button>
            <button
              class="q-btn q-btn-teal"
              @click="addProduct"
            >
              {{ $t('Modals_CustomModal_add2cart') }}
            </button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup";
import { mapState } from "vuex";

export default {
    name: "CustomModal",
    components: {
        FormGroup,
    },
    data() {
        return {
            inputText: '',
            inputOk: null,
            editable: true,
        }
    },
    computed: {
        ...mapState({
            product: state => state.products.modalProduct,
        }),
    },
    methods: {
        async addProduct() {
            try {
                await this.$store.dispatch('cart/addProduct', this.product);
                this.$bvModal.hide('customModal');
                this.$bvModal.hide('productModal');
            } catch (e) {
                console.error(e);
            }
        },
        buyNow() {
            try {
                this.addProduct();
            } finally {
                this.$router.push('/cart');
                this.$bvModal.hide('customModal');
                this.$bvModal.hide('productModal');
            }
        },
        checkSize() {
            if (!this.editable) {
                this.editable = true;
                return;
            }
            this.inputOk = this.inputText.length < 15;
            this.editable = !this.inputOk;
        },
        hideModal() {
            this.$bvModal.hide('customModal');
        },
    },
}
</script>
