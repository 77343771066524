<template>
  <div class="cart-item">
<!--    <img src="@/assets/images/product-3.png" alt="cart-item" class="cart-item__img">-->
    <div class="cart-item__img">
      <img v-if="product.image" :src="product.image" :alt="product.name">
      <img v-else src="@/assets/images/default_product.png" :alt="product.name">
    </div>
    <div class="cart-item__body">
      <div class="cart-item__body-line">
        <div class="cart-item__title">{{ product.name }}</div>
        <div class="cart-item__clear" @click="removeProduct"></div>
      </div>
      <div class="cart-item__body-line">
        <div class="cart-item__count">
          <div
            class="cart-item__count-decrease"
            @click="decreaseProduct"
          >-</div>
          <div class="cart-item__count-value">{{ product.quantity }}</div>
          <div
            class="cart-item__count-increase"
            @click="addProduct"
          >+</div>
        </div>
        <div class="cart-item__price">{{ numberWithSpaces(product.quantity * product.price) }} ₸</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "CartItem",
    props: {
        product: Object,
    },
    methods: {
        addProduct() {
            this.$store.dispatch('cart/addProduct', this.product);
        },
        decreaseProduct() {
            this.$store.dispatch('cart/decrementProduct', this.product);
        },
        removeProduct() {
            this.$store.dispatch("cart/removeProduct", this.product);
        },
    },
}
</script>
