<template>
  <b-col cols="12">
    <div class="q-card about-us__card">
      <b-row>
        <b-col cols="12" lg="6">
          <div class="about-us__paragraph">
            {{ $t('about_We_aboutUs_p1') }}
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div class="about-us__paragraph">
            {{ $t('about_We_aboutUs_p2') }}
          </div>
        </b-col>
      </b-row>
      <carousel />
      <b-row class="about-us__points">
        <b-col cols="12" lg="4">
          <div class="about-us__point">
            <div class="about-us__point-img"></div>
            <div class="about-us__point-title">
              {{ $t('about_We_point_title_01') }}
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="4">
          <div class="about-us__point">
            <div class="about-us__point-img about-us__point-img-delivery"></div>
            <div class="about-us__point-title">
              {{ $t('about_We_point_title_02') }}
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="4">
          <div class="about-us__point">
            <div class="about-us__point-img about-us__point-img-discount"></div>
            <div class="about-us__point-title">
              {{ $t('about_We_point_title_03') }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="about-us__half-image align-items-center">
        <b-col cols="12" lg="6">
          <div class="about-us__image-block">
            <img src="@/assets/images/about-1.jpg" alt="about-1">
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div class="about-us__paragraph">
            {{ $t('about_We_aboutUs_p3') }}
          </div>
          <div class="about-us__paragraph">
            {{ $t('about_We_aboutUs_p4') }}
          </div>
        </b-col>
      </b-row>

    </div>
  </b-col>
</template>

<script>
import Carousel from "./AboutCarousel.vue";

export default {
    name: "We",

    metaInfo: {
      title: 'О нас | Доставка кондитерских изделий в Алмате',
      meta: [
        { vmid: 'description', property: 'description', content: 'Qulpynai о компании' },
        { vmid: 'og:title', property: 'og:title', content: 'About us Qulpynai' },
        { vmid: 'og:description', property: 'og:description', content: 'Qulpynai о компании' },
      ],
    },

  components: {
        Carousel
    },
}
</script>
