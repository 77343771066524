<template>
  <div class="signup-form">
    <form
        v-if="stage === 1"
        @submit.prevent="submit"
    >
      <form-group
          v-for="(input, i) in inputs"
          :key="`sign-up-input__${i}`"
          v-model="form[input.name]"
          :label="$t(input.label)"
          :error="(errors[input.name] || [])[0]"
          :placeholder="$t(input.placeholder)"
          :type="input.type"
      />
      <button
          :disabled="loading"
          class="q-btn q-btn-teal auth-form__submit"
      >
        {{ $t('Auth_SignupForm_btn2register') }}
      </button>
    </form>
    <form v-if="stage === 2">
        <PhoneApproveForm @closeDropdown="$emit('closeDropdown')" :phone="form.phone"></PhoneApproveForm>
    </form>
  </div>
</template>

<script>
import FormGroup from "../FormGroup/FormGroup";
import PhoneApproveForm from "@/components/Auth/PhoneApproveForm.vue";

export default {
  name: "SignupForm",
  components: {
    FormGroup,
    PhoneApproveForm
  },
  data() {
    return {
      loading: false,
      stage: 1,
      form: {},
      errors: {},
      inputs: [
        {
          name: 'name',
          label: 'Auth_SignupForm_your_name',
          placeholder: 'Auth_SignupForm_write_name',
          type: 'input'
        },
        {
          name: 'phone',
          label: 'Auth_SignupForm_your_number',
          placeholder: '',
          type: 'phone',
        },
        // {
        //     name: 'email',
        //     label: 'Email',
        //     placeholder: 'Электронная почта',
        //     type: 'input',
        // },
        // {
        //     name: 'birthday',
        //     label: 'Дата рождения',
        //     placeholder: 'Введите дату',
        //     type: 'date',
        // },
      ],
    }
  },
  methods: {
    async submit() {
      let {form} = this;
      form.phone = (form.phone || '').replace(/\D+/g, "");
      form.is_registration = true
      this.loading = true;
      try {
        await this.$store.dispatch("auth/register", form);
        this.stage = 2
      } catch (error) {
        this.errors = ((error.response || {}).data || {}).errors || {};
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
