<template>
  <div class="mainNavAuth">
    <b-dropdown
      v-if="renderComponent"
      id="dropdown-form"
      ref="authDropdown"
      :class="{'auth-form__active': dropdownActive, 'user-dropdown': userAuthorized}"
      toggle-class="q-btn q-btn-skyblue auth-form__btn"
      no-caret
      variant="link"
      class="auth-form"
      style="z-index:10"
      right
      no-flip
      @shown="dropdownActive = true"
      @hidden="dropdownActive = false"
    >
      <template #button-content>
        {{ (currentUser && currentUser.name) || $t('MainNav_Auth_enter') }}
      </template>
      <div v-show="!userAuthorized">
        <div class="q-tab auth-form__tab">
          <div
            :class="{'q-tab__item-active': state === 'login'}"
            class="q-tab__item"
            @click="changeState('login')"
          >{{ $t('MainNav_Auth_login') }}</div>
          <div
            :class="{'q-tab__item-active': state === 'signup'}"
            class="q-tab__item"
            @click="changeState('signup')"
          >{{ $t('MainNav_Auth_register') }}</div>
        </div>
        <login-form
          v-if="state === 'login'"
          @closeDropdown="closeDropdown"
        />
        <signup-form
          v-else-if="state === 'signup'"
          @changeState="changeState"
        />
        <phone-approve-form v-else-if="state === 'approve'" />
        <div v-show="state === 'signup'" class="auth-form__link" @click="changeState('login')">{{ $t('MainNav_Auth_registered') }}</div>
      </div>
      <div v-show="userAuthorized">
        <b-dropdown-item
          :class="{'q-dropdown__item-selected': false}"
          class="q-dropdown__item"
          @click="goToRoute('/cabinet')"
        >{{ $t('MainNav_Auth_profile') }}</b-dropdown-item>
        <b-dropdown-item
          :class="{'q-dropdown__item-selected': false}"
          class="q-dropdown__item"
          @click="logout"
        >{{ $t('MainNav_Auth_exit') }}</b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import LoginForm from "@/components/Auth/LoginForm.vue";
import SignupForm from "@/components/Auth/SignupForm.vue";
import PhoneApproveForm from "../Auth/PhoneApproveForm";

export default {
    name: "MainNavAuth",
    components: {
        LoginForm,
        SignupForm,
        PhoneApproveForm,
    },

    data() {
        return {
            dropdownActive: false,
            state: 'login',
           renderComponent:true
        }
    },

    watch: {
        state() {

        },
    },

    methods: {
        changeState(state) {
          if (this.state === state) return;
            this.state = state;
            this.$refs.authDropdown.show();
        },
        goToRoute(route) {
            const { path: current } = this.$route;
            if (route === current) return;
            this.$router.push(route);
        },
        async logout() {
            try {
              await this.$store.dispatch('auth/logout')
            } catch (e) {
                console.error(e);
            } finally {
                this.closeDropdown();
                this.goToRoute('/');
            }
        },
        closeDropdown() {
            this.$refs.authDropdown.hide();
        }
    },
}
</script>
<style>
.mainNavAuth .dropdown-menu.show{
  transform: none!important;
  top:30px!important;
}
</style>
