<template>
  <b-dropdown
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
    class="q-dropdown"
  >
    <template #button-content>
      <span class="q-dropdown__selected">{{ selected.name || selected }}</span>
    </template>
    <b-dropdown-item
      v-for="item in options"
      :key="`dropdown-${item.name}`"
      :class="{'q-dropdown__item-selected': item === selected}"
      class="q-dropdown__item"
      @click="select(item)"
    >
      {{ item.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
    name: "QDropdown",
    props: {
        options: Array,
        value: [String,Object],
    },
    data() {
        return {
            selected: '',
        }
    },

    mounted() {
        if (typeof this.value === 'string') this.selected = this.options.filter(item => item.value === this.value)[0];
        else this.selected = this.value;
    },
    methods: {
        select(item) {
            this.selected = item;
            if (item && item.value) this.$i18n.locale = item.value;
              this.$emit('callback')
        }
    }
}
</script>
