<template>
  <b-container class="help">
    <b-row>
      <b-col class="help__title">
        {{ $t('Help_title') }}
      </b-col>
    </b-row>
    <b-row class="help__row">
      <b-col cols="12" lg="8">
        <div class="help-accordion q-card">
          <div class="accordion" role="tablist">
            <div class="help-accordion__tab">
              <div
                class="help-accordion__header"
                v-b-toggle.accordion-1
              >
                {{ $t('Help_which_pay') }}
              </div>
              <b-collapse
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="help-accordion__text">{{ $t('Help_lorem_01') }}</div>
              </b-collapse>
            </div>
            <div class="help-accordion__tab">
              <div
                class="help-accordion__header"
                v-b-toggle.accordion-2
              >
                {{ $t('Help_time_cooking') }}
              </div>
              <b-collapse
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="help-accordion__text">{{ $t('Help_lorem_02') }}</div>
              </b-collapse>
            </div>
            <div class="help-accordion__tab">
              <div
                class="help-accordion__header"
                v-b-toggle.accordion-3
              >
                {{ $t('Help_how_tow_start') }}
              </div>
              <b-collapse
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="help-accordion__text">{{ $t('Help_lorem_03') }}</div>
              </b-collapse>
            </div>
            <div class="help-accordion__tab">
              <div
                class="help-accordion__header"
                v-b-toggle.accordion-4
              >
                {{ $t('Help_time_cake') }}
              </div>
              <b-collapse
                id="accordion-4"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="help-accordion__text">{{ $t('Help_lorem_04') }}</div>
              </b-collapse>
            </div>
            <div class="help-accordion__tab">
              <div
                class="help-accordion__header"
                v-b-toggle.accordion-5
              >
                {{ $t('Help_canceled') }}
              </div>
              <b-collapse
                id="accordion-5"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="help-accordion__text">{{ $t('Help_lorem_05') }}</div>
              </b-collapse>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <feedback-form type="help"/>
      </b-col>
    </b-row>
    <info-block />
  </b-container>
</template>

<script>
import FeedbackForm from "@/components/Forms/FeedbackForm.vue";
import InfoBlock from "@/components/InfoBlock/InfoBlock.vue";

export default {
    name: "Help",
    components: {
        FeedbackForm,
        InfoBlock,
    },

    metaInfo: {
      title: 'Помощь | Доставка кондитерских изделий в Алмате',
      meta: [
        { vmid: 'description', property: 'description', content: 'Qulpynai Помощь' },
        { vmid: 'og:title', property: 'og:title', content: 'Qulpynai' },
        { vmid: 'og:description', property: 'og:description', content: 'Qulpynai Помощь' },
      ],
    },

    data() {
        return {
          answers: [
            {
              answer_01: 'Help_lorem_01',
            },
            {
              answer_02: 'Help_lorem_02',
            },
            {
              answer_03: 'Help_lorem_03',
            },
            {
              answer_04: 'Help_lorem_04',
            },
            {
              answer_05: 'Help_lorem_05',
            },
          ],
        }
    },
}
</script>
