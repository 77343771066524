<template>
  <div class="q-card cabinet-card">
    <div class="cabinet-card__title">{{ $t('Cabinet_CabinetVerify_btn2check_number') }}</div>
    <phone-approve-form
      :error="codeError"
      @sendCode="c => code = c"
      @resendCode="resendCode"
    />
    <button
      class="q-btn q-btn-teal cabinet-card__button"
      @click="verify"
    >
      {{ $t('Cabinet_CabinetVerify_btn2accept_number') }}
    </button>
  </div>
</template>

<script>
import PhoneApproveForm from "@/components/Auth/PhoneApproveForm.vue";

export default {
    name: "CabinetVerify",
    components: {
        PhoneApproveForm,
    },
    data() {
        return {
            codeError: false,
            code: null,
        }
    },

    methods: {
        resendCode() {
            this.$store.dispatch('auth/getActivationCode')
                .then(() => {
                    this.codeError = false;
                })
                .catch((e) => {
                    console.error(e);
                    this.codeError = true;
                })
        },
        verify() {
            this.$store.dispatch('auth/sendActivationCode', this.code)
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        this.codeError = true
                    }
                })

        },
    }
}
</script>
