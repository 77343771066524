var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-modal__images"},[_c('div',{staticClass:"product-modal__images-button-wrapper"},[(_vm.can_decor)?_c('button',{staticClass:"q-btn product-modal__images-button",on:{"click":_vm.showCustomModal}},[_vm._v(" "+_vm._s(_vm.$t('Modals_Product_Images_create_modifier'))+" ")]):_vm._e(),(_vm.product.stock !== undefined)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"catalog-product__stock",attrs:{"title":_vm.tooltip}},[_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-colored': _vm.product.stock === 'red',
          'catalog-product__stock-cell-yellow': _vm.product.stock === 'yellow',
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-colored': _vm.product.stock === 'red',
          'catalog-product__stock-cell-yellow': _vm.product.stock === 'yellow',
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-yellow': _vm.product.stock === 'yellow',
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }})]):_vm._e()]),_c('div',{staticClass:"product-modal__images-carousel"},[_c('swiper',{ref:"swiperTop",staticClass:"swiper product-modal__images-carousel-top",attrs:{"options":_vm.swiperOptionTop}},_vm._l((_vm.images),function(image,i){return _c('swiper-slide',{key:`product-modal-image__${i}`},[(image.img.includes('product_empty.png'))?_c('img',{attrs:{"src":require("@/assets/images/picture.svg"),"alt":""}}):_c('img',{attrs:{"src":image.img}})])}),1),_c('swiper',{ref:"swiperThumbs",staticClass:"swiper product-modal__images-carousel-bot",attrs:{"options":_vm.swiperOptionThumbs}},_vm._l((_vm.images),function(image,i){return _c('swiper-slide',{key:`product-modal-image__-${i}`},[(image.img.includes('product_empty.png'))?_c('img',{attrs:{"src":require("@/assets/images/picture.svg"),"alt":""}}):_c('img',{attrs:{"src":image.img}})])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }