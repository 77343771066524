<template>
  <div class="product-modal__images">
    <div class="product-modal__images-button-wrapper">
      <button
        v-if="can_decor"
        class="q-btn product-modal__images-button"
        @click="showCustomModal"
      >
        {{ $t('Modals_Product_Images_create_modifier') }}
      </button>
      <div
        v-if="product.stock !== undefined"
        class="catalog-product__stock"
        v-b-tooltip.hover
        :title="tooltip"
      >
        <div class="catalog-product__stock-cell"
          :class="{
            'catalog-product__stock-cell-colored': product.stock === 'red',
            'catalog-product__stock-cell-yellow': product.stock === 'yellow',
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"
        ></div>
        <div class="catalog-product__stock-cell"
             :class="{
            'catalog-product__stock-cell-colored': product.stock === 'red',
            'catalog-product__stock-cell-yellow': product.stock === 'yellow',
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
        <div class="catalog-product__stock-cell"
             :class="{
            'catalog-product__stock-cell-yellow': product.stock === 'yellow',
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
        <div class="catalog-product__stock-cell"
             :class="{
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
        <div class="catalog-product__stock-cell"
             :class="{
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
      </div>
    </div>
    <div class="product-modal__images-carousel">
      <swiper
        ref="swiperTop"
        :options="swiperOptionTop"
        class="swiper product-modal__images-carousel-top"
      >
        <swiper-slide
          v-for="(image, i) in images"
          :key="`product-modal-image__${i}`"
        >
          <img v-if="image.img.includes('product_empty.png')" src="@/assets/images/picture.svg" alt="">
          <img v-else :src="image.img">
        </swiper-slide>
      </swiper>

      <swiper
        ref="swiperThumbs"
        :options="swiperOptionThumbs"
        class="swiper product-modal__images-carousel-bot"
      >
        <swiper-slide
          v-for="(image, i) in images"
          :key="`product-modal-image__-${i}`"
        >
          <img v-if="image.img.includes('product_empty.png')" src="@/assets/images/picture.svg" alt="">
          <img v-else :src="image.img">
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
    name: "ProductModalImages",
    props: {
        can_decor: [Boolean, Number],
        images: Array,
    },
    data() {
        return {
            swiperOptionTop: {
                loopedSlides: 2, // looped slides should be the same
                spaceBetween: 10,
                autoHeight: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            swiperOptionThumbs: {
                loopedSlides: 2, // looped slides should be the same
                spaceBetween: 10,
                autoHeight: true,
                centeredSlides: true,
                slidesPerView: 'auto',
                touchRatio: 0.2,
                slideToClickedSlide: true
            },
            product: {
              stock: localStorage.getItem('indicator')
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.$swiper;
            const swiperThumbs = this.$refs.swiperThumbs.$swiper;
            swiperTop.controller.control = swiperThumbs;
            swiperThumbs.controller.control = swiperTop;
        })
    },
    methods: {
        showCustomModal() {
            this.$bvModal.show('customModal');
        },
    },
    computed: {
      tooltip() {
        if (this.product.stock === 'green') {
            return this.$t('CatalogProduct_label_have_in_stock')
        }
        if (this.product.stock === 'yellow') {
            return this.$t('CatalogProduct_label_have_not_more')
        }
        return this.$t('CatalogProduct_label_doesnot_have')
      },
    }
}
</script>
