export default {
    data() {
        return {
            sections: [
                {
                    products: 'cakes',
                    name: 'Торты',
                },
                {
                    products: 'pies',
                    name: 'Пироги',
                },
                {
                    products: 'rolls',
                    name: 'Рулеты',
                },
                {
                    products: 'desserts',
                    name: 'Десерты',
                },
                {
                    products: 'bakery',
                    name: 'Выпечка',
                },
                {
                    products: 'cookies',
                    name: 'Печенье',
                },
            ],

            cakes: [
                {
                    name: 'Вупи-пай',
                    price: 5200,
                    type: 'cake',
                    img: require("@/assets/images/01_torti/1.png"),
                    label: 'hit',
                },
                {
                    name: 'Карамельная девочка',
                    price: 4900,
                    type: 'cake',
                    stock: 2,
                    img: require("@/assets/images/01_torti/2.png"),
                },
                {
                    name: 'Клубничный торт',
                    price: 5700,
                    type: 'cake',
                    img: require("@/assets/images/01_torti/3.png"),
                    label: 'new',
                },
                {
                    name: 'Красный бархат',
                    price: 4200,
                    type: 'cake',
                    img: require("@/assets/images/01_torti/4.png"),
                },
                {
                    name: 'Медовый',
                    price: 4200,
                    type: 'cake',
                    img: require("@/assets/images/01_torti/5.png"),
                },
                {
                    name: 'Молочная девочка',
                    price: 5000,
                    type: 'cake',
                    stock: 3,
                    img: require("@/assets/images/01_torti/6.png"),
                },
                {
                    name: 'Морковный',
                    price: 4800,
                    type: 'cake',
                    img: require("@/assets/images/01_torti/7.png"),
                    label: 'veg',
                },
                {
                    name: 'Наполеон',
                    price: 4200,
                    type: 'cake',
                    stock: 3,
                    img: require("@/assets/images/01_torti/8.png"),
                },
                {
                    name: 'Орео',
                    price: 5200,
                    type: 'cake',
                    img: require("@/assets/images/01_torti/9.png"),
                    label: 'hit'
                },
                {
                    name: 'Тирамису классический',
                    price: 3700,
                    type: 'cake',
                    img: require("@/assets/images/01_torti/10.png"),
                },
                {
                    name: 'Тирамису фундук',
                    price: 4800,
                    type: 'cake',
                    img: require("@/assets/images/01_torti/11.png"),
                },
                {
                    name: 'Черепашка',
                    price: 4200,
                    type: 'cake',
                    img: require("@/assets/images/01_torti/12.png"),
                },
                {
                    name: 'Черничный торт',
                    price: 5700,
                    type: 'cake',
                    stock: 4,
                    img: require("@/assets/images/01_torti/13.png"),
                    label: 'hit',
                },
                {
                    name: 'Шоколадный торт',
                    price: 4800,
                    type: 'cake',
                    img: require("@/assets/images/01_torti/14.png"),
                },
                {
                    name: 'Шпинатный торт',
                    price: 5200,
                    type: 'cake',
                    img: require("@/assets/images/01_torti/15.png"),
                },
            ],

            pies: [
                {
                    name: 'Банановая тарталетка',
                    price: 1600,
                    type: 'pie',
                    img: require("@/assets/images/02_pies/1.png"),
                },
                {
                    name: 'Испанский чизкейк',
                    price: 6400,
                    type: 'pie',
                    img: require("@/assets/images/02_pies/2.png"),
                    label: 'new',
                },
                {
                    name: 'Творожно-ягодный пирог',
                    price: 3000,
                    type: 'pie',
                    img: require("@/assets/images/02_pies/3.png"),
                },
                {
                    name: 'Творожный пирог',
                    price: 1900,
                    type: 'pie',
                    img: require("@/assets/images/02_pies/4.png"),
                    stock: 2,
                },
                {
                    name: 'Шоколадный чизкейк',
                    price: 7000,
                    type: 'pie',
                    img: require("@/assets/images/02_pies/5.png"),
                    label: 'hit',
                },
                {
                    name: 'Яблочный пирог',
                    price: 1900,
                    type: 'pie',
                    img: require("@/assets/images/02_pies/6.png"),
                    stock: 3,
                },
                {
                    name: 'Яблочный пирог',
                    price: 1900,
                    type: 'pie',
                    img: require("@/assets/images/02_pies/7.png"),
                },
            ],

            rolls: [
                {
                    name: 'Меренговый рулет',
                    price: 4000,
                    type: 'roll',
                    img: require("@/assets/images/03_ruleti/1.png"),
                    label: 'new',
                },
                {
                    name: 'Фисташковый рулет',
                    price: 8000,
                    type: 'roll',
                    img: require("@/assets/images/03_ruleti/2.png"),
                    label: 'hit',
                },
                {
                    name: 'Шоколдно-меренговый рулет',
                    price: 6000,
                    type: 'roll',
                    img: require("@/assets/images/03_ruleti/3.png"),
                    stock: 2,
                },
            ],
            desserts: [
                {
                    name: 'Ванильный Эклер',
                    price: 280,
                    type: 'dessert',
                    img: require("@/assets/images/04_deserti/1.png"),
                    label: 'hit',
                },
                {
                    name: 'Вупи пирожное',
                    price: 400,
                    type: 'dessert',
                    img: require("@/assets/images/04_deserti/2.png"),
                },
                {
                    name: 'Капкейк с оформлением',
                    price: 470,
                    type: 'dessert',
                    img: require("@/assets/images/04_deserti/3.png"),
                    stock: 3,
                },
                {
                    name: 'Мини тарт с орехами',
                    price: 550,
                    type: 'dessert',
                    img: require("@/assets/images/04_deserti/4.png"),
                    label: 'new',
                },
                {
                    name: 'Мини тарт с ягодами',
                    price: 550,
                    type: 'dessert',
                    img: require("@/assets/images/04_deserti/5.png"),
                    label: 'new',
                },
                {
                    name: 'Париж брест (кофейный)',
                    price: 550,
                    type: 'dessert',
                    img: require("@/assets/images/04_deserti/6.png"),
                    stock: 2,
                },
            ],
            bakery: [
                {
                    name: 'Борек с картошкой',
                    price: 180,
                    type: 'bakery',
                    img: require("@/assets/images/06_vypechki/1.png"),
                    label: 'hit',
                },
                {
                    name: 'Борек с курицей',
                    price: 200,
                    type: 'bakery',
                    img: require("@/assets/images/06_vypechki/2.png"),
                },
                {
                    name: 'Классический круассан',
                    price: 300,
                    type: 'bakery',
                    img: require("@/assets/images/06_vypechki/3.png"),
                    stock: 3,
                },
                {
                    name: 'Круассан фундучный',
                    price: 350,
                    type: 'bakery',
                    img: require("@/assets/images/06_vypechki/4.png"),
                    label: 'new',
                },
                {
                    name: 'Рогалики с вареной сгущенкой',
                    price: 150,
                    type: 'bakery',
                    img: require("@/assets/images/06_vypechki/5.png"),
                    label: 'new',
                },
                {
                    name: 'Рогалики с вареной сгущенкой',
                    price: 150,
                    type: 'bakery',
                    img: require("@/assets/images/06_vypechki/6.png"),
                    stock: 2,
                },
                {
                    name: 'Самса с курицей',
                    price: 160,
                    type: 'bakery',
                    img: require("@/assets/images/06_vypechki/7.png"),
                    label: 'new',
                },
                {
                    name: 'Самса с мясом',
                    price: 160,
                    type: 'bakery',
                    img: require("@/assets/images/06_vypechki/8.png"),
                    stock: 2,
                },
            ],
            cookies: [
                {
                    name: 'Безе печенье',
                    price: 200,
                    type: 'bakery',
                    img: require("@/assets/images/05_pecene/1.png"),
                    label: 'hit',
                },
                {
                    name: 'Печенье лимонное',
                    price: 190,
                    type: 'bakery',
                    img: require("@/assets/images/05_pecene/2.png"),
                },
                {
                    name: 'Печенье с творогом',
                    price: 300,
                    type: 'bakery',
                    img: require("@/assets/images/05_pecene/3.png"),
                    stock: 3,
                },
                {
                    name: 'Печенье с финиками',
                    price: 200,
                    type: 'bakery',
                    img: require("@/assets/images/05_pecene/4.png"),
                    label: 'new',
                },
                {
                    name: 'Печенье шоколадное',
                    price: 200,
                    type: 'bakery',
                    img: require("@/assets/images/05_pecene/5.png"),
                    label: 'new',
                },
            ],
        }
    }
}
