<template>
  <div>
    <b-row v-show="paymentType && !paymentType.prepay">
      <b-col cols="12" md="5" xl="4">
        <form-group
            :label="$t('Cart_Step4_Self_label_sdacha')"
            :placeholder="$t('Cart_Step4_Self_placeholder_sdacha')"
            v-model="cashAmount"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="10" xl="8" class="cart-step4__disclaimer" style="display: flex;align-items: center">
        {{ $t('Cart_Step4_Self_policy_accept',{rout_legacy: 'https://api.qulpynai.kz/admin/form/translation/926'}) }}
      </b-col>
      <b-col cols="12">
          <b-row class="justify-content-center justify-content-lg-start">
              <button
                  :disabled="loading"
                  class="q-btn q-btn-red cart-step4__button"
                  @click="createOrder"
              >
                  {{ $t('Cart_Step4_Self_btn2order') }}
              </button>
          </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";
import { mapState } from "vuex";

export default {
  name: "Self",
  props: {
    paymentType: Object,
  },
  components: {
    FormGroup,
  },
  computed: {
    ...mapState({
        loading: state => state.order.loading,
    }),
    cashAmount: {
      get() {
        return this.$store.state["cart/cashAmount"];
      },
      set(cashAmount) {
        this.$store.commit('cart/SET_CASH_AMOUNT', cashAmount)
      }
    },
  },
  methods: {
    createOrder() {
      this.$emit('createOrder')
    }
  }
}
</script>
