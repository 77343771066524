<template>
  <div
    :class="{white: white}"
    class="product-modal__nutrition"
  >
    <div class="product-modal__nutrition-title">{{ $t('Modals_Product_Nutriotion_title') }}</div>
<!--    <div class="product-modal__nutrition-items">-->
<!--      <div-->
<!--        v-for="(item, i) in items"-->
<!--        :key="`nutrition-item-${i}`"-->
<!--        class="product-modal__nutrition-item"-->
<!--      >-->
<!--        <div class="product-modal__nutrition-item-key">{{ item.key }}</div>-->
<!--        <div class="product-modal__nutrition-item-value">-->
<!--          {{ item.value }}-->
<!--          <template v-show="item.value2">-->
<!--            <br><span>{{ item.value2 }}</span>-->
<!--          </template>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="product-modal__nutrition-items">
      <div
        v-if="energy && energy.energy_amount"
        class="product-modal__nutrition-item"
      >
        <div class="product-modal__nutrition-item-key">{{ $t('Modals_Product_Nutriotion_bju') }}</div>
        <div class="product-modal__nutrition-item-value">
          {{ energy.energy_amount }} ккал
        </div>
      </div>
      <div
          v-if="energy && energy.proteins_amount"
          class="product-modal__nutrition-item"
      >
        <div class="product-modal__nutrition-item-key">{{ $t('Modals_Product_Nutriotion_belki') }}</div>
        <div class="product-modal__nutrition-item-value">
          {{ energy.proteins_amount }} г
        </div>
      </div>
      <div
          v-if="energy && energy.fat_amount"
          class="product-modal__nutrition-item"
      >
        <div class="product-modal__nutrition-item-key">{{ $t('Modals_Product_Nutriotion_jiri') }}</div>
        <div class="product-modal__nutrition-item-value">
          {{ energy.fat_amount }} г
        </div>
      </div>
      <div
        v-if="energy && energy.carbohydrates_amount"
        class="product-modal__nutrition-item"
      >
        <div class="product-modal__nutrition-item-key">{{ $t('Modals_Product_Nutriotion_uglevodi') }}</div>
        <div class="product-modal__nutrition-item-value">
          {{ energy.carbohydrates_amount }} г
        </div>
      </div>
      <div v-if="weight" class="product-modal__nutrition-item">
        <div class="product-modal__nutrition-item-key">{{ $t('Modals_Product_Nutriotion_weight') }}</div>
        <div class="product-modal__nutrition-item-value">
          {{ weight }} {{$t('Modals_Product_Nutriotion_kg')}}
        </div>
      </div>
    </div>
    <div v-if="diameter" class="product-modal__nutrition-items">
      <div class="product-modal__nutrition-item">
        <div class="product-modal__nutrition-item-key">{{ $t('Modals_Product_Nutriotion_diameter') }}</div>
        <div class="product-modal__nutrition-item-value">
          {{ diameter }}
        </div>
      </div>
      <div v-if="height" class="product-modal__nutrition-item">
        <div class="product-modal__nutrition-item-key">{{ $t('Modals_Product_Nutriotion_height') }}</div>
        <div class="product-modal__nutrition-item-value">
          {{ height }}
        </div>
      </div>
      <div v-if="storage_period" class="product-modal__nutrition-item">
        <div class="product-modal__nutrition-item-key">{{ $t('Modals_Product_Nutriotion_srok') }}</div>
        <div class="product-modal__nutrition-item-value">
          {{ storage_period }}
        </div>
      </div>
      <div v-if="storage_conditions" class="product-modal__nutrition-item">
        <div class="product-modal__nutrition-item-key">{{ $t('Modals_Product_Nutriotion_uslovie') }}</div>
        <div class="product-modal__nutrition-item-value">
          {{ storage_conditions }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "ProductModalNutrition",
    props: {
        energy: Object,
        diameter: Number,
        height: Number,
        weight: Number,
        storage_conditions: String,
        storage_period: String,
        white: Boolean
    },
    data() {
        return {
            items: [
                {
                    key: 'Энерг. ценность',
                    value: '289.00 ккал',
                    value2: '1295 кДж'
                },
                {
                    key: 'Белки',
                    value: '9.1 г',
                },
                {
                    key: 'Жиры',
                    value: '9.9 г',
                },
                {
                    key: 'Углеводы',
                    value: '30.3 г',
                },
                {
                    key: 'Вес',
                    value: '390 кг',
                },
            ],
            items2: [
                {
                    key: 'Диаметр',
                    value: '25 см',
                },
                {
                    key: 'Высота',
                    value: '8 - 8.5 см',
                },
                {
                    key: 'Срок хранения',
                    value: '5 дней',
                },
                {
                    key: 'Условия хранения',
                    value: 'В холодильнике при температуре от +2 до +6 °С',
                },
            ],
        }
    },
}
</script>
