<template>
  <b-container>
    <b-row class="blog-post">
      <b-col cols="12" class="blog-post__back">
        <router-link to="/blog">
          {{ $t('Blog_BlogPost_btn2back') }}
        </router-link>
      </b-col>
      <b-col cols="12" class="blog-post__header">
        {{ $t('Blog_BlogPost_header') }}
      </b-col>
      <b-col cols="12">
        <div class="q-card blog-post__item">
          <div class="blog-post__image">
            <img :src="post.image" :alt="post.caption">
          </div>
          <div class="blog-post__content">
            <div class="blog-post__title">
              {{ post.caption }}
            </div>
            <div class="blog-post__tags">
              <div class="blog-post__tag">{{ post.created_at }}</div>
              <div class="blog-post__tag">{{ post.type }}</div>
            </div>
<!--            <div class="blog-post__blue-line">-->
<!--              <p class="blog-post__paragraph">-->
<!--                Фильм о первой медиа-экспедиции с профессиональной съемкой-->
<!--                "Вернуться с Хан-Тенгри" вышел в официальный прокат в марте-->
<!--                2019-го.  А теперь, наконец -то, работа  Ануара Нурпеисова-->
<!--                стала доступна и на YouTube. Участники должны были дойти до-->
<!--                вершины за 21 день, но вернулись через 16. Организаторами-->
<!--                экспедиции выступили Алматинский областной акимат и Жетысуское-->
<!--                географическое общество.-->
<!--              </p>-->
<!--            </div>-->
            <p class="blog-post__paragraph" v-html="post.text"></p>
<!--            <p class="blog-post__paragraph">-->
<!--              Сотни часов изнурительных тренировок, сборы, ожидания, перелёт,-->
<!--              тысячи метров подъема вверх и сложнейшая вершина Казахстана. Всё-->
<!--              это ради того, чтобы испытать себя. Для каждого из них это целая-->
<!--              жизнь, которая несомненно перевернет всё, что было "ДО"... Они-->
<!--              идут на Хан! В фильме, как в дневнике, рассказывается о событиях-->
<!--              каждого дня.-->
<!--            </p>-->
<!--            <p class="blog-post__paragraph">-->
<!--              Чтобы отправится на Хан-Тенгри, нужно иметь деньги, азарт и-->
<!--              невозможность жить без адреналина.  А можно просто посмотреть-->
<!--              этот фильм и, испытав страх, преодолев себя, совершить своё-->
<!--              восхождение. Это фильм - интересное документальное кино снятое-->
<!--              и смонтированное  "от души".-->
<!--            </p>-->
<!--            <p class="blog-post__paragraph">-->
<!--              В 2018 году известный блогер Ануар Нурпеисов, актриса Айсулу Азимбаева-->
<!--              и оператор Александр Кузнецов совершили самое опасное восхождение в их-->
<!--              жизни - подъем на пик Хан Тенгри. Нужно отметить, что ребята-->
<!--              непрофессиональные альпинисты и их история получилась очень вдохновляющей.-->
<!--            </p>-->
          </div>
          <div class="blog-post__footer">

          </div>
        </div>
      </b-col>
    </b-row>
    <info-block />
  </b-container>
</template>

<script>
import InfoBlock from "@/components/InfoBlock/InfoBlock.vue";
import { mapState } from "vuex";

export default {
    name: "BlogPost",
    components: {
        InfoBlock,
    },

    computed: {
        ...mapState({
            post: state => state.articles.article,
        }),
        postId() {
            return this.$route.params.id
        },
    },

    created() {
        this.$store.dispatch('articles/getArticle', this.postId);
    },
}
</script>
