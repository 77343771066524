<template>
  <div class="product-modal__general">
    <div class="product-modal__general-head">
      <div class="product-modal__general-title">{{ name }}</div>
<!--      <div class="product-modal__general-label">Новинка</div>-->
    </div>
    <div class="product-modal__general-text">
      {{ description }}
    </div>
    <div v-if="info" class="product-modal__general-composition">
      {{ $t('Modals_Product_General_condition') }}
      <p>{{ info }}</p>
    </div>
    <div class="product-modal__general-price">
      <span>{{ $t('Modals_Product_General_sum') }}</span>
      <div class="product-modal__general-price-value">{{ numberWithSpaces(price) }} <span>₸</span></div>
    </div>
    <div class="product-modal__general-buttons" v-if="!is_special">
      <button v-if="is_stop" class="q-btn q-btn-teal" disabled>{{ $t('CatalogProduct_label_btn2_no_stock') }}</button>
      <template v-else>
        <button class="q-btn q-btn-teal" @click="addProduct">{{ $t('Modals_Product_General_add2cart') }}</button>
        <button class="q-btn" @click="buyNow">{{ $t('Modals_Product_General_buy2cart') }}</button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
    name: "ProductModalGeneral",
    props: {
        name: String,
        price: Number,
        description: String,
        info: String,
        id: Number,
        is_special: Number,
        is_stop: Number
    },
    methods: {
        async addProduct() {
            try {
                const product = {};
                product.id = this.id;
                product.name = this.name;
                await this.$store.dispatch('cart/addProduct', product);
                this.$bvModal.hide('productModal');
            } catch (e) {
                console.error(e);
            }
        },
        buyNow() {
            try {
                this.addProduct();
            } finally {
                this.$router.push('/cart');
                this.$bvModal.hide('productModal');
            }
        },
    },
}
</script>
