<template>
  <div
      id="cart-step-3"
      :class="{'cart-step-inactive': cantOrder}"
      class="q-card cart-step"
  >
    <div class="cart-step__title">
      {{ $t('Cart_Step3_header') }}
      <span>{{ quantity }}</span>
    </div>
    <div v-if="!cantOrder">
      <router-link to="/" class="q-btn cart-step__button">
        {{ $t('Cart_Step3_add2button') }}
      </router-link>

      <b-row>
        <b-col cols="12">
          <div class="q-issue" v-if="filialMarker(selectedFilial && selectedFilial.id) === 'yellow'">
            <div>
              {{ $t('Cart_Step3_out_of_stock_01') }}
              <span @click="$scrollTo('#cart-step-2', 1000, {offset: -70})">{{ $t('Cart_Step3_out_of_stock_02') }}</span>
            </div>
          </div>
          <div class="q-issue" v-if="filialMarker(selectedFilial && selectedFilial.id) === 'red'">
            <div>
              {{ $t('Cart_Step3_out_of_stock_03') }}
              <br><span @click="$scrollTo('#cart-step-2', 1000, {offset: -70})">{{ $t('Cart_Step3_out_of_stock_04') }}</span>
            </div>
          </div>
        </b-col>
        <b-col
          v-for="(product, i) in productsList"
          :key="`cart-step-3-product__${i}`"
          cols="12"
          class="q-desktop-only"
        >
          <div class="cart-product">
            <div class="next-day-block" :title="$t('CatalogProduct_label_have_next_day')">
              <span v-if="product.next_day">
                <img src="../../assets/icons/next-day.svg" alt />
              </span>
            </div>
            <div class="cart-product__img">
              <img v-if="product.image" :src="product.image" :alt="product.name">
              <img v-else src="@/assets/images/default_product.png" :alt="product.name">
            </div>
            <div class="cart-product__content">
              <cart-stock-products class="cart-product__stock" :product="product" step/>
              <div class="cart-product__name">{{ product.name }}</div>
              <div v-if="product.inscription" class="cart-product__custom">
                {{ $t('Cart_Step3_product_inscription') }}
                <div class="cart-product__custom-value">
                  {{ product.inscription }}
                </div>
              </div>
              <div v-if="productIsOutOfStock(product.id)" class="cart-product__out">
                {{ $t('Cart_Step3_out_of_stock_05') }}
              </div>
            </div>
            <div class="cart-product__count cart-item__count">
              <div
                :class="{'cart-item__count-increase-inactive': addLoading}"
                class="cart-product__count-decrease cart-item__count-decrease"
                @click="decreaseProduct(product)"
              >-</div>
              <div class="cart-product__count-value cart-item__count-value">{{ product.quantity }}</div>
              <div
                :class="{'cart-item__count-increase-inactive': productIsOutOfStock(product.id) || addLoading}"
                class="cart-product__count-increase cart-item__count-increase"
                @click="addProduct(product)"
              >+</div>
            </div>
            <div class="cart-product__price">{{ numberWithSpaces(product.amount) }} ₸</div>
            <div
              class="cart-product__clear cart-item__clear"
              @click="removeProduct(product)"
            ></div>
          </div>
        </b-col>
          <div class="cart-side__items q-mobile-only">
            <div
              v-for="(item, i) in productsList"
              :key="`cart-side-item__${i}`"
              class="cart-side__item"
            >
              <div class="cart-side__item-img">
                <img v-if="item.image" :src="item.image" :alt="item.name">
                <img v-else src="@/assets/images/default_product.png" :alt="item.name">
              </div>
              <div class="cart-side__item-content">
                <cart-stock-products class="cart-product__stock" :product="item" step/>
                <div class="cart-side__item-content-head">
                  <div class="cart-side__item-title">
                    {{ item.name }}
                  </div>
                  <div class="cart-product__clear cart-item__clear" @click="removeProduct(item)"></div>
                </div>
                <div class="cart-side__item-content-body">
                  <div
                    v-if="productIsOutOfStock(item.id)"
                    class="cart-side__item-out"
                  >
                    {{ $t('Cart_Step3_out_of_stock_06') }}
                  </div>
                  <div
                    v-else
                    class="cart-item__count cart-side__item-count"
                  >
                    <div
                      class="cart-item__count-decrease"
                      @click="removeProduct(item)"
                    >-
                    </div>
                    <div class="cart-item__count-value">{{ item.quantity }}</div>
                    <div
                      class="cart-item__count-increase"
                      @click="addProduct(item)"
                    >+
                    </div>
                  </div>
                  <div class="cart-side__item-price">{{ item.amount }} ₸</div>
                </div>
              </div>
            </div>
          </div>
      </b-row>

      <div
        v-if="(products || []).length > 3"
        class="cart-step3__show-more"
        @click="showAll = !showAll"
      >
        {{ !showAll ? $t('Cart_Step3_btn2show') : $t('Cart_Step3_btn2hide') }}
        {{ $t('Cart_Step3_products') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CartStock from "@/components/Cart/CartStock.vue";
import CartStockProducts from "./cartStockProducts.vue";


export default {
    name: "Step3",
  components: {CartStock,CartStockProducts},

    data() {
        return {
            showAll: false,
        }
    },

    computed: {
        ...mapState({
            quantity: state => state.cart.productsQuantity,
            products: state => state.cart.products,
            selectedFilial: state => state.filial.activeFilial,
            addLoading: state => state.cart.addLoading,
        }),
        productsList() {
            const { showAll, products } = this;
            return showAll ? products: products.slice(0,3);
        },
        cantOrder() {
            return !this.$store.getters['stopList/canPickup'] && !this.$store.getters['stopList/canDelivery'];
        },
    },

    methods: {
        addProduct(product) {
            if (this.productIsOutOfStock(product.id)) return;
            this.$store.dispatch('cart/addProduct', product);
        },
        decreaseProduct(product) {
            this.$store.dispatch('cart/decrementProduct', product);
        },
        removeProduct(product) {
            this.$store.dispatch("cart/removeProduct", product);
        },
        filialMarker(filialId) {
            return this.$store.getters["stopList/filialMarker"](filialId)
        },
        productIsOutOfStock(productId) {
            return this.$store.getters["stopList/productIsOutOfStock"](productId)
        },
    },
}
</script>

<style lang="scss" scoped>
.next-day-block {
  position: absolute;
  top: 20px;
  left: 90px;
  cursor: pointer;

  img {
    width: 20px;
  }
}
.cart-product__stock{
  margin-bottom: -10px;
}
.cart-side__item-content{
  position: relative;
}
@media (max-width: 1000px) {
  .cart-product__stock{
    margin-top: 16px;
    margin-left: 4px;
  }
}
</style>


