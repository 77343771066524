<template>
  <div class="cart-step__delivery">
    <div
      class="q-issue"
      v-if="filialMarker(selectedFilial && selectedFilial.id) === 'yellow'"
    >
      {{ $t("Cart_Step2_out_of_stock_01") }}
      <br />{{ $t("Cart_Step2_out_of_stock_02") }}
    </div>
    <div
      class="q-issue"
      v-if="filialMarker(selectedFilial && selectedFilial.id) === 'red'"
    >
      {{ $t("Cart_Step2_out_of_stock_03") }}
      <br />{{ $t("Cart_Step2_out_of_stock_04") }}
    </div>
    <b-row>
      <b-col cols="12" md="5" xl="4">
        <form-group
          v-model="selectedDate"
          :options="dates"
          type="select2"
          :label="$t('Cart_Step2_delivery_date_label')"
          :placeholder="$t('Cart_Step2_delivery_date_placeholder')"
        />
      </b-col>
      <b-col cols="12" md="5" xl="4">
        <form-group
          v-model="selectedTime"
          :options="hours"
          type="select2"
          :label="$t('Cart_Step2_delivery_time_label')"
          :placeholder="$t('Cart_Step2_delivery_time_placeholder')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="cart-step__subtitle">
        {{ $t("Cart_Step2_delivery_address") }}
      </b-col>
      <b-col cols="12" md="10" xl="8">
        <form-group
          id="address_input"
          :label="$t('Cart_Step2_delivery_street')"
          :placeholder="$t('Cart_Step2_delivery_street_number')"
          :error="errors['address.street'] && ' '"
          required
          v-model="address"
          @blur="lostFocus"
          @focus="showSuggestList = true"
          autocomplete="off"
        >
        </form-group>

        <div class="suggestion" v-if="showSuggestList && address.length > 0">
          <div class="suggestion_list">
            <div
              v-for="(item, index) in suggestResponse ?? []"
              :key="index"
              :value="item.value"
              @click="selectAdress(item)"
            >
              {{ item.displayName }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" md="4" lg="3" xl="2">
        <form-group
          id="addressFlat"
          :label="$t('Cart_Step2_delivery_flat')"
          :placeholder="$t('Cart_Step2_delivery_number_flat')"
          required
          v-model="addressFlat"
          autocomplete="off"
        />
      </b-col>
      <b-col cols="6" md="4" lg="3" xl="2">
        <form-group
          :label="$t('Cart_Step2_delivery_entrace')"
          placeholder="N"
          v-model="addressEntrance"
        />
      </b-col>
      <b-col cols="6" md="4" lg="3" xl="2">
        <form-group
          :label="$t('Cart_Step2_delivery_house')"
          placeholder="NN"
          v-model="addressDoorphone"
        />
      </b-col>
      <b-col cols="6" md="4" lg="3" xl="2">
        <form-group
          :label="$t('Cart_Step2_delivery_floor')"
          placeholder="NN"
          v-model="addressFloor"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="cart-step__map map">
          <div
            :class="{ 'cart-step__map-toggle-open': delivery.map }"
            class="cart-step__map-toggle"
            @click="delivery.map = !delivery.map"
          >
            {{
              delivery.map
                ? $t("Cart_Step2_delivery_hide2map")
                : $t("Cart_Step2_delivery_show2map")
            }}
          </div>
          <div class="map-content">
            <div style="position: relative; overflow: hidden">
              <div
                id="map"
                style="width: 100%; height: 50vh"
                v-show="delivery.map"
              ></div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="10" xl="8">
        <form-group
          v-model="addressComment"
          :label="$t('Cart_Step2_delivery_comment_label')"
          :placeholder="$t('Cart_Step2_delivery_comment_placeholder')"
          :max-value="30"
          required
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";
import { mapState } from "vuex";
import { loadYmap } from "vue-yandex-maps";

export default {
  name: "Delivery",
  components: {
    FormGroup,
  },
  data() {
    return {
      isDelivery: true,
      coords: [43.241655, 76.944246],
      addressError: null,
      addressInput: undefined,
      myMap: undefined,
      address: "",
      shortAddress: "",
      interval: null,
      mapSettings: {
        apiKey:
          process.env.VUE_APP_YANDEX_CART_API_KEY +
          "&" +
          `suggest_apikey=` +
          process.env.VUE_APP_YANDEX_SUGGEST_API_KEY,
        lang: "ru_RU",
        center: [76.944246, 43.241655],
        zoom: 12,
        controls: ["zoomControl"],
        coordorder: "longlat",
      },
      placemarkSettings: {},
      show: false,
      rotate: 180,
      delivery: {
        map: true,
        house: false,
      },
      debounceAdressTimeout: 125,
      showSuggestList: false,
      suggestResponse: [],
    };
  },
  computed: {
    ...mapState({
      filials: (state) => state.filial.filials,
      dates: (state) => state.cart.deliveryDates,
      hours: (state) => state.cart.deliveryTimes,
      selectedFilial: (state) => state.filial.activeFilial,
      errors: (state) => state.order.errors,
    }),
    selectedDate: {
      get() {
        return this.$store.state.cart.selectedDeliveryDate;
      },
      set(selectedDate) {
        this.$store.commit("cart/SET_SELECTED_DELIVERY_DATE", selectedDate);
      },
    },
    selectedTime: {
      get() {
        return this.$store.state.cart.selectedDeliveryTime;
      },
      set(selectedTime) {
        this.$store.commit("cart/SET_SELECTED_DELIVERY_TIME", selectedTime);
      },
    },
    addressFlat: {
      get() {
        return this.$store.state.cart.deliveryAddress.flat;
      },
      set(flat) {
        this.$store.commit("cart/SET_DELIVERY_FLAT", flat);
      },
    },
    addressEntrance: {
      get() {
        return this.$store.state.cart.deliveryAddress.entrance;
      },
      set(entrance) {
        this.$store.commit("cart/SET_DELIVERY_ENTRANCE", entrance);
      },
    },
    addressFloor: {
      get() {
        return this.$store.state.cart.deliveryAddress.floor;
      },
      set(floor) {
        this.$store.commit("cart/SET_DELIVERY_FLOOR", floor);
      },
    },
    addressDoorphone: {
      get() {
        return this.$store.state.cart.deliveryAddress.doorphone;
      },
      set(doorphone) {
        this.$store.commit("cart/SET_DELIVERY_DOORPHONE", doorphone);
      },
    },
    addressComment: {
      get() {
        return this.$store.state.order.courierComment;
      },
      set(comment) {
        this.$store.commit("order/SET_COURIER_COMMENT", comment);
      },
    },
  },
  created() {
    loadYmap(this.mapSettings);
    this.interval = setInterval(async () => {
      if (
        window.ymaps !== undefined &&
        window.ymaps.SuggestView !== undefined
      ) {
        clearInterval(this.interval);
        this.initMap();
        await this.$store.dispatch("cart/getDeliveryZone");
        this.onZonesLoad(this.$store.state.cart.geoJson);
      }
    }, 1000);
  },
  mounted() {
    //this.$store.commit("cart/SET_SELECTED_DELIVERY_DATE", this.dates[0]);
  },
  watch: {
    async address(value) {
      // console.log(
      //   value,
      //   this.address,
      //   value === this.address,
      //   this.showSuggestList
      // );
      await this.sleep(this.debounceAdressTimeout);
      if (value === this.address && this.showSuggestList) {
        this.suggestResponse = await this.suggest(value);
        //console.log("suggestResponse", this.suggestResponse);
      }
    },
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    suggest(request, options) {
      //console.log("suggest", request);
      return ymaps.suggest("Алматы, " + request, {
        boundedBy: [
          [71.2, 51.3],
          [71.7, 51],
        ],
        results: 5,
      });
      // return ymaps.vow.resolve([
      //   {
      //     displayName: "Мытищи, Московская область",
      //     value: "Россия, Московская область, Мытищи ",
      //   },
      //   {
      //     displayName: "Мытищи2, Московская область2",
      //     value: "Россия2, Московская область, Мытищи2 ",
      //   },
      //   {
      //     displayName: "Мытищи3, Московская область3",
      //     value: "Россия3, Московская область, Мытищи3 ",
      //   },
      //]);
    },
    lostFocus() {
      setTimeout(() => {
        this.showSuggestList = false;
      }, 200);
    },
    selectAdress(request) {
      //console.log("selectAdress", request);
      this.address = request.value;
      this.geocodeRequest(request.value);
    },
    onClick(e) {
      this.coords = e.get("coords");
    },
    initMap() {
      this.myMap = new ymaps.Map("map", this.mapSettings);
      // this.addressInput = new ymaps.SuggestView("address_input", {
      //   boundedBy: [
      //     [71.2, 51.3],
      //     [71.7, 51],
      //   ],
      //   strictsBounds: true,
      //   provider: {
      //     suggest: function (request, options) {
      //       return ymaps.suggest("Алматы , " + request);
      //     },
      //   },
      // });

      this.myMap.events.add("click", (e) => {
        let coords = e.get("coords");
        // Если метка уже создана – просто передвигаем ее.
        if (this.myPlacemark) {
          this.myPlacemark.geometry.setCoordinates(coords);
        }
        // Если нет – создаем.
        else {
          this.myPlacemark = this.createPlacemark(coords);
          this.myMap.geoObjects.add(this.myPlacemark);
          // Слушаем событие окончания перетаскивания на метке.
          this.myPlacemark.events.add("dragend", () => {
            this.getAddress(this.myPlacemark.geometry.getCoordinates());
          });
        }
        this.getAddress(coords);
      });

      // this.addressInput.events.add("select", (e) => {
      //   let request = this.addressInput.state.get("request");
      //   console.log(request);
      //   this.geocodeRequest(request);
      // });
    },
    getAddress(coords) {
      this.myPlacemark.properties.set("iconCaption", "поиск...");
      this.geocodeRequest(coords);
    },
    createPlacemark(coords) {
      return new ymaps.Placemark(
        coords,
        {
          iconCaption: "поиск...",
        },
        {
          preset: "islands#violetDotIconWithCaption",
          draggable: true,
        }
      );
    },
    geocodeRequest(request) {
      const notifyOptions = {
        group: "foo",
        title: this.$t("Cart_Step4_error"),
        text: "",
        type: "error",
      };
      ymaps
        .geocode(request)
        .then((res) => {
          const obj = res.geoObjects.get(0);
          this.setAddress(obj);
          this.$store.commit(
            "cart/SET_DELIVERY_COORDS",
            obj.geometry.getCoordinates()
          );
          if (this.checkFullAddress(obj)) {
            this.addressError = null;
            this.$store
              .dispatch("cart/getDeliveryInfo")
              .then((response) => {
                if (!response.data.filial_id) {
                  this.addressError = "Cart_Step2_delivery_not_available";
                }
                this.addressError = null;
              })
              .catch((err) => {
                this.addressError = "Cart_Step2_delivery_not_available";
              });
          }
          const mapState = ymaps.util.bounds.getCenterAndZoom(
            obj.properties.get("boundedBy"),
            this.myMap.container.getSize()
          );
          if (typeof request === "string") {
            this.setMapCenter(mapState);
            this.setPlacemark(mapState);
          } else {
            this.myPlacemark.properties.set(this.placemarkSettings);
          }

          if (this.addressError) {
            notifyOptions.text = this.$t(this.addressError);
            this.$notify(notifyOptions);
            this.$store.commit("cart/SET_DELIVERY", {
              deliveryPrice: null,
              canDelivery: false,
            });
            //@todo так как здесь ставится undefined филиал, то потом где-то у filial требуется свойство .name
            this.$store.commit("filial/SET_ACTIVE_FILIAL_BY_ID", undefined, {
              root: true,
            });
            this.addressError = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkFullAddress(obj) {
      if (obj) {
        switch (
          obj.properties.get("metaDataProperty.GeocoderMetaData.precision")
        ) {
          case "exact":
            return true;
          case "number":
          case "near":
          case "range":
            this.addressError = "Cart_Step2_map_error_01";
            break;
          case "street":
            this.addressError = "Cart_Step2_map_error_01";
            break;
          case "other":
          default:
            this.addressError = "Cart_Step2_map_error_03";
        }
      } else {
        this.addressError = "Cart_Step2_map_error_04";
      }
      return false;
    },
    setMapCenter(mapState) {
      if (this.myPlacemark) {
        this.myMap.setCenter(mapState.center);
      } else {
        this.myMap.setCenter(mapState.center, mapState.zoom);
      }
    },
    setPlacemark(mapState) {
      if (this.myPlacemark) {
        this.myPlacemark.geometry.setCoordinates(mapState.center);
        this.myPlacemark.properties.set(this.placemarkSettings);
      } else {
        this.myPlacemark = new ymaps.Placemark(
          mapState.center,
          this.placemarkSettings,
          {
            preset: "islands#redDotIconWithCaption",
          }
        );
        this.myMap.geoObjects.add(this.myPlacemark);
      }
    },
    setAddress(obj) {
      this.$store.commit("cart/SET_DELIVERY_ADDRESS", obj);
      this.address = obj.getAddressLine();
      this.shortAddress = [
        obj.getThoroughfare(),
        obj.getPremiseNumber(),
        obj.getPremise(),
      ].join(" ");
      if (!this.shortAddress.trim().length) {
        this.shortAddress = [
          obj.getLocalities().length
            ? obj.getLocalities()
            : obj.getAdministrativeAreas(),
        ].join(" ");
      }
      this.placemarkSettings = {
        iconCaption: this.shortAddress,
        balloonContent: this.address,
      };
    },
    onZonesLoad(json) {
      this.delivery.map = false;
      // Добавляем зоны на карту.
      let deliveryZones = window.ymaps.geoQuery(json).addToMap(this.myMap);
      // Задаём цвет и контент балунов полигонов.
      deliveryZones.each(function (obj) {
        obj.options.set({
          fillColor: obj.properties.get("fill"),
          fillOpacity: obj.properties.get("fill-opacity"),
          strokeColor: obj.properties.get("stroke"),
          strokeWidth: obj.properties.get("stroke-width"),
          strokeOpacity: obj.properties.get("stroke-opacity"),
          interactivityModel: "default#silent",
        });
        obj.properties.set("balloonContent", obj.properties.get("description"));
      });
    },
    filialMarker(filialId) {
      return this.$store.getters["stopList/filialMarker"](filialId);
    },
  },
};
</script>

<style scoped>
.suggestion {
  position: relative;
}

.suggestion_list {
  margin-top: -25px;
  margin-bottom: 26px;
  background-color: #ffffff;
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid rgb(201, 201, 201);
  width: 100%;
  z-index: 5000;
}

.suggestion_list div {
  width: 100%;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
}
.suggestion_list div:hover {
  background-color: rgb(241, 241, 89);
  cursor: pointer;
}
</style>
