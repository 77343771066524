<template>
  <div
    id="cart-step-2"
    :class="{ 'cart-step-inactive': false }"
    class="q-card cart-step"
  >
    <div class="cart-step__title">
      {{ $t("Cart_Step2_index_header_step2") }}
    </div>
    <div v-if="!isLoading">
      <b-row class="cart-step__tabs">
        <b-col cols="12" md="5" xl="4">
          <div
            :class="{
              'cart-step__tab-active': isDelivery,
              'cart-step__tab-inactive':
                !$store.getters['stopList/canDelivery'],
            }"
            class="cart-step__tab"
            @click="toggleDelivery(true)"
          >
            <div class="cart-step__tab-title">
              {{ $t("Cart_Step2_index_tab_btn2delivery") }}
            </div>

            <div class="cart-step__tab-text">
              {{
                amount < minAmount
                  ? $t("Cart_Step2_index_min_delivery_price")
                  : !$store.getters["stopList/canDelivery"]
                  ? $t("Cart_Step2_index_delivery_not_access")
                  : $t("Cart_Step2_index_delivery_address")
              }}
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="5" xl="4">
          <div
            :class="{
              'cart-step__tab-active': !isDelivery,
              'cart-step__tab-inactive': !$store.getters['stopList/canPickup'],
            }"
            class="cart-step__tab"
            @click="toggleDelivery(false)"
          >
            <div class="cart-step__tab-title">
              {{ $t("Cart_Step2_index_tab_btn2pickup") }}
            </div>
            <div class="cart-step__tab-text">
              {{
                !$store.getters["stopList/canPickup"]
                  ? $t("Cart_Step2_index_pickup_not_access")
                  : $t("Cart_Step2_index_pickup_paragraph")
              }}
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="10" xl="8">
          <div
            v-if="
              !$store.getters['stopList/canPickup'] &&
              !$store.getters['stopList/canDelivery']
            "
            class="q-issue"
          >
            {{ $t("Cart_Step2_index_pickup_delivery_not_access_01") }}
            <br /><br />
            {{ $t("Cart_Step2_index_pickup_delivery_not_access_02") }}
          </div>
        </b-col>
      </b-row>
      <!--delivery-->
      <delivery v-if="isDelivery && canOrder" />

      <!--pickup-->
      <pickup v-if="!isDelivery && canOrder" />
    </div>
    <div v-else>
      <div class="d-flex justify-content-center">
        <div
          style="width: 10rem; height: 10rem"
          class="main-spinner spinner-border text-info"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Delivery from "./Delivery";
import Pickup from "./Pickup";
import { mapState } from "vuex";

export default {
  name: "Step2",
  components: {
    Delivery,
    Pickup,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      amount: (state) => state.cart.amount,
      minAmount: (state) => state.filial.minAmount,
    }),
    isDelivery: {
      get() {
        return (
          this.$store.state.cart.isDelivery && this.checkDeliveryMinAmount()
        );
      },
      set(isDelivery) {
        this.$store.commit("cart/SET_IS_DELIVERY", isDelivery);
      },
    },
    canOrder() {
      return this.$store.getters["stopList/canOrder"];
    },
  },

  mounted() {
    this.isLoading = true;
    this.$store.dispatch("filial/getFilials").then(() => {
      this.isDelivery = this.$store.getters["stopList/canDelivery"];
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    });
  },

  watch: {
    amount() {
      this.isDelivery = this.$store.getters["stopList/canDelivery"]
        ? this.isDelivery
        : false;
    },
  },

  methods: {
    toggleDelivery(isDelivery) {
      this.$store.commit("order/SET_ERRORS", {});
      if (
        (isDelivery && !this.$store.getters["stopList/canDelivery"]) ||
        !this.$store.getters["stopList/canPickup"]
      ) {
        return;
      }
      this.isDelivery = isDelivery;
      this.$store.commit("filial/SET_ACTIVE_FILIAL", {});
      // if (!isDelivery) {
      //     this.$store.commit('order/SET_FOR_FRIEND', false);
      //     this.$store.commit('cart/SET_SELECTED_DELIVERY_TIME', null);
      //     this.$store.commit('cart/SET_SELECTED_DELIVERY_DATE', null);
      //     this.$store.commit('cart/SET_DELIVERY_COMMENT', null);
      //     this.$store.commit('cart/SET_DELIVERY_DOORPHONE', null);
      //     this.$store.commit('cart/SET_DELIVERY_FLOOR', null);
      //     this.$store.commit('cart/SET_DELIVERY_ENTRANCE', null);
      //     this.$store.commit('cart/SET_DELIVERY_FLAT', null);
      // }
      //if (isDelivery) {
      //this.$store.commit("filial/SET_ACTIVE_FILIAL", {});
      //this.$store.commit('cart/SET_SELECTED_PICKUP_TIME', null);
      //this.$store.commit('cart/SET_SELECTED_PICKUP_DATE', null);
      //}
    },
    checkDeliveryMinAmount() {
      return this.amount > this.minAmount;
    },
  },
};
</script>

<style scoped></style>
