<template>
  <b-modal
    ref="reviewModal"
    id="reviewModal"
    size="xl"
    hide-header
    hide-footer
    body-class="review-modal"
  >
    <div class="review-modal__close-button" @click="hideReviewModal"></div>
    <show v-if="review && review.id" @hideReviewModal="hideReviewModal" />
    <b-row v-else>
      <b-col cols="12" lg="6">
        <div class="review-modal__body">
          <div
            class="review-modal__back-button"
            @click="hideReviewModal"
          >
            {{ $t('Modals_ReviewModal_back') }}
          </div>
          <auth v-if="!$store.getters['auth/userVerified']"/>
          <add
            v-else
            :success="success"
            @submit="addReview"
            @hideModal="hideReviewModal"
          />
        </div>
      </b-col>
      <b-col class="q-desktop-only">
        <div class="review-modal__image"></div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import Auth from "./Review/Auth";
import Add from "./Review/Add"
import Show from "./Review/Show"
import { mapState } from "vuex";

export default {
    name: "ReviewModal",
    components: {
        Auth,
        Add,
        Show,
    },
    data() {
        return {
            step: 1,
            success: false,
        }
    },
    computed: {
        ...mapState({
            modalProduct: state => state.products.modalProduct,
            review: state => state.reviews.modalReview,
        }),
    },

    methods: {
        hideReviewModal() {
            this.success = false;
            this.$bvModal.hide('reviewModal');
        },
        async addReview(data) {
            let review = {...data};
            review.author = this.currentUser.name;
            review.product_id = this.modalProduct.id;

            try {
                await this.$store.dispatch('reviews/addReview', review);
                this.success = true;
            } catch (e) {
                console.error(e);
            }
        },
    },
}
</script>
