var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.cabinet)?_c('div',{staticClass:"order-card__progress",class:{
    'order-card__progress-four': _vm.order.order_type === 'DeliveryByClient',
  }},[_c('div',{staticClass:"order-card__progress-item",class:{
      colored: _vm.order.status_id >= 2,
    }}),_c('div',{staticClass:"order-card__progress-item",class:{
      colored: _vm.order.status_id >= 4,
    }}),_c('div',{staticClass:"order-card__progress-item",class:{
      colored: _vm.order.status_id >= 5,
    }}),(_vm.order.order_type !== 'DeliveryByClient')?_c('div',{staticClass:"order-card__progress-item",class:{
      colored: _vm.order.status_id >= 7,
    }}):_vm._e(),_c('div',{staticClass:"order-card__progress-item",class:{
      colored: _vm.order.status_id >= 8,
    }})]):(_vm.order.status_id !== 11)?_c('div',{staticClass:"order-card__timeline",class:{
    'order-card__timeline-four': _vm.order.order_type === 'DeliveryByClient',
  }},[_c('div',{staticClass:"order-card__timeline-item",class:{
      'order-card__timeline-item-current': _vm.order.status_id === 2,
      'order-card__timeline-item-green': _vm.order.status_id > 2,
    }},[_c('div',{staticClass:"order-card__timeline-icon order-page__status-icon order-page__status-icon-step1"}),_c('div',{staticClass:"order-card__timeline-title"},[_vm._v(" "+_vm._s(_vm.$t("Order_OrderTimeline_Order_Accept"))+" "),(!(_vm.order.payment.type === 'cash') && !_vm.order.payed_at)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("Order_OrderTimeline_Order_Wait_Pay"))+" ")]):(!(_vm.order.payment.type === 'cash') && _vm.order.payed_at)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("Order_OrderTimeline_Order_Payed"))+" ")]):_vm._e()])]),_c('div',{staticClass:"order-card__timeline-item",class:{
      'order-card__timeline-item-current': _vm.order.status_id === 4,
      'order-card__timeline-item-green': _vm.order.status_id > 4,
    }},[_c('div',{staticClass:"order-card__timeline-icon order-page__status-icon order-page__status-icon-step2"}),_c('div',{staticClass:"order-card__timeline-title"},[_vm._v(" "+_vm._s(_vm.$t("Order_OrderTimeline_Order_Waiting"))+" ")])]),_c('div',{staticClass:"order-card__timeline-item",class:{
      'order-card__timeline-item-current': _vm.order.status_id === 5,
      'order-card__timeline-item-green': _vm.order.status_id > 5,
    }},[_c('div',{staticClass:"order-card__timeline-icon order-page__status-icon order-page__status-icon-step3"}),_c('div',{staticClass:"order-card__timeline-title"},[_vm._v(" "+_vm._s(_vm.$t("Order_OrderTimeline_Order_Ready"))+" ")])]),(_vm.order.is_delivery)?_c('div',{staticClass:"order-card__timeline-item",class:{
      'order-card__timeline-item-current': _vm.order.status_id === 7,
      'order-card__timeline-item-green': _vm.order.status_id > 7,
    }},[_c('div',{staticClass:"order-card__timeline-icon order-page__status-icon order-page__status-icon-step4"}),_c('div',{staticClass:"order-card__timeline-title"},[_vm._v(" "+_vm._s(_vm.$t("Order_OrderTimeline_Order_Delivery"))+" ")])]):_vm._e(),_c('div',{staticClass:"order-card__timeline-item",class:{
      'order-card__timeline-item-current': _vm.order.status_id === 8,
      'order-card__timeline-item-green': _vm.order.status_id > 8,
    }},[_c('div',{staticClass:"order-card__timeline-icon order-page__status-icon order-page__status-icon-step5"}),_c('div',{staticClass:"order-card__timeline-title"},[_vm._v(" "+_vm._s(_vm.order.is_delivery ? _vm.$t("Order_OrderTimeline_Order_Delivered") : _vm.$t("Order_OrderTimeline_Order_Closed"))+" ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }