<template>
  <div>
    <b-modal
      ref="WaitOrderAnswerModal"
      id="WaitOrderAnswerModal"
      size="md"
      hide-header
      @hide="hideModal"
      hide-footer
      body-class="product-modal"
      centered
      no-close-on-backdrop
    >
      <div class="text-center">
        {{ $t("WaitOrderAnswerModal_text") }}
      </div>
      <div class="d-flex justify-content-center">
        <div
          style="width: 10rem; height: 10rem; margin-top: 30px"
          class="main-spinner spinner-border text-info"
        >
          <span class="sr-only">{{ $t("loading") }}</span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "WaitOrderAnswer",
  data() {
    return {};
  },
  computed: {},
  methods: {
    hideModal() {
      this.$bvModal.hide("WaitOrderAnswerModal");
    },
    callback() {
      this.$emit("callback");
    },
  },
};
</script>
