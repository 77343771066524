<template>
  <div class="approve-phone">
    <div class="approve-phone__text">
      {{ $t('Auth_PhoneApproveForm_text_01') }}
      <span>{{ phone || $store.getters["auth/phone"] }}</span>
      {{ $t('Auth_PhoneApproveForm_text_02') }}
      <br>
      {{ $t('Auth_PhoneApproveForm_text_03') }}
    </div>
    <div
        v-if="error"
        style="color: #E10714"
    >
      {{ $t('Auth_PhoneApproveForm_error_01') }}
    </div>
    <div
        :class="{'approve-phone__inputs-left': alignContent}"
        class="approve-phone__inputs"
    >

      <input
          v-for="i in 6"
          :key="`approve-input-field__${i}`"
          :ref="`approve${i}`"
          :class="{'approve-phone__input-error': error}"
          type="number"
          class="approve-phone__input"
          @input="e => codeInput(e, i)"
          @keyup="e => onBackspace(e, i)"
      >
    </div>
    <div
        :class="{'approve-phone__resend-left': alignContent}"
        class="approve-phone__resend"
    >
      <div v-if="timer > 0" class="approve-phone__resend-timer">
        {{ $t('Auth_PhoneApproveForm_btn2send_sms_01') }}
        {{ timer }}
        {{ $t('Auth_PhoneApproveForm_btn2send_sms_02') }}
      </div>
      <div
          v-else
          class="approve-phone__resend-text"
          @click="resendCode"
      >
        {{ $t('Auth_PhoneApproveForm_btn2send_sms_03') }}
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";

export default {
  name: "PhoneApproveForm",
  props: {
    phone: String,
    alignContent: String,
  },
  components: {
    FormGroup,
  },
  data() {
    return {
      values: [],
      code: [],
      timeToRecovery: 15,
      timeIntervalId: null,
      timer: 0,
      error: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.startTimer();
    });
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    codeInput(e, n) {
      if (e.data === null) return;
      let {value} = this.$refs[`approve${n}`][0];
      if (value.length > 1) {
        this.$refs[`approve${n}`][0].value = value[1];
      }
      this.code[n - 1] = value.length > 1 ? value[1] : value;
      if (n === 6 && this.code.join("").length === 6) {
        this.sendCode();
        return;
      }
      if (n < 6) {
        this.$refs[`approve${n + 1}`][0].focus();
      }
    },
    onBackspace(e, n) {
        if (n === 1) return;
        this.error = false;
        if (e.key && e.key === 'Backspace') {
            if (n === 0) return;
            this.$refs[`approve${n - 1}`][0].focus();
        }
    },
    startTimer() {
      clearInterval(this.timeIntervalId);
      this.timer = 60;
      this.timeIntervalId = setInterval(() => {
        this.timer--;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timeIntervalId);
    },
   async sendCode(){
      try {
        let data = {
          phone: (this.phone || '').replace(/\D+/g, "") || this.$store.getters["auth/phone"],
          code: this.code.join('')
        }
        await this.$store.dispatch("auth/loginByCode", data);
        this.$emit('closeDropdown');
      }
      catch (e){
        console.error(e);
        this.error = true
      }
    },
    resetForm() {
      this.stopTimer();
      this.startTimer();
    },
    async resendCode() {
      try {
        await this.$emit('resendCode');
      } catch (e) {
        console.error(e);
      } finally {
        this.resetForm();
        for (var i = 1; i <= 6; i++) {
          this.$refs[`approve${i}`][0].value = '';
        }
      }
    },
  },
}
</script>
