<template>
  <div class="product-modal__reviews">
    <div class="product-modal__reviews-head">
      <div class="product-modal__reviews-title">
        {{ $t('Modals_Product_Reviews_title') }}
        <span v-if="count">{{ count }}</span>
      </div>
      <button
        class="q-btn product-modal__reviews-head-button"
        @click="showReviewModal"
      >
        {{ $t('Modals_Product_Reviews_write') }}
      </button>
    </div>
    <b-form-rating
      v-model="rating"
      v-if="rating"
      variant="warning"
      class="product-modal__reviews-rating product-modal__review-rating"
      disabled
    />
    <div v-if="count" class="product-modal__reviews-list">
      <div
        v-for="(item, i) in reviews"
        :key="`product-review-${i}`"
        class="product-modal__review"
      >
        <div class="product-modal__review-title">{{ item.author }}</div>
        <b-form-rating
          v-model="item.rating"
          variant="warning"
          class="product-modal__reviews-rating product-modal__review-rating"
          size="sm"
          disabled
        ></b-form-rating>
        <div class="product-modal__review-content">
          <div class="product-modal__review-text">
            {{ item.review }}
          </div>
          <div
            class="product-modal__review-link"
            @click="showReviewModal(item.id)"
          ></div>
        </div>
      </div>
    </div>
    <div v-else class="product-modal__reviews-list">
      <div class="product-modal__review">
        <div class="product-modal__review-content">
          <div class="product-modal__review-text">
            {{ $t('Modals_Product_Reviews_none_reviews') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "ProductModalReviews",
    props: {
        count: Number,
        reviews: Array,
        rating: Number,
    },
    data() {
        return {
            reviewss: [
                {
                    name: 'Макпал',
                    rating: 5,
                    text: 'Очень вкусный торт! Советую всем вот такие  шикарные продукты! Спасибо за радость! И еще ...',
                },
                {
                    name: 'Света',
                    rating: 4,
                    text: 'Ели всей семьей, все были в восторге, наши гости были в восторге!',
                },
                {
                    name: 'Денис',
                    rating: 3,
                    text: 'Кажется слишком сладко, но в целом круто.',
                },
            ],
        }
    },
    methods: {
        showReviewModal(id) {
            if (typeof id !== 'number') {
                this.$bvModal.show('reviewModal');
                this.$store.commit('reviews/SET_REVIEW', {});
                return;
            }
            this.$store.dispatch('reviews/getReview', id)
                .then(() => {
                    this.$bvModal.show('reviewModal');
                });
        },
    }
}
</script>
