import cookie from "vue-cookies";
import axios from "axios";
import Vue from "vue";
import dayjs from "dayjs";

const getDefaultState = () => {
  return {
    token: cookie.get("auth_token"),
    products: [],
    amount: 0,
    cartId: null,
    productsQuantity: 0,
    cashAmount: undefined,
    pickupDates: [],
    deliveryDates: [],
    pickupTimes: [],
    deliveryTimes: [],
    selectedPickupDate: null,
    selectedPickupTime: null,
    selectedDeliveryDate: null,
    selectedDeliveryTime: null,
    isDelivery: true,
    timeIntervals: {},
    deliveryPrice: null,
    canDelivery: true,
    deliveryAddress: {
      country: null,
      city: null,
      street: null,
      house: null,
      flat: null,
      entrance: null,
      floor: null,
      doorphone: null,
      comment: null,
    },
    deliveryCoords: {
      latitude: null,
      longitude: null,
    },
    geoJson: {},
    loading: false,
    addLoading: false,
    maxPostPaymentAmount: 15000,
  };
};

const state = getDefaultState();

const getters = {
  getDeliveryDates(state) {
    if (state.deliveryDates.length) return state.deliveryDates.slice(0, 7);
    return state.deliveryDates;
  },
  getUnauthorizedToken() {
    return state.token ? state.token : cookie.get("auth_token");
  },
  getSelectedDate(state) {
    return state.isDelivery
      ? state.selectedDeliveryDate
      : state.selectedPickupDate;
  },
  getSelectedTime(state) {
    return state.isDelivery
      ? state.selectedDeliveryTime
      : state.selectedPickupTime;
  },
  getOrderType(state) {
    return state.isDelivery ? "delivery" : "pickup";
  },
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
    cookie.set("auth_token", token);
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_AMOUNT(state, amount) {
    state.amount = amount;
  },
  RESET_CARD(state) {
    state.amount = 0;
    state.products = [];
    state.productsQuantity = 0;
    state.cartId = null;
  },
  SET_PRODUCTS_QUANTITY(state, quantity) {
    state.productsQuantity = quantity;
  },
  SET_CART_ID(state, cartId) {
    state.cartId = cartId;
  },
  RESET_TOKEN(state) {
    state.token = null;
    cookie.remove("auth_token");
  },
  SET_CASH_AMOUNT(state, cashAmount) {
    state.cashAmount = cashAmount;
  },
  SET_SELECTED_PICKUP_DATE(state, selectedPickupDate) {
    state.selectedPickupDate = selectedPickupDate;
    if (state.timeIntervals.pickup !== undefined) {
      state.pickupTimes = state.timeIntervals.pickup[state.selectedPickupDate];
    }
    if (
      state.pickupTimes &&
      !state.pickupTimes.includes(state.selectedPickupTime)
    ) {
      state.selectedPickupTime = state.pickupTimes[0];
    }
  },
  SET_SELECTED_PICKUP_TIME(state, selectedPickupTime) {
    state.selectedPickupTime = selectedPickupTime;
  },
  SET_SELECTED_DELIVERY_DATE(state, selectedDeliveryDate) {
    state.selectedDeliveryDate = selectedDeliveryDate;
    state.deliveryTimes =
      state.timeIntervals.delivery &&
      state.timeIntervals.delivery[state.selectedDeliveryDate];
    if (
      state.deliveryTimes &&
      !state.deliveryTimes.includes(state.selectedDeliveryTime)
    ) {
      state.selectedDeliveryTime = state.deliveryTimes[0];
    }
  },
  SET_SELECTED_DELIVERY_TIME(state, selectedDeliveryTime) {
    state.selectedDeliveryTime = selectedDeliveryTime;
  },

  SET_PERIODS(state, response) {
    const today = dayjs().format("DD.MM.YYYY");
    const yesterday = dayjs().add(1, "day").format("DD.MM.YYYY");
    const intervals = response.intervals;
    state.timeIntervals = intervals;
    state.deliveryDates = Object.keys(intervals.delivery).map((date) => {
      if (date == today) return { value: date, text: "Сегодня" };
      if (date == yesterday) return { value: date, text: "Завтра" };
      return { value: date, text: date };
    });
    state.pickupDates = Object.keys(intervals.pickup).map((date) => {
      if (date == today) return { value: date, text: "Сегодня" };
      if (date == yesterday) return { value: date, text: "Завтра" };
      return { value: date, text: date };
    });
    if (!state.selectedDeliveryDate) {
      state.selectedDeliveryDate = state.deliveryDates[0].value;
    }
    if (!state.selectedPickupDate) {
      state.selectedPickupDate = state.pickupDates[0].value;
    }
    state.pickupTimes = intervals.pickup[state.selectedPickupDate];
    state.deliveryTimes = intervals.delivery[state.selectedDeliveryDate];
    if (!state.selectedPickupTime) {
      state.selectedPickupTime = state.pickupTimes[0];
    }
    if (!state.selectedDeliveryTime) {
      state.selectedDeliveryTime = state.deliveryTimes[0];
    }
  },
  SET_IS_DELIVERY(state, isDelivery) {
    state.isDelivery = isDelivery;
  },
  SET_DELIVERY(state, delivery) {
    state.deliveryPrice = delivery.price;
    state.canDelivery = delivery.can_delivery;
  },
  SET_DELIVERY_ADDRESS(state, obj) {
    let street = obj.getThoroughfare();
    if (!street) {
      if (obj.getLocalities() && obj.getLocalities().length > 1) {
        street = obj.getLocalities()[1];
      }
    }

    state.deliveryAddress.country = obj.getCountry();
    state.deliveryAddress.city = obj.getLocalities()
      ? obj.getLocalities()[0]
      : null;
    state.deliveryAddress.street = street;
    state.deliveryAddress.house = obj.getPremiseNumber();
  },
  SET_DELIVERY_FLAT(state, flat) {
    state.deliveryAddress.flat = flat;
  },
  SET_DELIVERY_ENTRANCE(state, entrance) {
    state.deliveryAddress.entrance = entrance;
  },
  SET_DELIVERY_FLOOR(state, floor) {
    state.deliveryAddress.floor = floor;
  },
  SET_DELIVERY_DOORPHONE(state, doorphone) {
    state.deliveryAddress.doorphone = doorphone;
  },
  SET_DELIVERY_COMMENT(state, comment) {
    state.deliveryAddress.comment = comment;
  },
  SET_DELIVERY_COORDS(state, coords) {
    state.deliveryCoords.latitude = coords[1];
    state.deliveryCoords.longitude = coords[0];
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_ADD_LOADING(state, payload) {
    state.addLoading = payload;
  },
  SET_MAX_POST_PAYMENT_AMOUNT(state, maxPostPaymentAmount) {
    state.maxPostPaymentAmount = maxPostPaymentAmount;
  },
};
const actions = {
  // setAmount({commit}, price) {
  //     commit('SET_AMOUNT', price)
  // },
  addProduct({ state, commit, dispatch }, product) {
    commit("SET_LOADING", true);
    commit("SET_ADD_LOADING", product.id);
    const params = {
      products: [
        {
          id: product.id,
          quantity: 1,
          inscription: product.inscription,
        },
      ],
    };
    if (getters.getUnauthorizedToken()) {
      params.token = getters.getUnauthorizedToken();
    }
    return axios.post("cart/product", params).then((response) => {
      dispatch("setCart", response.data);
      Vue.notify({
        group: "foo",
        title: "Добавлено",
        text: product.name,
        type: "success",
      });
    });
  },
  getCart(context) {
    if (
      !getters.getUnauthorizedToken() &&
      !context.rootGetters["auth/loggedIn"]
    ) {
      return;
    }
    let params = {};
    if (getters.getUnauthorizedToken()) {
      params.token = getters.getUnauthorizedToken();
    }
    return axios.get("cart", { params: params }).then((response) => {
      actions.setCart(context, response.data);
    });
  },
  removeProduct({ state, commit, dispatch }, product) {
    commit("SET_LOADING", true);
    let params = {
      hash: product.hash,
    };
    if (getters.getUnauthorizedToken()) {
      params.token = getters.getUnauthorizedToken();
    }

    return axios.delete("cart/product", { params: params }).then((response) => {
      dispatch("setCart", response.data);
      Vue.notify({
        group: "foo",
        title: "Удалено",
        text: product.name,
        type: "error",
      });
    });
  },
  removeCart(context) {
    let params = {};
    if (getters.getUnauthorizedToken()) {
      params.token = getters.getUnauthorizedToken();
    }
    return axios.delete("cart", { params: params }).then(() => {
      context.commit("RESET_CARD");
      context.commit("RESET_TOKEN");
    });
  },
  decrementProduct({ state, commit, dispatch }, product) {
    commit("SET_LOADING", true);
    commit("SET_ADD_LOADING", product.id);
    const params = {
      id: product.id,
      inscription: product.inscription,
    };
    if (getters.getUnauthorizedToken()) {
      params.token = getters.getUnauthorizedToken();
    }
    return axios.post("cart/product/decrement", params).then((response) => {
      dispatch("setCart", response.data);
      Vue.notify({
        group: "foo",
        title: "Удалено",
        text: product.name,
        type: "error",
      });
    });
  },
  setCart({ state, commit }, responseCart) {
    if (responseCart.token && !getters.getUnauthorizedToken()) {
      commit("SET_TOKEN", responseCart.token);
    }
    commit("SET_PRODUCTS", responseCart.products);
    commit("SET_AMOUNT", responseCart.amount);
    commit("SET_CART_ID", responseCart.id);
    commit("SET_PRODUCTS_QUANTITY", responseCart.quantity_products);
    commit("SET_LOADING", false);
    commit("SET_ADD_LOADING", false);
  },
  getDeliveryInfo(context) {
    if (!context.state.deliveryCoords.latitude) {
      return;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`cart/${context.state.cartId}/delivery`, {
          params: context.state.deliveryCoords,
        })
        .then((response) => {
          context.commit("SET_DELIVERY", response.data);
          if (response.data.filial_id) {
            context.commit(
              "filial/SET_ACTIVE_FILIAL_BY_ID",
              response.data.filial_id,
              { root: true }
            );
            resolve(response);
          } else {
            context.commit("filial/SET_ACTIVE_FILIAL_BY_ID", undefined, {
              root: true,
            });
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    });
    return axios
      .get(`cart/${context.state.cartId}/delivery`, {
        params: context.state.deliveryCoords,
      })
      .then((response) => {
        context.commit("SET_DELIVERY", response.data);
        if (response.data.filial_id) {
          context.commit(
            "filial/SET_ACTIVE_FILIAL_BY_ID",
            response.data.filial_id,
            { root: true }
          );
        }
      });
  },
  getDeliveryZone(context) {
    const params = {
      name: "delivery_zones",
    };
    return axios.get("file", { params: params }).then((response) => {
      context.state.geoJson = response.data;

      return response;
    });
  },
};

// Экспортируем наружу
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
