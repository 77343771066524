<template>
  <b-container class="whatsapp">
    <b-row>
      <b-col>
        <div class="whatsapp-card">
          <div class="whatsapp-title">
            {{ $t('Whatsapp_call2whatsapp') }}
          </div>
          <div class="whatsapp-img q-mobile-only">
            <img src="@/assets/images/whatsapp.png" alt="whatsup?">
          </div>
          <div class="whatsapp-content">
            <div class="whatsapp-content__title">
              {{ $t('Whatsapp_in_whatsapp') }}
            </div>
            <div class="whatsapp-content__button">
              <a href="https://api.whatsapp.com/send/?phone=77471111818&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C+%D1%82%D0%BE%D1%80%D1%82&type=phone_number&app_absent=0"
                 target="_blank"
                 class="q-btn q-btn-green">
                +7 (747) 111 18 18
              </a>
            </div>

          </div>

          <div class="whatsapp-content">
            <div class="whatsapp-content__title">
              {{ $t('modifier_whatsapp') }}
            </div>
            <div class="whatsapp-content__button">
              <a href="https://api.whatsapp.com/send/?phone=77006419910&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D1%81%D0%B4%D0%B5%D0%BB%D0%B0%D1%82%D1%8C+%D0%BE%D1%84%D0%BE%D1%80%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5+%D0%B4%D0%BB%D1%8F+%D1%82%D0%BE%D1%80%D1%82%D0%B0&type=phone_number&app_absent=0"
                 target="_blank"
                 class="q-btn q-btn-green">
                +7 (700) 641 99 10
              </a>
            </div>
          </div>

        </div>
      </b-col>
    </b-row>
<!--    <info-block />-->
  </b-container>
</template>

<script>
// import InfoBlock from "@/components/InfoBlock/InfoBlock.vue";

export default {
    name: "Whatsapp",

    metaInfo: {
      title: 'WhatsApp | Доставка кондитерских изделий в Алмате',
      meta: [
        { vmid: 'description', property: 'description', content: 'Qulpynai WhatsApp' },
        { vmid: 'og:title', property: 'og:title', content: 'WhatsApp Qulpynai' },
        { vmid: 'og:description', property: 'og:description', content: 'Qulpynai WhatsApp' },
      ],
    },

    // components: {
    //     InfoBlock,
    // },
}
</script>
