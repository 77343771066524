<template>
  <div>
    <b-row>
      <b-col cols="12" md="10" xl="8" class="cart-step4__disclaimer">
        {{ $t('Cart_Step4_Friend_Attention_01') }}
        <br><br>
        {{ $t('Cart_Step4_Friend_Attention_02') }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="5" xl="4">
        <form-group
            type="text"
            :label="$t('Cart_Step4_Friend_label_name')"
            :placeholder="$t('Cart_Step4_Friend_placeholder_name')"
            v-model="name"
        />
      </b-col>
      <b-col cols="12" md="5" xl="4">
        <form-group
            type="phone"
            :label="$t('Cart_Step4_Friend_label_number')"
            :placeholder="$t('Cart_Step4_Friend_placeholder_number')"
            v-model="phone"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-row class="justify-content-center justify-content-lg-start">
            <button
                :disabled="loading"
                class="q-btn q-btn-red cart-step4__button"
                @click="createOrder"
            >
                {{ $t('Cart_Step4_Friend_btn2order') }}
            </button>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";
import { mapState } from "vuex";

export default {
  name: "Friend",
  components: {
    FormGroup,
  },
  methods: {
    createOrder() {
      this.$emit('createOrder')
    }
  },
  computed: {
    ...mapState({
        loading: state => state.order.loading,
    }),
    name: {
      get() {
        return this.$store.state.order.friend.name;
      },
      set(name) {
        this.$store.commit('order/SET_FRIEND_NAME', name)
      },
    },
    phone: {
      get() {
        return this.$store.state.order.friend.phone;
      },
      set(phone) {
        this.$store.commit('order/SET_FRIEND_PHONE', phone)
      },
    },
  }
}
</script>
