<template>
  <b-modal
    ref="cabinetModal"
    id="cabinetModal"
    size="md"
    hide-header
    hide-footer
    body-class="custom-modal"
  >
    <div class="custom-modal__close-button" @click="hideModal"></div>
    <b-row>
      <b-col cols="12">
        <div class="cabinet-modal">
          <div class="cabinet-modal__head">
            {{ $t('Cabinet_CabinetModal_header_change_pass') }}
          </div>
          <div class="cabinet-modal__body">
            <div class="cabinet-modal__form">
              <form-group
                v-for="(field, i) in fields"
                v-model="form[field.name]"
                :key="`password-change-modal-field__${i}`"
                :error="errors[field.name] && errors[field.name][0]"
                :label="$t(field.label)"
                :type="field.type"
                placeholder="*******"
              />
            </div>
            <div class="cabinet-modal__footer">
              <button
                class="q-btn q-btn-teal cabinet-modal__button"
                @click="changePassword"
              >
                {{ $t('Cabinet_CabinetModal_btn2save') }}
              </button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";
export default {
    name: "CabinetModal",
    components: {
        FormGroup,
    },
    data() {
        return {
            form: {},
            errors: {},
            fields: [
                {
                    name: 'old_password',
                    type: 'password',
                    label: 'Cabinet_CabinetModal_label_01',
                    placeholder: 'Cabinet_CabinetModal_placeholder_01',
                },
                {
                    name: 'new_password',
                    type: 'password',
                    label: 'Cabinet_CabinetModal_label_02',
                    placeholder: 'Cabinet_CabinetModal_placeholder_02',
                },
                {
                    name: 'new_password_confirmation',
                    type: 'password',
                    label: 'Cabinet_CabinetModal_label_03',
                    placeholder: 'Cabinet_CabinetModal_placeholder_03',
                },
            ],
        }
    },

    methods: {
        hideModal() {
            this.form = {};
            this.errors = {};
            this.$bvModal.hide('cabinetModal');
        },
        changePassword() {
            this.$store.dispatch('auth/changePassword', this.form)
                .then(() => {
                    this.hideModal();
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                })
        },
    },
}
</script>

<style scoped>

</style>
