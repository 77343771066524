<template>
  <b-container class="contacts">
    <b-row>
      <b-col class="contacts__header">
        {{ $t('Contacts_title') }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="8">

        <div class="q-card contacts-card">
<!--          <a href="https://api.whatsapp.com/send/?phone=77471111818&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C+%D1%82%D0%BE%D1%80%D1%82&type=phone_number&app_absent=0"-->
<!--             target="_blank"-->
<!--             class="q-btn q-btn-green">-->
<!--            WhatsApp-->
<!--          </a>-->
          <router-link to="/whatsapp" class="main-nav__link q-btn q-btn-green">WhatsApp</router-link>
          <br />
          <br />
          <br />
          <a href="tel:77001101059" class="contacts-card__phone">+ 7 (700) 110 10 59</a>

          <div class="contacts-card__feedback">
            <span>{{ $t('Contacts_question_form') }}</span>
            <a href="mailto:feedback@qulpynai.kz">feedback@qulpynai.kz</a>
          </div>
          <div class="contacts-card__head">
            <div>{{ $t('Contacts_shops') }}</div>
            <a href="https://2gis.kz/almaty/branches/70000001026664738?m=76.913684%2C43.230445%2F12.74&utm_referrer=http%3a%2f%2f172.31.127.198%3a8080%2f&fa821dba_ipp_key=v1668785638695%2Fv33947245bb5ad87a72e273%2FdC6Z0HRjMO7hvSwh8bzRpg%3d%3d&fa821dba_ipp_uid=1662381174603%2fkadF3nDZuNf8Yi5H%2fPm%2f3EXJ%2fOIpbmYZxRiQ%2bXA%3d%3d"
               target="_blank">
              {{ $t('Contacts_area_deliveries') }}
            </a>

          </div>
          <div class="contacts-card__items">
            <div
              v-for="(item, i) in shops"
              :key="`contact-shop__${i}`"
              class="contacts-card__item"
              v-if="item.visible"
            >

              <h5>{{ $t(item.name) }}</h5>


              <div class="contacts-card__item-address">
                <a :href="item.link" target="_blank">
                  {{ item.address }}
                </a>
              </div>

              <div class="contacts-card__item-phone">
                {{ item.phone }}
              </div>

              <div class="contacts-card__item-hours">
                {{ item.start_work }} - {{ item.end_work}}
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="4">
       <span style="color: red">{{ $t('feedback_info_title') }}</span>
        <div class="q-card order-card mt-2 contacts-card__feedback">
          <div
              v-html="$t('feedback_text_info')"
          ></div>
        </div>
        <br />
        <feedback-form type="contacts" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FeedbackForm from "@/components/Forms/FeedbackForm.vue";
import {mapState} from "vuex";

export default {
    name: "Contacts",
    components: {
        FeedbackForm,
    },

    metaInfo: {
      title: 'Контакты | Доставка кондитерских изделий в Алмате',
      meta: [
        { vmid: 'description', property: 'description', content: 'Qulpynai Контакты' },
        { vmid: 'og:title', property: 'og:title', content: 'Contacts Qulpynai' },
        { vmid: 'og:description', property: 'og:description', content: 'Qulpynai Контакты' },
      ],
    },
  computed: {
    ...mapState({
      shops: state => state.filial.filials,
    })
  },
    data() {
        return {

        }
    },
  created() {
    let filials
    this.$store.dispatch('filial/getFilials').then(res => {
      filials = res
    });
  },
}
</script>
