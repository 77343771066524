<template>
  <div>
    <div v-if="stage === 1">
      <form v-show="!isRecovery">
        <form-group
          v-for="(field, i) in fields"
          v-model="form[field.name]"
          :key="`login-form-field__${i}`"
          :error="errors[field.name] && errors[field.name][0]"
          :type="field.name"
          :label="$t(field.label)"
          :placeholder="field.placeholder"
        />

        <button
          class="q-btn q-btn-teal auth-form__submit"
          :disabled="loading"
          @click="submit"
        >
          {{ $t("Auth_LoginForm_login") }}
        </button>
<!--        <div class="auth-form__link" @click="showRecovery(true)">-->
<!--          {{ $t("Auth_LoginForm_recovery") }}-->
<!--        </div>-->
      </form>
      <password-recovery v-show="isRecovery" @back="showRecovery(false)" />
    </div>
    <div v-if="stage === 2">
      <PhoneApproveForm
        @closeDropdown="this.$emit('closeDropdown')"
        @resendCode="submit"
        :phone="form.phone"
      ></PhoneApproveForm>
    </div>
  </div>
</template>

<script>
import FormGroup from "../FormGroup/FormGroup";
import PasswordRecovery from "./PasswordRecoveryForm.vue";
import PhoneApproveForm from "@/components/Auth/PhoneApproveForm.vue";

export default {
  name: "LoginForm",
  components: {
    FormGroup,
    PasswordRecovery,
    PhoneApproveForm,
  },
  data() {
    return {
      form: {},
      errors: [],
      stage: 1,
      loading: false,

      isRecovery: false,

      fields: [
        {
          name: "phone",
          type: "phone",
          label: "Auth_LoginForm_fields_phone",
        },
      ],
    };
  },

  methods: {
    async submit() {
      let { form } = this;
      form.phone = (form.phone || "").replace(/\D+/g, "");
      form.is_registration = false;
      this.loading = true;
      try {
        await this.$store.dispatch("auth/register", form);
        this.stage = 2;
        const { path: current } = this.$route;
        if ("/" === current) return;
        this.$router.push("/");
      } catch (error) {
        this.errors = ((error.response || {}).data || {}).errors || {};
        if (error.response && error.response.status === 401) {
          this.$set(this.errors, "phone", [
            this.$t("Auth_LoginForm_errors_login_pass"),
          ]);
        }
      } finally {
        this.loading = false;
      }
    },
    closeDropdown() {
      this.$emit("closeDropdown");
    },
    showRecovery(recovery) {
      this.isRecovery = recovery;
    },
  },
};
</script>
