<template>
  <b-container>
    <div class="q-card legacy-block">
      <b-row>
        <b-col cols="12">
          <div class="legacy-block__header">
            {{ $t("Legacy_public_policy") }}
          </div>
      <div v-html="$t('legacy')"></div>
          </b-col>
        </b-row>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Legacy",
  components: {},
  data() {
    return {
      pdfUrl:
        "https://docs.google.com/document/d/1JEGI61vzpaLIthAFgWIsjHJeuv8yVihA0QoveZ5pNSE/edit",
    };
  },

  metaInfo: {
    title: "Публичная оферта | Доставка кондитерских изделий в Алмате",
    meta: [
      {
        vmid: "description",
        property: "description",
        content: "Qulpynai Правовая информация",
      },
      { vmid: "og:title", property: "og:title", content: "Qulpynai Legacy" },
      {
        vmid: "og:description",
        property: "og:description",
        content: "Qulpynai Правовая информация",
      },
    ],
  },
};
</script>

<style scoped></style>
