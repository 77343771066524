import axios from 'axios';
import store from '../store';
import {i18n} from "@/i18n/i18n";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${ token }`;
        }
        if (i18n.locale) {
            config.headers['Content-Language'] = i18n.locale
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(function (response) {
    return response;
}, error => {
    if (401 === error.response?.status) {
        let token = localStorage.getItem('token');
        if (token) {
            return store.dispatch('auth/refresh').then(() => {
                return axios.request(error.config);
            });
        }
    } else {
        return Promise.reject(error);
    }

    return Promise.reject(error);
});

export default axios;
