<template>
  <div class="catalog">
    <div
        v-for="(section, i) in products"
        :key="`catalog-section__${i}`"
        :id="section.slug"
    >
      <div v-if="$route.query.section !== 'all'">
        <b-row v-if="section && section.slug && section.slug === elements[$route.query.section]">
          <b-col class="catalog-header">
            {{ section.name }}
          </b-col>
        </b-row>
        <b-row
            v-if="section && section.slug && section.products && section.slug === elements[$route.query.section]"
            class="catalog-list"
            cols="1"
            cols-md="2"
            cols-lg="4"
        >
          <catalog-product
              v-for="(product, i) in section.products"
              :key="`product-${i}`"
              :product="product"
              :added="productAdded(product.id)"
          />
        </b-row>
      </div>
      <div v-else>
        <b-row v-if="section">
          <b-col class="catalog-header">
            {{ section.name }}
          </b-col>
        </b-row>
        <b-row
            class="catalog-list"
            cols="1"
            v-if="section && section.products"
            cols-md="2"
            cols-lg="4"
        >
          <catalog-product
              v-for="(product, i) in section.products"
              :key="`product-${i}`"
              :product="product"
              :added="productAdded(product.id)"
          />
        </b-row>
      </div>

    </div>
  </div>
</template>

<script>
import CatalogProduct from "./CatalogProduct";
import catalogItems from "./catalogItems.js";
import {mapState} from "vuex";

export default {
  name: "Catalog",
  components: {
    CatalogProduct,
  },
  mixins: [catalogItems],
  data() {
    return {
      elements: {torty: 'torty', deserty: 'deserty', pirogi: 'pirogi', vypecka: 'vypecka'}
    }
  },
  computed: {
    ...mapState({
      products: state => state.products.products,
      cart: state => state.cart.products,
    })
  },
  methods: {
    getProducts(name) {
      return this[name] ? this[name] : [];
    },
    productAdded(id) {
      const {cart} = this;
      return !!cart.filter(item => item.id === id).length;
    },
    async showProductModalByQuery() {
      if (this.$route.query.id) {
        try {
          await this.$store.dispatch('products/getProduct', this.$route.query.id);
        } catch (e) {
          console.error(e);
        } finally {
          this.$bvModal.show('productModal');
        }
      }
    }
  },
  mounted() {
    if (!this.$route.query.section) {
      this.$router.push({query: {section: 'all'}})
    }
    this.showProductModalByQuery()
  }
}
</script>
