import axios from 'axios';

const state = {
    carouselItems: [],
    loading: false,
};

const getters = {};

const mutations = {
    setCarouselItems(state, payload) {
        state.carouselItems = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    }
};

const actions = {
    async getCarouselItems({state, commit}) {
        commit('setLoading', true);
        try {
            const { data: items} = await axios.get('/carousel');
            commit('setCarouselItems', items);
            commit('setLoading', false);
        } catch (e) {
            console.error(e);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
