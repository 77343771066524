const state =  {
    loader: false,
}
const getters = {
    loader: (state) => state.loader
}
const mutations =  {
    SET_LOADER(state,payload){
        state.loader = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}