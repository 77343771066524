<template>
  <div class="q-card popular-item">
    <img
        v-if="product.image"
      :src="product.image"
      :alt="product.name"
      class="popular-item__img"
    >
    <img
        v-else
      src="@/assets/images/default_product.png"
      :alt="product.name"
      class="popular-item__img"
    >

    <div class="popular-item__info">
      <div class="popular-item__title" @click="showProductModal">
        {{ product.name }}
      </div>
      <button
        :class="{'popular-item__button-checked': added}"
        class="q-btn q-btn-glow popular-item__button"
        @click="addProduct"
      >
        {{ numberWithSpaces(product.price) }} ₸
      </button>
    </div>
  </div>
</template>

<script>
export default {
    name: "PopularBlockItem",
    props: {
        product: Object,
        added: Boolean,
    },
    methods: {
        async showProductModal() {
            try {
                await this.$store.dispatch('products/getProduct', this.product.id);
            } catch (e) {
                console.error(e);
            } finally {
                this.$bvModal.show('productModal');
            }
        },
        addProduct() {
            this.$store.dispatch('cart/addProduct', this.product);
        },
    },
}
</script>
