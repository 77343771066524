<template>
  <b-container class="blog">
    <b-row>
      <b-col class="blog__title">
        {{ $t('Blog_title') }}
      </b-col>
    </b-row>
    <b-row
      v-if="items && items.length"
      cols="1"
      cols-md="2"
      cols-lg="3"
      cols-xl="4"
    >
      <b-col
        v-for="(item, i) in items"
        :key="`blog-item__${i}`"
      >
        <div class="q-card blog-item">
          <div class="blog-item__image" @click="openPost(item.id)">
            <img :src="item.image" :alt="item.caption">
          </div>
          <div class="blog-item__content">
            <div class="blog-item__title">
              {{ item.caption }}
            </div>
            <div class="blog-item__date">
              {{ item.created_at }}
            </div>
            <div class="blog-item__text">
              {{ item.text }}
            </div>
            <router-link :to="`/blog/${item.id}`" class="q-btn blog-item__button">{{ $t('Blog_open2blog') }}</router-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12">
        <div class="q-card promo-no-data p-5 mb-5">
          {{ $t('Blog_none_blogs') }}
        </div>
      </b-col>
    </b-row>
    <div v-if="false" class="blog__more-button">
      <a href="#" class="q-btn q-btn-teal">{{ $t('Blog_next_open') }}</a>
    </div>
    <info-block />
  </b-container>
</template>

<script>
import InfoBlock from "@/components/InfoBlock/InfoBlock.vue";
import { mapState } from "vuex";

export default {
    name: "Blog",
    components: {
        InfoBlock,
    },

    metaInfo: {
      title: 'Блоги | Доставка кондитерских изделий в Алмате',
      meta: [
        { vmid: 'description', property: 'description', content: 'Qulpynai блоги' },
        { vmid: 'og:title', property: 'og:title', content: 'Blogs Qulpynai' },
        { vmid: 'og:description', property: 'og:description', content: 'Qulpynai блоги' },
      ],
    },

    computed: {
        ...mapState({
            items: state => state.articles.blog,
        }),
    },

    created() {
        this.$store.dispatch('articles/getArticles', 'blog');
    },

    methods: {
        openPost(id) {
            this.$router.push(`/blog/${id}`);
        },
    },
}
</script>
