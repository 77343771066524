<template>
  <section class="popular">
    <b-row class="popular-header">
      <b-col>
        <div class="popular-title">
          {{ $t('PopularBlock_Popular_title') }}
        </div>
      </b-col>
      <b-col class="justify-content-end popular-halal-sign q-desktop-only">
          <img src="@/assets/images/halal.png" alt="halal">
          <span>{{ $t('PopularBlock_Popular_halal_01') }} <br>{{ $t('PopularBlock_Popular_halal_02') }}</span>
      </b-col>
    </b-row>
    <b-row v-if="products.length">
      <b-col>
        <swiper class="swiper popular-carousel" :options="swiperOption">
          <swiper-slide v-for="(product, i) in products" :key="`popular-product__${i}`">
            <popular-item :product="product" :added="productAdded(product.id)"/>
          </swiper-slide>
          <div
            class="swiper-pagination popular-carousel__pagination q-mobile-only"
            slot="pagination"
          ></div>
        </swiper>
        <div class="swiper-button-prev q-desktop-only" slot="button-prev"></div>
        <div class="swiper-button-next q-desktop-only" slot="button-next"></div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="justify-content-center popular-halal-sign q-mobile-only">
        <img src="@/assets/images/halal.png" alt="halal">
        <span>{{ $t('PopularBlock_Popular_halal_03') }} <br>{{ $t('PopularBlock_Popular_halal_04') }}</span>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import PopularItem from "./PopularBlockItem";
import { mapState } from "vuex";

export default {
    name: "PopularBlock",
    components: {
        PopularItem,
    },
    data() {
        return {
            swiperOption: {
                // slidesPerView: 4,
                centered: true,
                spaceBetween: 30,
                // loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1440: {
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    }
                }
            },
        }
    },
    computed: {
        ...mapState({
            products: state => state.products.novelties,
            cart: state => state.cart.products,
        }),
    },
    methods: {
        productAdded(id) {
            const { cart } = this;
            return !!cart.filter(item => item.id === id).length;
        },
    },
}
</script>
