
<template>
<div>
  <b-modal
      ref="warningModal"
      id="warningModal"
      size="xl"
      hide-header
      @hide="hideModal"
      hide-footer
      body-class="product-modal"
  >
 <div>
    <h5> {{$t('modal_last_time_intervals',{filial_name:this.filial_name.name,last_time_interval:this.last_time_interval,fisrt_time_interval:this.fisrt_time_interval})}}</h5>


   <div class="d-flex justify-content-end mt-5">
     <button class="q-btn" @click="hideModal"> {{$t('Modals_CustomModal_back')}}</button>
     <button class="q-btn q-btn-teal ml-2" @click="callback">{{$t('CatalogProduct_label_btn2no_sell')}}</button>
   </div>
 </div>
  </b-modal>
</div>
</template>
<script>
export default {
  props:{
    filial_name:{
     type:Object,
    },
    last_time_interval:{
    type: String | Number
    },
    fisrt_time_interval:{
    type: String | Number
    }
  },

  methods:{
    hideModal(){
       this.$bvModal.hide('warningModal')
    },
    callback(){
       this.$emit('callback')
    }
  }
}
</script>

<style scoped>

</style>