var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.product.stock !== undefined)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"catalog-product__stock"},[_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-colored': _vm.product.stock === 'red',
          'catalog-product__stock-cell-yellow': _vm.product.stock === 'yellow',
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-colored': _vm.product.stock === 'red',
          'catalog-product__stock-cell-yellow': _vm.product.stock === 'yellow',
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-yellow': _vm.product.stock === 'yellow',
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }