<template>
  <div>
    <div class="review-modal__title">
      {{ $t('Modals_Review_Auth_write2review') }}
    </div>
    <form
        v-if="step === 1"
        class="review-modal__form"
    >
      <div class="review-modal__form-text">
        {{ $t('Modals_Review_Auth_validation') }}
      </div>
      <form-group
          v-for="(input, i) in inputs"
          :key="`review-input__${i}`"
          v-model="form[input.name]"
          :label="$t(input.label)"
          :error="(errors[input.name] || [])[0]"
          :placeholder="$t(input.placeholder)"
          :type="input.type"
      />
    </form>
    <phone-approve-form
        v-if="step === 2"
        :phone="form.phone"
        @resendCode="resendCode"
        @sendCode="sendCode"
        :error="codeError"
    />

    <button
        v-if="step === 1"
        class="q-btn q-btn-teal review-modal__form-button"
        @click.prevent="submit"
    >
      {{ $t('Modals_Review_Auth_next') }}
    </button>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";
import PhoneApproveForm from "@/components/Auth/PhoneApproveForm.vue";

export default {
  name: "ReviewModalAuth",
  components: {
    FormGroup,
    PhoneApproveForm,
  },
  created() {
    if (this.$store.getters["auth/user"] && !this.$store.getters["auth/userVerified"]) {
      this.step = 2;
    }
  },
  data() {
    return {
      step: 1,
      inputs: [
        {
          name: 'name',
          label: 'Modals_Review_Auth_your_name',
          placeholder: 'Modals_Review_Auth_write2name',
          type: 'input'
        },
        {
          name: 'phone',
          label: 'Modals_Review_Auth_number',
          placeholder: '',
          type: 'phone',
        },
      ],
      form: {},
      errors: {},
      codeError: null,
    }
  },

  methods: {
    submit() {
      let { form } = this;
      form.phone =  (form.phone || '').replace(/\D+/g, "");
      form.is_registration = true;
      this.$store.dispatch("auth/register", form)
          .then(() => {
            this.step = 2;
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
          })
    },
    sendCode(code) {
      if (!this.$store.getters["auth/user"]) {
        let user = {
          phone: this.form.phone,
          code: code,
        };
        this.$store.dispatch('auth/loginByCode', user)
            .then(response => {
              console.log(response)
            })
            .catch(error => {
              if (error.response && error.response.status === 404) {
                this.codeError = true
              }
            })
      }else {
        this.$store.dispatch('auth/sendActivationCode', code)
            .then(response => {
              console.log(response)
            })
            .catch(error => {
              if (error.response && error.response.status === 404) {
                this.codeError = true
              }
            })
      }
      console.log(code);
    },
    resendCode() {
      if (!this.$store.getters["auth/user"]) {
        return this.submit()
      }else {
        return this.$store.dispatch('auth/getActivationCode')
          .then(() => {
            this.codeError = false
          })
      }
    },
  },
}
</script>
