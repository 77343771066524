var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"q-card cart-step",class:{ 'cart-step-inactive': false },attrs:{"id":"cart-step-2"}},[_c('div',{staticClass:"cart-step__title"},[_vm._v(" "+_vm._s(_vm.$t("Cart_Step2_index_header_step2"))+" ")]),(!_vm.isLoading)?_c('div',[_c('b-row',{staticClass:"cart-step__tabs"},[_c('b-col',{attrs:{"cols":"12","md":"5","xl":"4"}},[_c('div',{staticClass:"cart-step__tab",class:{
            'cart-step__tab-active': _vm.isDelivery,
            'cart-step__tab-inactive':
              !_vm.$store.getters['stopList/canDelivery'],
          },on:{"click":function($event){return _vm.toggleDelivery(true)}}},[_c('div',{staticClass:"cart-step__tab-title"},[_vm._v(" "+_vm._s(_vm.$t("Cart_Step2_index_tab_btn2delivery"))+" ")]),_c('div',{staticClass:"cart-step__tab-text"},[_vm._v(" "+_vm._s(_vm.amount < _vm.minAmount ? _vm.$t("Cart_Step2_index_min_delivery_price") : !_vm.$store.getters["stopList/canDelivery"] ? _vm.$t("Cart_Step2_index_delivery_not_access") : _vm.$t("Cart_Step2_index_delivery_address"))+" ")])])]),_c('b-col',{attrs:{"cols":"12","md":"5","xl":"4"}},[_c('div',{staticClass:"cart-step__tab",class:{
            'cart-step__tab-active': !_vm.isDelivery,
            'cart-step__tab-inactive': !_vm.$store.getters['stopList/canPickup'],
          },on:{"click":function($event){return _vm.toggleDelivery(false)}}},[_c('div',{staticClass:"cart-step__tab-title"},[_vm._v(" "+_vm._s(_vm.$t("Cart_Step2_index_tab_btn2pickup"))+" ")]),_c('div',{staticClass:"cart-step__tab-text"},[_vm._v(" "+_vm._s(!_vm.$store.getters["stopList/canPickup"] ? _vm.$t("Cart_Step2_index_pickup_not_access") : _vm.$t("Cart_Step2_index_pickup_paragraph"))+" ")])])]),_c('b-col',{attrs:{"cols":"12","md":"10","xl":"8"}},[(
            !_vm.$store.getters['stopList/canPickup'] &&
            !_vm.$store.getters['stopList/canDelivery']
          )?_c('div',{staticClass:"q-issue"},[_vm._v(" "+_vm._s(_vm.$t("Cart_Step2_index_pickup_delivery_not_access_01"))+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t("Cart_Step2_index_pickup_delivery_not_access_02"))+" ")]):_vm._e()])],1),(_vm.isDelivery && _vm.canOrder)?_c('delivery'):_vm._e(),(!_vm.isDelivery && _vm.canOrder)?_c('pickup'):_vm._e()],1):_c('div',[_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"main-spinner spinner-border text-info",staticStyle:{"width":"10rem","height":"10rem"},attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])
}]

export { render, staticRenderFns }