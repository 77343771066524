<template>
  <b-container class="bonus">
    <b-row>
      <b-col cols="12">
        <div class="q-card mb-5 bonus-info">
          {{ $t('Page_Bonuses_placeholder') }}
        </div>
      </b-col>
    </b-row>
    <b-row v-show="false">
      <b-col cols="12">
        <div class="q-card bonus-info">
          <div class="bonus-info__basic">
            <div class="bonus-info__basic-content">
              <div class="bonus-info__basic-title">
                {{ $t('Bonuses_title') }}
              </div>
              <div class="bonus-info__basic-text">
                {{$t('Bonuses_pravila')}}
              </div>
              <div class="bonus-info__basic-button">
                <a href="https://iiko.biz/ru-RU/L/034917?b=GAZCSK4JP7" target="_blank" class="q-btn">{{ $t('Bonuses_btn2bonus_card') }}</a>
<!--                <button onclick="https://iiko.biz/ru-RU/L/034917?b=GAZCSK4JP7" class="q-btn">{{ $t('Bonuses_btn2bonus_card') }}</button>-->
              </div>
            </div>
            <div class="bonus-info__basic-img">
              <img src="@/assets/images/card.png" alt="bonus-card">
            </div>
          </div>
          <div class="bonus-info__calc">
            <div class="bonus-info__calc-items">
              <div class="bonus-info__calc-item">
                <div class="bonus-info__calc-title">
                  {{ $t('Bonuses_size_summ') }} ₸
                </div>
              </div>
              <div class="bonus-info__calc-item">
                <div class="bonus-info__calc-sum">
                  {{ $t('Bonuses_from_1') }} ₸ <br>{{ $t('Bonuses_to_30k') }} ₸
                </div>
              </div>
              <div class="bonus-info__calc-item">
                <div class="bonus-info__calc-sum">
                  {{ $t('Bonuses_from_30k') }} ₸ <br> {{ $t('Bonuses_to_100k') }} ₸
                </div>
              </div>
              <div class="bonus-info__calc-item">
                <div class="bonus-info__calc-sum">
                  {{$t('Bonuses_from_100k')}}} ₸ <br>{{ $t('Bonuses_to_200k') }} ₸
                </div>
              </div>
              <div class="bonus-info__calc-item">
                <div class="bonus-info__calc-sum">
                  {{ $t('Bonuses_from_200k') }} ₸
                </div>
              </div>
            </div>
            <div class="bonus-info__calc-items">
              <div class="bonus-info__calc-item">
                <div class="bonus-info__calc-red">
                  {{ $t('Bonuses_percent') }}
                </div>
              </div>
              <div class="bonus-info__calc-item">
                <div class="bonus-info__calc-red">
                  3%
                </div>
              </div>
              <div class="bonus-info__calc-item">
                <div class="bonus-info__calc-red">
                  5%
                </div>
              </div>
              <div class="bonus-info__calc-item">
                <div class="bonus-info__calc-red">
                  7%
                </div>
              </div>
              <div class="bonus-info__calc-item">
                <div class="bonus-info__calc-red">
                  10%
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="q-card bonus-bday">
          <div class="bonus-bday__img">
            <img src="@/assets/images/bday.png" alt="bday">
          </div>
          <div class="bonus-bday__info">
            <div class="bonus-bday__info-title">
              {{ $t('Bonuses_bday_title') }}
              <br>{{ $t('Bonuses_pravila_title_bday') }}
              <br>{{ $t('Bonuses_extra_cashback') }}
            </div>
            <div class="bonus-bday__content">
              <div class="bonus-bday__content-percent">
                <span>10%</span>
                {{ $t('Bonuses_sum_price') }}
              </div>
              <div class="bonus-bday__content-body">
                <div class="bonus-bday__content-title">
                  {{ $t('Bonuses_how_to_use') }}
                </div>
                <div class="bonus-bday__content-text">
                  {{ $t('Bonuses_sum_percent') }}
                </div>
                <div class="bonus-bday__content-text">
                  {{ $t('Bonuses_to_20k_for_month') }}
                </div>
                <div class="bonus-bday__content-text">
                  {{ $t('Bonuses_how_to_spend') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="q-card bonus-reg">
          <div class="bonus-reg__title">
            {{ $t('Bonuses_register') }}
          </div>
          <div class="bonus-reg__items">
            <div class="bonus-reg__item">
              <div class="bonus-reg__item-img">
                <img src="@/assets/images/bonus-reg-1.png" alt="reg-step-1">
              </div>
              <div class="bonus-reg__item-title">
                {{ $t('Bonuses_qr') }}
              </div>
            </div>
            <div class="bonus-reg__item">
              <div class="bonus-reg__item-img">
                <img src="@/assets/images/bonus-reg-2.png" alt="reg-step-2">
              </div>
              <div class="bonus-reg__item-title">
                {{ $t('Bonuses_number') }}
              </div>
            </div>
            <div class="bonus-reg__item">
              <div class="bonus-reg__item-img">
                <img src="@/assets/images/bonus-reg-3.png" alt="reg-step-3">
              </div>
              <div class="bonus-reg__item-title">
                {{$t('Bonuses_verification')}}
              </div>
            </div>
            <div class="bonus-reg__item">
              <div class="bonus-reg__item-img">
                <img src="@/assets/images/bonus-reg-4.png" alt="reg-step-4">
              </div>
              <div class="bonus-reg__item-title">
                {{ $t('Bonuses_write2data') }}
              </div>
            </div>
            <div class="bonus-reg__item">
              <div class="bonus-reg__item-img">
                <img src="@/assets/images/bonus-reg-5.png" alt="reg-step-5">
              </div>
              <div class="bonus-reg__item-title">
                {{ $t('Bonuses_card') }}
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
<!--    <info-block />-->
  </b-container>
</template>

<script>
// import InfoBlock from "@/components/InfoBlock/InfoBlock";

export default {
    name: "Bonuses",
    // components: {
    //     InfoBlock,
    // },

    metaInfo: {
      title: 'Бонусы | Доставка кондитерских изделий в Алмате',
      meta: [
        { vmid: 'description', property: 'description', content: 'Qulpynai Бонусы' },
        { vmid: 'og:title', property: 'og:title', content: 'Bonuses Qulpynai' },
        { vmid: 'og:description', property: 'og:description', content: 'Qulpynai Бонусы' },
      ],
    },

}
</script>
