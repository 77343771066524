<template>
  <b-col cols="12">
    <div class="about-us-img">
      <img src="@/assets/images/partners.jpg" alt="">
    </div>
    <div class="q-card about-us__card">
      <b-row class="justify-content-center">
        <b-col cols="12" lg="6">
          <div class="about-us__paragraph">
            {{ $t('about_Partners_aboutUs_p1') }}
          </div>
          <div class="about-us__paragraph">
            {{ $t('about_Partners_aboutUs_p2') }}
          </div>
        </b-col>

        <b-col cols="12" lg="6">
          <feedback-form type="suggestion"/>
        </b-col>
      </b-row>
    </div>
  </b-col>
</template>

<script>
import FeedbackForm from "@/components/Forms/FeedbackForm.vue";

export default {
    name: "Partners",
    components: {
        FeedbackForm,
    },

    metaInfo: {
      title: 'Партнерам | Доставка кондитерских изделий в Алмате',
      meta: [
        { vmid: 'description', property: 'description', content: 'Qulpynai Партнерам' },
        { vmid: 'og:title', property: 'og:title', content: 'Partners Qulpynai' },
        { vmid: 'og:description', property: 'og:description', content: 'Qulpynai Партнерам' },
      ],
    },

}
</script>
