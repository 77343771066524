<template>
  <div id="cabinetOrder">
    <order-card v-if="currentOrder && currentOrder.id" cabinet />
    <div v-else class="q-card cabinet-card">
      <div class="cabinet-card__title">{{ $t('Cabinet_CabinetOrder_check_status') }}</div>
      <div class="cabinet-card__text">{{ $t('Cabinet_CabinetOrder_null_orders') }}</div>
      <button class="q-btn q-btn-teal cabinet-card__button">{{ $t('Cabinet_CabinetOrder_btn2menu') }}</button>
    </div>
  </div>
</template>

<script>
import OrderCard from "@/components/Order/OrderCard.vue";
import { mapState } from "vuex";

export default {
    name: "CabinetOrder",
    components: {
        OrderCard,
    },

    computed: {
        ...mapState({
            currentOrder: state => state.order.order,
        }),
    },
}
</script>
