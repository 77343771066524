<template>
  <b-container class="cabinet">
    <b-row>
      <b-col v-if="currentUser && !currentUser.verified" cols="12" lg="8">
        <cabinet-verify />
      </b-col>
      <b-col v-else cols="12" lg="8">
        <div class="q-card cabinet-card">
          <div class="cabinet-card__title">{{ $t('Cabinet_how_to_use_bonuses') }}</div>
          <div class="cabinet-card__text">
            {{ $t('Cabinet_bonuses_step1') }}
            <br>{{ $t('Cabinet_bonuses_step2') }}
            <br>{{ $t('Cabinet_bonuses_step3') }}
          </div>
          <router-link to="/bonuses" class="q-btn q-btn-teal cabinet-card__button">{{ $t('Cabinet_btn2bonuses') }}</router-link>
        </div>

        <cabinet-orders />

        <cabinet-order />
      </b-col>

      <cabinet-profile />
    </b-row>
    <cabinet-modal />
    <cabinet-bday-modal />
  </b-container>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";
import CabinetOrders from "@/components/Cabinet/CabinetOrders";
import CabinetOrder from "../components/Cabinet/CabinetOrder";
import CabinetVerify from "@/components/Cabinet/CabinetVerify.vue";
import CabinetProfile from "@/components/Cabinet/CabinetProfile.vue";
import CabinetModal from "@/components/Cabinet/CabinetModal.vue";
import CabinetBdayModal from "@/components/Cabinet/CabinetBdayModal.vue";
import { mapState } from "vuex";

export default {
    name: "Cabinet",
    components: {
        FormGroup,
        CabinetOrders,
        CabinetOrder,
        CabinetVerify,
        CabinetProfile,
        CabinetModal,
        CabinetBdayModal,
    },
    metaInfo: {
      title: 'Личный кабинет | Доставка кондитерских изделий в Алмате',
      meta: [
        { vmid: 'description', property: 'description', content: 'Qulpynai кабинет' },
        { vmid: 'og:title', property: 'og:title', content: 'Cabinet Qulpynai' },
        { vmid: 'og:description', property: 'og:description', content: 'Qulpynai кабинет' },
      ],
    },

    computed: {
        ...mapState({
            currentOrder: state => state.order.order,
            history: state => state.order.history,
            loading: state => state.order.loading,
        }),
    },
    mounted() {
        if (this.currentUser) this.$store.dispatch('order/getHistoryOrders');
    }
}
</script>
