<template>
  <div>
    <div v-show="step === 1">
      <div class="auth-form__text">
        {{ $t('Auth_PasswordRecoveryForm_text_01') }}
      </div>
      <form-group
        v-model="recoveryForm.phone"
        :error="recoveryErrors.phone && recoveryErrors.phone[0]"
        type="phone"
        :label="$t('Auth_PasswordRecoveryForm_label_01')"
      />
      <button class="q-btn q-btn-teal auth-form__submit" @click="recovery">{{ $t('Auth_PasswordRecoveryForm_btn2next') }}</button>
    </div>
    <div v-show="step === 2">
      <phone-approve
        v-if="step === 2"
        :phone="(recoveryForm || {}).phone"
        :error="resetErrors.code && resetErrors.code[0]"
        @sendCode="setCode"
        @resendCode="recovery"
      />

      <form-group
        v-model="resetForm.password"
        :error="resetErrors.password && resetErrors.password[0]"
        type="password"
        :label="$t('Auth_PasswordRecoveryForm_label_02')"
        placeholder="*****"
      />
      <form-group
        v-model="resetForm.password_confirmation"
        :error="resetErrors.password_confirmation && resetErrors.password_confirmation[0]"
        type="password"
        :label="$t('Auth_PasswordRecoveryForm_label_03')"
        placeholder="*****"
      />
      <button class="q-btn q-btn-teal auth-form__submit" @click="reset">{{ $t('Auth_PasswordRecoveryForm_btn2enter_01') }}</button>
    </div>
    <div
      v-show="step < 3"
      class="auth-form__link"
      @click="login"
    >
      {{ $t('Auth_PasswordRecoveryForm_btn2login') }}
    </div>
    <div v-if="step === 3">
      {{ $t('Auth_PasswordRecoveryForm_btn2success') }}
      <button class="q-btn q-btn-teal auth-form__submit mt-3" @click="login">{{ $t('Auth_PasswordRecoveryForm_btn2enter_02') }}</button>
    </div>
  </div>
</template>

<script>
import FormGroup from "../FormGroup/FormGroup.vue";
import PhoneApprove from "./PhoneApproveForm.vue";

export default {
    name: "PasswordRecoveryForm",
    components: {
        FormGroup,
        PhoneApprove,
    },
    data() {
        return {
            step: 1,
            recoveryForm: {},
            recoveryErrors: {},

            resetForm: {},
            resetErrors: {},
        }
    },
    methods: {
        async recovery() {
          let { recoveryForm:  form } = this;
          form.phone =  (form.phone || '').replace(/\D+/g, "");
          try {
              await this.$store.dispatch('auth/recovery', form);
              this.step = 2;
          }  catch (error) {
              this.recoveryErrors = ((error.response || {}).data || {}).errors || {};
          }
        },
        async reset() {
            let { resetForm:  form } = this;
            form.phone =  this.recoveryForm.phone.replace(/\D+/g, "");;
            try {
                await this.$store.dispatch('auth/resetPassword', form);
                this.step = 3;
            }  catch (error) {
                this.resetErrors = ((error.response || {}).data || {}).errors || {};
            }
        },
      login() {
        this.step = 1;
        this.$emit('back')
      },

        setCode(code) {
            this.resetForm.code = code;
        }
    },
}
</script>
