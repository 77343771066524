<template>
  <div id="app" :class="[loader === true ? 'loader-content' : '']" :key="appKey">
    <div v-if="!loader" class="main">
      <main-nav />

      <router-view/>

      <main-footer />
    </div>
    <div v-else>
      <div class="d-flex justify-content-center">
      <div style="width: 10rem; height: 10rem;" class="main-spinner spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      </div>
    </div>

    <product-modal />
    <review-modal />
    <custom-modal />

    <notifications
      group="foo"
      classes="q-notifications"
      width="180px"
    />
  </div>
</template>

<style lang="scss">
@import "./assets/style/index.scss";

</style>

<script>
import MainNav from "@/components/MainNav/MainNav.vue";
import MainFooter from "@/components/Footer/Footer.vue";
import ProductModal from "@/components/Modals/ProductModal.vue";
import ReviewModal from "@/components/Modals/ReviewModal.vue";
import CustomModal from "@/components/Modals/CustomModal";
import {loadLanguageAsync} from "@/i18n/i18n";

export default {
    components: {
        MainNav,
        MainFooter,
        ProductModal,
        ReviewModal,
        CustomModal,
    },
    data() {
        return {
            appKey: 0,
        }
    },
    async created() {
       this.$store.commit('loader/SET_LOADER',true)
        this.$watch(
            "$i18n.locale",
            (newLocale, oldLocale) => {
                if (newLocale === oldLocale) {
                    return
                }
                loadLanguageAsync(newLocale)
                    .then(() => {
                      this.$store.commit('loader/SET_LOADER',false)
                      if (newLocale && oldLocale) {
                            this.forceRerender();
                        }
                    })
            },
            { immediate: true }
        );

        this.loadData();
        await this.$store.dispatch("products/getProducts");

        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'auth/SET_USER') {
                this.$store.commit('cart/RESET_TOKEN');
                this.$store.dispatch('cart/getCart');
            }
            if (mutation.type === 'auth/CLEAR_USER') {
                this.$store.commit('cart/RESET_CARD');
            }
            if (mutation.type === 'cart/SET_CART_ID' && this.$route.path === '/cart') {
                this.$store.dispatch('stopList/getCartStopList');
            }
            if (mutation.type === 'auth/SET_USER' && this.$route.path === '/cabinet') {
                this.$store.dispatch('order/getHistoryOrders');
            }
            if (mutation.type === 'auth/CLEAR_USER' && this.$route.meta.auth) {
                this.$router.push('/');
            }
        })
    },
    beforeDestroy() {
        this.unsubscribe();
    },
    computed:{
      loader(){
        return this.$store.getters["loader/loader"]
      }
    },
    methods: {
      loadData() {
        this.$store.dispatch("auth/fetchUser");
        if (!this.$store.state.auth.token) {
          this.$store.dispatch('cart/getCart');
        }
      },
        forceRerender() {
            this.appKey++;
            this.$store.commit('modalProduct/SET_PRODUCTS', []);
            this.loadData()
        },
    },
}
</script>
