<template>
  <div v-if="!isMobile" class="main-header">
    <b-container>
      <b-row>
        <div class="main-nav">
          <div class="main-nav__left">
            <router-link
              v-for="(item, i) in menu"
              :key="`nav-menu-item__${i}`"
              :class="{'ml-135': !navStick && i === 0}"
              :to="item.link"
              class="main-nav__link"
            >
              {{ $t(item.name) }}
            </router-link>
            <router-link to="/whatsapp" class="main-nav__link q-btn q-btn-green">{{ $t('MainNav_whatsapp') }}</router-link>
          </div>

<!--         <a href="https://api.whatsapp.com/send/?phone=77471111818&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C+%D1%82%D0%BE%D1%80%D1%82&type=phone_number&app_absent=0"-->
<!--            target="_blank"-->
<!--            class="q-btn q-btn-green">-->
<!--           WhatsApp-->
<!--         </a>-->

          <div class="main-nav__right" style="z-index: 1 ">

            <a href="tel:77001101059" class="main-nav__right-link">+7 (700) 110 10 59</a>

            <q-dropdown
              :options="cityOptions"
              :value="cityOptions[0]"
            />
            <q-dropdown
              :options="langOptions"
              @callback="langHandler"
              :value="currentLang"
            />
          </div>
        </div>
      </b-row>
    </b-container>
    <main-nav-sticky
      :is-cart="isCart"
      :nav-stick="navStick"
    />
  </div>
  <main-nav-mobile
    v-else
    :sticky="navStick"
  />
</template>

<script>
import QDropdown from "@/components/QDropdown/QDropdown.vue";

import MainNavMobile from "./MainNavMobile.vue";
import MainNavSticky from "./MainNavSticky.vue";
import { mapState } from "vuex";

export default {
    name: "MainNav",
    components: {
        QDropdown,
        MainNavMobile,
        MainNavSticky,
    },
    data() {
        return {
            cityOptions: [{name:'Алматы'}],
            langOptions: [
                {
                    name: 'Рус',
                    value: 'ru',
                },
                {
                    name: 'Қаз',
                    value: 'kz'
                }
            ],
            menu: [
                {
                    name: 'MainNav_menu',
                    link: '/?section=all',
                },
                {
                    name: 'MainNav_blog',
                    link: '/blog',
                },
                {
                    name: 'MainNav_about_us',
                    link: '/about',
                },
                {
                    name: 'MainNav_contacts',
                    link: '/contacts',
                },
                {
                    name: 'MainNav_bonuses',
                    link: '/bonuses',
                },
            ],

            navStick: false,
            isMobile: false,
        }
    },
    created() {
        this.windowResize();
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', this.windowResize);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('resize', this.windowResize);
    },

    computed: {
        isCart() {
            return this.$route.path === '/cart'
        },
    },

    methods: {
        onScroll () {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            this.navStick = currentScrollPosition > 50;
        },
        windowResize() {
            const windowSize = window.innerWidth;
            this.isMobile = windowSize < 1023;
        },
        langHandler(){
          setTimeout(()=>{
            window.location.reload();
          },1000)
        }
    }
}
</script>
