<template>
  <b-container>
    <b-row class="justify-content-center promo-post">
      <b-col cols="12" md="12" lg="9" class="">
        <div class="promo-post__head">
          <div class="promo-post__title">
            {{ $t('Promo_PromoPost_delivery_free') }}
          </div>
          <div class="promo-post__back q-desktop-only">{{ $t('Promo_PromoPost_back') }}</div>
        </div>
        <b-row class="q-card">
          <b-col cols="12" md="6" order="2">
            <div class="promo-post__content">
              <div>
                <div class="promo-post__paragraph">
                  <span class="q-bold">6220</span>
                  {{$t('Promo_PromoPost_paragraph_01')}}
                </div>
                <div class="promo-post__paragraph">
                  {{$t('Promo_PromoPost_paragraph_02')}}
                </div>
              </div>
              <div class="promo-post__content-footer">
                <form-group :label="$t('Promo_PromoPost_title_promocode')" />
                <button class="q-btn promo-post__button">{{ $t('Promo_PromoPost_btn2promocode') }}</button>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="6" order="1" order-md="3" class="promo-post__image">
            <img src="@/assets/images/promopost.jpg" alt="promo-post">
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <info-block />
  </b-container>
</template>

<script>
import InfoBlock from "@/components/InfoBlock/InfoBlock.vue";
import FormGroup from "@/components/FormGroup/FormGroup.vue";

export default {
    name: "PromoPost",
    components: {
        InfoBlock,
        FormGroup,
    },
}
</script>

<style scoped>

</style>
