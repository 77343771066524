<template>
  <div>
    <b-modal
      ref="TimeConfirmModal"
      id="TimeConfirmModal"
      size="md"
      hide-header
      @hide="hideModal"
      hide-footer
      body-class="product-modal"
      centered
      no-fade
    >
      <div>
        <!-- <div class="row_line">
          <div
            class="tab pointer"
            style="margin-right: 20px"
            :class="{ border_underline: isDelivery }"
            @click="isDelivery = true"
          >
            {{ $t("Cart_Step2_index_tab_btn2delivery") }}
          </div>
          <div
            class="tab pointer"
            :class="{ border_underline: !isDelivery }"
            @click="isDelivery = false"
          >
            {{ $t("Cart_Step2_index_tab_btn2pickup") }}
          </div>
        </div> -->
        <div class="text">{{ $t("time_confirm_modal_main_text") }}</div>
        <div class="q-form-group__label"></div>
        <b-row v-if="isDelivery">
          <b-col>
            <form-group
              v-model="selectedDateDelivery"
              :options="deliveryDates"
              type="select2"
              :label="$t('Cart_Step2_delivery_date_label')"
              :placeholder="$t('Cart_Step2_delivery_date_placeholder')"
            />
          </b-col>
          <b-col>
            <form-group
              v-model="selectedTimeDelivery"
              :options="deliveryTimes"
              type="select2"
              :label="$t('Cart_Step2_delivery_time_label')"
              :placeholder="$t('Cart_Step2_delivery_time_placeholder')"
            />
          </b-col>
        </b-row>
        <b-row v-if="!isDelivery">
          <b-col class="w-50">
            <form-group
              v-model="selectedPickupDate"
              :options="pickupDates"
              type="select2"
              :label="$t('Cart_Step2_pickup_date_01')"
              :placeholder="$t('Cart_Step2_pickup_date_02')"
            />
          </b-col>
          <b-col class="w-50">
            <form-group
              v-model="selectedPickupTime"
              :options="pickupTimes"
              type="select2"
              :label="$t('Cart_Step2_pickup_time_01')"
              :placeholder="$t('Cart_Step2_pickup_time_02')"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <button class="q-btn q-btn-red float-left" @click="nextCreateOrder">
          {{ $t("Cart_Step4_Friend_btn2order") }}
        </button>
        <button class="q-btn q-btn float-right" @click="hideModal">
          {{ $t("time_confirm_modal_back") }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";
import { mapState } from "vuex";
export default {
  name: "TimeConfirmModal",
  components: { FormGroup },
  data() {
    return {
      tabIndex: 1,
    };
  },
  computed: {
    ...mapState({
      deliveryDates: (state) => state.cart.deliveryDates,
      deliveryTimes: (state) => state.cart.deliveryTimes,
      pickupDates: (state) => state.cart.pickupDates,
      pickupTimes: (state) => state.cart.pickupTimes,
      //selectedFilial: (state) => state.filial.activeFilial,
    }),
    isDelivery: {
      get() {
        return this.$store.state.cart.isDelivery;
      },
      set(isDelivery) {
        this.$store.commit("cart/SET_IS_DELIVERY", isDelivery);
      },
    },
    selectedDateDelivery: {
      get() {
        return this.$store.state.cart.selectedDeliveryDate;
      },
      set(selectedDateDelivery) {
        this.$store.commit(
          "cart/SET_SELECTED_DELIVERY_DATE",
          selectedDateDelivery
        );
      },
    },
    selectedTimeDelivery: {
      get() {
        return this.$store.state.cart.selectedDeliveryTime;
      },
      set(selectedTimeDelivery) {
        this.$store.commit(
          "cart/SET_SELECTED_DELIVERY_TIME",
          selectedTimeDelivery
        );
      },
    },
    selectedPickupDate: {
      get() {
        return this.$store.state.cart.selectedPickupDate;
      },
      set(selectedPickupDate) {
        this.$store.commit("cart/SET_SELECTED_PICKUP_DATE", selectedPickupDate);
      },
    },
    selectedPickupTime: {
      get() {
        return this.$store.state.cart.selectedPickupTime;
      },
      set(selectedPickupTime) {
        this.$store.commit("cart/SET_SELECTED_PICKUP_TIME", selectedPickupTime);
      },
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("TimeConfirmModal");
    },
    callback() {
      this.$emit("callback");
    },
    nextCreateOrder() {
      this.$emit("createOrder", true);
      this.hideModal();
    },
  },
};
</script>

<style scoped>
.row_line {
  display: flex;
  flex-wrap: wrap;
}
.tab {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.075em;
  color: #387583;
}
.border_underline {
  border-bottom: 3px solid #387583;
  padding-bottom: 3px;
}
.text {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: unset;
  color: #387583;
  font-weight: normal;
  margin: 20px 0px;
}
</style>
