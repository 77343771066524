<template>
  <b-col class="catalog-product-wrapper">
    <div class="q-card catalog-product">
      <div class="next-day-block" :title="$t('CatalogProduct_label_have_next_day')">
              <span v-if="product.next_day">
                <img src="../../assets/icons/next-day.svg" alt />
              </span>
      </div>
      <div
        v-if="product.label"
        :class="{
        'catalog-product__label-red': product.label === 'hit',
        'catalog-product__label-veg': product.label === 'veg'}"
        class="catalog-product__label"
      >
        {{ product.label === 'hit' ? $t('CatalogProduct_label_tag_01') : ( product.label === 'veg' ? $t('CatalogProduct_label_tag_02') : $t('CatalogProduct_label_tag_03') ) }}
      </div>
      <div
        v-if="product.stock !== undefined"
        class="catalog-product__stock"
        v-b-tooltip.hover
        :title="tooltip"
      >
        <div class="catalog-product__stock-cell"
          :class="{
            'catalog-product__stock-cell-colored': product.stock === 'red',
            'catalog-product__stock-cell-yellow': product.stock === 'yellow',
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"
        ></div>
        <div class="catalog-product__stock-cell"
             :class="{
            'catalog-product__stock-cell-colored': product.stock === 'red',
            'catalog-product__stock-cell-yellow': product.stock === 'yellow',
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
        <div class="catalog-product__stock-cell"
             :class="{
            'catalog-product__stock-cell-yellow': product.stock === 'yellow',
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
        <div class="catalog-product__stock-cell"
             :class="{
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
        <div class="catalog-product__stock-cell"
             :class="{
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
      </div>

      <div
        class="catalog-product__triggerable"
        @click="showProductModal"
      >
        <div class="catalog-product__img">
          <img v-if="product.image" :src="product.image" alt="product">
          <img v-else src="@/assets/images/default_product.png" alt="product">
        </div>
        <div class="catalog-product__title">{{ product.name }}</div>
      </div>
      <div class="catalog-product__price">
        {{ prettyPrice(product.price) }} /
        <span class="value">{{ product.measure_unit }}</span>
      </div>

      <button
            v-if="product.is_stop"
            class="q-btn q-btn-teal q-btn-icon catalog-product__button"
            disabled
            @click="addProduct"
      >
        {{ $t('CatalogProduct_label_btn2_no_stock') }}
      </button>

      <a href="https://api.whatsapp.com/send/?phone=77471111818&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C+%D1%82%D0%BE%D1%80%D1%82&type=phone_number&app_absent=0" target="_blank"
        v-else-if="product.is_special"
        class="q-btn q-btn"
      >
        {{ $t('CatalogProduct_label_btn2no_sell') }}
      </a>

      <button
        v-else-if="added"
        class="q-btn q-btn-icon catalog-product__button catalog-product__button-added"
        @click="addProduct"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
      >
        {{ hovered ? $t('CatalogProduct_label_btn2cart'): $t('CatalogProduct_label_btn2added') }}
      </button>
      <button
        v-else
        class="q-btn q-btn-teal q-btn-icon catalog-product__button"
        @click="addProduct"
      >
        {{ $t('CatalogProduct_label_btn2cart') }}
      </button>
    </div>
  </b-col>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "CatalogProduct",
    props: {
        product: Object,
        added: Boolean,
    },
    data() {
        return {
            hovered: false,
        }
    },
    computed: {
        ...mapState({
            loading: state => state.card.addLoading,
        }),
        tooltip() {
            if (this.product.stock === 'green') {
                return this.$t('CatalogProduct_label_have_in_stock')
            }
            if (this.product.stock === 'yellow') {
                return this.$t('CatalogProduct_label_have_not_more')
            }
            return this.$t('CatalogProduct_label_doesnot_have')
        },
    },
    methods: {
        async showProductModal() {
            try {
                this.$router.push({query:{...this.$route.query,id:this.product.id}})
                await this.$store.dispatch('products/getProduct', this.product.id);
                localStorage.setItem('indicator', this.product.stock);
            } catch (e) {
                console.error(e);
            } finally {
                this.$bvModal.show('productModal', {stock: this.product.stock});
            }
        },
        prettyPrice(x) {
            if (typeof x !== 'number') return x;
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ₸';
        },
        async addProduct() {
            try {
                await this.$store.dispatch('cart/addProduct', this.product);
            } catch (e) {
                console.error(e);
            }
        },
        hoverProduct() {

        },
    },
}
</script>

<style lang="scss" scoped>
.next-day-block {
  position: absolute;
  top: 10px;
  left: 15px;
  cursor: pointer;

  img {
    width: 25px;
  }
}
</style>
