var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{staticClass:"catalog-product-wrapper"},[_c('div',{staticClass:"q-card catalog-product"},[_c('div',{staticClass:"next-day-block",attrs:{"title":_vm.$t('CatalogProduct_label_have_next_day')}},[(_vm.product.next_day)?_c('span',[_c('img',{attrs:{"src":require("../../assets/icons/next-day.svg"),"alt":""}})]):_vm._e()]),(_vm.product.label)?_c('div',{staticClass:"catalog-product__label",class:{
      'catalog-product__label-red': _vm.product.label === 'hit',
      'catalog-product__label-veg': _vm.product.label === 'veg'}},[_vm._v(" "+_vm._s(_vm.product.label === 'hit' ? _vm.$t('CatalogProduct_label_tag_01') : ( _vm.product.label === 'veg' ? _vm.$t('CatalogProduct_label_tag_02') : _vm.$t('CatalogProduct_label_tag_03') ))+" ")]):_vm._e(),(_vm.product.stock !== undefined)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"catalog-product__stock",attrs:{"title":_vm.tooltip}},[_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-colored': _vm.product.stock === 'red',
          'catalog-product__stock-cell-yellow': _vm.product.stock === 'yellow',
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-colored': _vm.product.stock === 'red',
          'catalog-product__stock-cell-yellow': _vm.product.stock === 'yellow',
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-yellow': _vm.product.stock === 'yellow',
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }}),_c('div',{staticClass:"catalog-product__stock-cell",class:{
          'catalog-product__stock-cell-green': _vm.product.stock === 'green'
        }})]):_vm._e(),_c('div',{staticClass:"catalog-product__triggerable",on:{"click":_vm.showProductModal}},[_c('div',{staticClass:"catalog-product__img"},[(_vm.product.image)?_c('img',{attrs:{"src":_vm.product.image,"alt":"product"}}):_c('img',{attrs:{"src":require("@/assets/images/default_product.png"),"alt":"product"}})]),_c('div',{staticClass:"catalog-product__title"},[_vm._v(_vm._s(_vm.product.name))])]),_c('div',{staticClass:"catalog-product__price"},[_vm._v(" "+_vm._s(_vm.prettyPrice(_vm.product.price))+" / "),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.product.measure_unit))])]),(_vm.product.is_stop)?_c('button',{staticClass:"q-btn q-btn-teal q-btn-icon catalog-product__button",attrs:{"disabled":""},on:{"click":_vm.addProduct}},[_vm._v(" "+_vm._s(_vm.$t('CatalogProduct_label_btn2_no_stock'))+" ")]):(_vm.product.is_special)?_c('a',{staticClass:"q-btn q-btn",attrs:{"href":"https://api.whatsapp.com/send/?phone=77471111818&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C+%D1%82%D0%BE%D1%80%D1%82&type=phone_number&app_absent=0","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('CatalogProduct_label_btn2no_sell'))+" ")]):(_vm.added)?_c('button',{staticClass:"q-btn q-btn-icon catalog-product__button catalog-product__button-added",on:{"click":_vm.addProduct,"mouseover":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}},[_vm._v(" "+_vm._s(_vm.hovered ? _vm.$t('CatalogProduct_label_btn2cart'): _vm.$t('CatalogProduct_label_btn2added'))+" ")]):_c('button',{staticClass:"q-btn q-btn-teal q-btn-icon catalog-product__button",on:{"click":_vm.addProduct}},[_vm._v(" "+_vm._s(_vm.$t('CatalogProduct_label_btn2cart'))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }