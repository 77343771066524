import axios from "axios";

const state = {
    filials: [],
    activeFilial: {},
    minAmount: null,
};

const getters = {

};

const mutations = {
    SET_FILIALS(state, filials) {
        state.filials = filials;
    },
    SET_ACTIVE_FILIAL(state, filial) {
        state.activeFilial = filial;
    },
    SET_ACTIVE_FILIAL_BY_ID(state, filialId) {
        const filials = state.filials;
        state.activeFilial = filials.filter(filial => {
            return filial.id === filialId;
        })[0];
    },
    SET_MIN_AMOUNT(state, payload) {
        state.minAmount = payload;
    },
};

const actions = {
    async getFilials({ commit }) {
        await axios.get("filial")
            .then(response => {
                commit('SET_FILIALS', response.data.filials);
                commit('cart/SET_MAX_POST_PAYMENT_AMOUNT', response.data.max_post_payment_amount, {root: true});
                commit('SET_MIN_AMOUNT', response.data.min_amount_for_delivery);
            });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
