import axios from "axios";

const RED_MARKER = "red";
const GREEN_MARKER = "green";
const YELLOW_MARKER = "yellow";

const state = {
  stopList: {},
  canDelivery: true,
  canPickup: true,
};

const getters = {
  filialIsOutOfStock: (state, getters) => (filialId) => {
    return (
      [RED_MARKER, YELLOW_MARKER].indexOf(getters.filialMarker(filialId)) !== -1
    );
  },
  filialMarker: (state, getters, rootState, rootGetters) => (filialId) => {
    try {
      const params = {
        date: rootGetters["cart/getSelectedDate"],
        type: rootGetters["cart/getOrderType"],
        time: rootGetters["cart/getSelectedTime"],
        filialId,
      };

      const filialStopList =
        state.stopList[params.date][params.type][params.time][filialId];
      const filialStopListArray = Object.keys(filialStopList).map(
        (item) => filialStopList[item]
      );
      const filialStopListLength = filialStopListArray.length;
      const productCount = rootState.cart.products.length;
      const isOutOfStock = filialStopListArray.every((productClass) => {
        return productClass === RED_MARKER;
      });
      if (filialStopListLength === productCount && isOutOfStock) {
        return RED_MARKER;
      }
      const someIsOutOfStock = filialStopListArray.some((productClass) => {
        return productClass === RED_MARKER;
      });
      if (someIsOutOfStock) {
        return YELLOW_MARKER;
      }
    } catch (e) {}

    return GREEN_MARKER;
  },
  productIsOutOfStock:
    (state, getters, rootState, rootGetters) => (productId) => {
      try {
        return (
          state.stopList[rootGetters["cart/getSelectedDate"]][
            rootGetters["cart/getOrderType"]
          ][rootGetters["cart/getSelectedTime"]][
            rootState.filial.activeFilial.id
          ][productId] === RED_MARKER
        );
      } catch (e) {}
      return false;
    },
  canDelivery: (state, getters, rootState) => {
    return (
      state.canDelivery && rootState.cart.amount > rootState.filial.minAmount
    );
  },
  canPickup: (state) => {
    return state.canPickup;
  },
  canOrder: (state, getters) => {
    return getters.canPickup || state.canDelivery;
  },
};

const mutations = {
  SET_STOP_LIST(state, stopList) {
    state.stopList = stopList;
  },
  SET_CAN_DELIVERY(state, canDelivery) {
    state.canDelivery = canDelivery;
  },
  SET_CAN_PICKUP(state, canPickup) {
    state.canPickup = canPickup;
  },
};

const actions = {
  getCartStopList(context) {
    if (
      !context.rootState.cart.cartId ||
      !context.rootState.cart.products.length
    ) {
      return;
    }
    axios
      .get(`cart/${context.rootState.cart.cartId}/stop_list`)
      .then((response) => {
        context.commit("SET_STOP_LIST", response.data.stop_list);
        context.commit("SET_CAN_DELIVERY", response.data.can_delivery);
        context.commit("SET_CAN_PICKUP", response.data.can_pickup);
        context.commit("cart/SET_PERIODS", response.data, { root: true });
        if (!response.data.can_delivery && response.data.can_pickup) {
          context.commit("cart/SET_IS_DELIVERY", response.data.can_delivery, {
            root: true,
          });
        }
      });
  },
};

// Экспортируем наружу
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
