<template>
 <div class="main-carousel">
   <swiper
     v-if="items && items.length"
     :options="swiperOption"
     class="swiper"
   >
     <swiper-slide
       v-for="(item, i) in items"
       :key="`main-carousel-item__${i}`"
     >
       <div class="main-carousel__slide">
         <img :src="item[carouselImage]" :alt="item.text">
         <div class="main-carousel__slide-content">
           <div class="main-carousel__slide-title">{{ item.text }}</div>
<!--           <div class="main-carousel__slide-subtitle">со скидкой <span>30%</span></div>-->
           <div v-if="item.link_url">
             <router-link :to="item.link_url" class="main-carousel__slide-button">
               {{ item.link_text }}
             </router-link>
           </div>
         </div>
       </div>
     </swiper-slide>
     <div
         v-show="items.length > 1"
         class="main-carousel__prev"
         slot="button-prev"
     ></div>
     <div
         v-show="items.length > 1"
         class="main-carousel__next"
         slot="button-next"
     ></div>
   </swiper>
 </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "MainCarousel",
    data() {
        return {
            swiperOption: {
                slidesPerView: 'auto',
                centeredSlides: true,
                centered: true,
                autoHeight: true,
                loop: true,
                spaceBetween: 30,
                slideActiveClass: 'main-carousel__slide-active',
                speed: 400,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.main-carousel__next',
                    prevEl: '.main-carousel__prev'
                },
                breakpoints: {
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                        centered: true
                    },
                    768: {
                        slidesPerView: 'auto',
                        spaceBetween: 20
                    },
                },
            },
            windowWidth: 1,

        }
    },
    computed: {
        ...mapState({
            items: state => state.carousel.carouselItems,
        }),
        carouselImage() {
            const { windowWidth: width  } = this;
            if (width < 776) return 'mobile_image';
            else if (width < 1024) return 'tablet_image';
            return 'image';
        }
    },
    created() {
      this.$store.dispatch('carousel/getCarouselItems').then(() => {
        if (this.items && this.items.length === 1) {
          this.swiperOption = {...this.swiperOption, ...{loop: false}};
        }
      });
      window.addEventListener('resize', this.setWindow);
      this.setWindow();
    },
    updated() {
        window.removeEventListener('resize', this.setWindow);
    },
    destroyed() {
        window.removeEventListener('resize', this.setWindow);
    },
    methods: {
        setWindow() {
            this.windowWidth = window.innerWidth;
        }
    },
}
</script>

<style scoped lang="scss">
  .swiper-slide {
    width: 70%;
  }
  @media screen and (max-width: 767px) {
    .swiper-slide {
      width: 100%;
      margin-left: 10px;
    }
  }
</style>
