<template>
  <b-col v-if="!vacancyMode" cols="12">
    <div class="q-card about-us__card">
      <b-row>
        <b-col cols="12" lg="6">
          <div class="about-us__paragraph">
            {{ $t('about_Vacancies_aboutUs_p1') }}
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div class="about-us__paragraph about-us__subtitle">
            {{ $t('about_Vacancies_aboutUs_p2') }}
          </div>
        </b-col>
      </b-row>
      <carousel />
      <div class="about-us__vacancies-title">{{ $t('about_Vacancies_title') }}</div>
      <b-row cols="12" cols-md="3">
        <b-col
          v-for="(vacancy, i) in vacancies"
          :key="`about-us-vacancy-card__${i}`"
          class="about-us__vacancies"
        >
          <div class="about-us__vacancy">
            <div class="about-us__vacancy-title">
              {{ vacancy.caption }}
            </div>
            <div class="about-us__vacancy-city">
              {{ vacancy.city }}
            </div>
            <div class="about-us__vacancy-btn">
              <button class="q-btn" @click="getVacancy(vacancy.id)">{{ $t('about_Vacancies_btn2open') }}</button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-col>
  <b-col v-else cols="12">
    <div class="q-card about-us__card">
      <b-row>
        <b-col cols="12">
          <div class="about-us__back" @click="vacancyMode = false">
            {{ $t('about_Vacancies_btn2back') }}
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="about-us__title">
            {{ vacancy.caption }}
          </div>
          <div class="about-us__vacancy-text" v-html="vacancy.description"></div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="about-us__vacancy-form">
            <feedback-form vacancy />
          </div>
        </b-col>
      </b-row>
    </div>
  </b-col>
</template>

<script>
import Carousel from "./AboutCarousel.vue";
import FeedbackForm from "@/components/Forms/FeedbackForm.vue";

export default {
    name: "Vacancies",
    components: {
        Carousel,
        FeedbackForm,
    },
    data() {
        return {
            vacancies: [],
            vacancy: {},
            vacancyMode: false,
        }
    },

    metaInfo: {
      title: 'Вакансии | Доставка кондитерских изделий в Алмате',
      meta: [
        { vmid: 'description', property: 'description', content: 'Qulpynai Вакансии' },
        { vmid: 'og:title', property: 'og:title', content: 'Vacancy Qulpynai' },
        { vmid: 'og:description', property: 'og:description', content: 'Qulpynai Вакансии' },
      ],
    },

    created() {
        this.getVacancies();
    },
    methods: {
        async getVacancies() {
            try {
                const { data } = await this.$axios.get('/vacancy');
                this.vacancies = data.vacancies;
            } catch (e) {
                console.error(e);
            }
        },
        async getVacancy(id) {
            this.vacancyMode = true;
            try {
                const { data } = await this.$axios.get(`/vacancy/${id}`);
                this.vacancy = data;
            } catch (e) {
                console.error(e);
            }
        },
        openVacancy() {},
    },
}
</script>
