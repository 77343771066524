import axios from "axios";

const state = {
    token: localStorage.getItem('token'),
    loggedIn: false,
    user: null,
};

const actions = {
    login({ state, commit, dispatch }, context) {
        const { user, token } = state;

        if (token) user.token = token;
        return axios
            .post('auth/login', context)
            .then(response => {
                if (response && response.data) {
                    commit('SET_TOKEN', response.data.access_token);
                    commit('SET_USER', response.data.user);
                }
                return response;
            });
    },
    recovery(context, user) {
        return axios
            .post('auth/recovery', user)
    },
    register(context, user) {
        const unauthorizedToken = context.rootGetters["cart/getUnauthorizedToken"];
       /* if (unauthorizedToken) {
            user.token = unauthorizedToken;
        }*/
        return axios
            .post('auth/send_confirmation', user)
            .then(response => {
                if (response.data.access_token){
                    context.commit('SET_TOKEN', response.data.access_token)
                    context.commit('SET_USER', response.data.user)
                }

                return response;
            });
    },
    fetchUser({state, commit, dispatch}) {
        if (!state.token) {
            return;
        }
        return axios
            .get('auth/user')
            .then(response => {
                commit('SET_USER', response.data);
            });
    },
    refresh({ state, commit }) {
        return axios
            .post('auth/refresh')
            .then(response => {
                commit('SET_TOKEN', response.data.access_token);
                if (!state.user || !state.user.id) {
                    commit('SET_USER', response.data.user)
                }
            })
            .catch(() => {
                commit('CLEAR_USER')
            });
    },
    logout({commit}) {
        axios.post('auth/logout')
            .finally(() => {
                commit('CLEAR_USER');
            });
    },
    sendActivationCode({ dispatch }, code) {
        return axios.post('auth/verify', { code })
            .then(() => {
                dispatch('fetchUser');
            })
    },
    getActivationCode() {
        return axios.post('auth/send_confirmation');
    },
    resetPassword(context, reset) {
        return axios.post('auth/reset', reset)
    },
    loginByCode(context, user) {
        const unauthorizedToken = context.rootGetters["cart/getUnauthorizedToken"];
        if (unauthorizedToken) {
            user.token = unauthorizedToken;
        }
        return axios
            .post('auth/code/login', user)
            .then(response => {
                context.commit('SET_TOKEN', response.data.access_token)
                context.commit('SET_USER', response.data.user)
            });
    },
    changePassword(context, password) {
        return axios.post('auth/password/change', password)
    },
    updateUser(context, data) {
        return axios.post('auth/update', data)
    },
};
const mutations = {
    SET_USER(state, user) {
        state.loggedIn = true;
        state.user = user;
    },
    SET_TOKEN(state, token) {
        localStorage.setItem('token', token);
        state.token = token;
    },
    CLEAR_USER(state) {
        state.loggedIn = false;
        state.user = null;
        state.token = null;
        localStorage.removeItem('token');
    },
};
const getters = {
    loggedIn() {
        return state.user && state.user.id;
    },
    user() {
        return state.user;
    },
    userVerified() {
        return state.user && state.user.verified;
    },
    phone() {
        return state.user ? state.user.phone : null;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
