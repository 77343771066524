<template>
  <div>
    <b-dropdown
      id="dropdown-form"
      ref="cartDropdown"
      no-caret
      toggle-class="q-btn q-btn-red main-cart__btn"
      toggle-tag="div"
      variant="link"
      class="main-cart"
      :class="{'main-cart__active': dropdownActive}"
      right
      no-flip
      @shown="dropdownActive = true"
      @hidden="dropdownActive = false"
    >
      <template #button-content>
        <div v-if="!isMobile">{{ $t('MainNav_Cart_name') }}</div>
        <span>{{ quantity }}</span>
      </template>
      <cart-preloader :loading="loading" />
      <div v-show="!loading">
        <div
          v-if="products.length"
          :class="{'q-scrollbar': products.length && products.length > 3}"
          class="main-cart__items"
        >
          <cart-item
            v-for="(product, i) in products"
            :key="`nav-cart-product__${i}`"
            :product="product"
          />
        </div>
        <div v-if="products.length" class="main-cart__bottom">
          <div class="main-cart__bottom-sum">
            <div>{{ $t('MainNav_Cart_order_price') }}</div>
            <span>{{ numberWithSpaces(amount) }} ₸</span>
          </div>
          <button
            class="q-btn q-btn-teal main-cart__bottom-button"
            @click="goToCart"
          >
            {{ $t('MainNav_Cart_btn2cart') }}
          </button>

          <b-row class="justify-content-center m-0">
            <div
              class="main-cart__bottom-clear"
              @click="clearCart"
            >
              {{ $t('MainNav_Cart_clean2cart') }}
            </div>
          </b-row>
        </div>
        <div v-else class="cart__no-data">
          <img src="@/assets/icons/cart.png" alt="cart-icon">
          <p>{{ $t('MainNav_Cart_empty_cart') }}</p>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import CartItem from "../Cart/CartItem.vue";
import CartPreloader from "../Cart/CartPreloader.vue";
import { mapState } from "vuex";

export default {
    name: "MainNavCart",
    props: {
        isMobile: Boolean,
    },
    components: {
        CartItem,
        CartPreloader,
    },

    data() {
        return {
            dropdownActive: false,
        }
    },

    computed: {
        ...mapState({
            amount: state => state.cart.amount,
            products: state => state.cart.products,
            loading: state => state.cart.loading,
            quantity: state => state.cart.productsQuantity,
        }),
    },

    methods: {
        goToCart() {
            if (this.$route.path === '/cart') return;
            this.$refs.cartDropdown.hide();
            this.$router.push('/cart');
        },
        clearCart() {
          this.$store.dispatch('cart/removeCart');
        },
    },
}
</script>

<style scoped>

</style>
