<template>
  <div>
    <form
      v-if="!success"
      class="feedback-form"
      @submit.prevent="submit"
    >
      <h3 class="feedback-form__title">
        {{ $t('Forms_FeedBackForm_write2our') }}
      </h3>
      <form-group
        v-for="(field, i) in fields"
        v-model="form[field.name]"
        :key="`feedback-input__${i}`"
        :label="$t(field.label)"
        :error="(errors[field.name] || [])[0]"
        :type="field.type"
        :placeholder="$t(field.placeholder)"
      />

      <div class="feedback-form__submit">
        <button class="q-btn q-btn-teal">{{ $t('Forms_FeedBackForm_btn2send') }}</button>
      </div>
    </form>
    <div v-show="success">
      <div>
        {{ $t('Forms_FeedBackForm_success2message') }}
      </div>
      <button class="q-btn mt-2" @click="sendAgain">{{ $t('Forms_FeedBackForm_btn2send_again') }}</button>
    </div>
  </div>
</template>

<script>
import FormGroup from "../FormGroup/FormGroup.vue";

export default {
    name: "FeedbackForm",
    props: {
        type: String,
        vacancy: Boolean,
    },
    components: {
        FormGroup,
    },
    data() {
        return {
            success: false,
            form: {},
            errors: {},
            file: null,
            fields: [
                {
                    name: 'name',
                    label: 'Forms_FeedBackForm_label_name',
                    placeholder: 'Forms_FeedBackForm_placeholder_name',
                },
                {
                    name: 'phone',
                    type: 'phone',
                    label: 'Forms_FeedBackForm_label_phone',
                    placeholder: '',
                },
                {
                    name: 'email',
                    label: 'Email',
                    placeholder: 'Forms_FeedBackForm_placeholder_email',
                },
                {
                    name: 'message',
                    label: 'Forms_FeedBackForm_label_question',
                    placeholder: 'Forms_FeedBackForm_placeholder_question',
                },
            ],
        }
    },
    methods: {
        async submit() {
            const { form, type, vacancy } = this;
            form.type = type;
            try {
                await this.$axios.post( vacancy ? '/vacancy/resume': '/feedback', form);
                this.success = true;
            } catch (error) {
                this.errors = ((error.response || {}).data || {}).errors || {};
            }
        },
        sendAgain() {
            this.form = {};
            this.errors = {};
            this.success = false;
        }
    },
}
</script>
