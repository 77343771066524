<template>
  <b-modal
    ref="cartModal"
    id="cartModal"
    size="md"
    hide-header
    hide-footer
    body-class="custom-modal"
  >
    <div class="custom-modal__close-button" @click="hideModal"></div>
    <b-row>
      <b-col cols="12">
        <div class="cabinet-modal">
          <div class="cabinet-modal__head">
            {{ $t('Cart_CartModal_any_error') }}
          </div>
          <div class="cabinet-modal__body">
            <div class="q-issue">
              {{ $t('Cart_CartModal_refresh_token') }}
            </div>
            <div class="cabinet-modal__footer">
              <button
                class="q-btn q-btn-teal cabinet-modal__button"
                @click="reload"
              >
                {{ $t('Cart_CartModal_refresh_btn') }}
              </button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

export default {
    name: "CartModal",
    methods: {
        reload() {
            document.location.reload();
        },
        hideModal() {
            this.$bvModal.hide('cartModal');
        },
    },
}
</script>

<style scoped>

</style>
