<template>
  <div
    id="cart-step-4"
    :class="{ 'cart-step-inactive': cantOrder }"
    class="q-card cart-step"
  >
    <div class="cart-step__title">
      {{ $t("Cart_Step4_index_header") }}
    </div>
    <div v-if="!cantOrder">
      <b-row v-if="isDelivery">
        <b-col cols="12" md="5" xl="4">
          <div class="cart-tabs">
            <div
              :class="{ 'cart-tab-active': !forFriend }"
              class="cart-tab"
              @click="setForFriend(false)"
            >
              {{ $t("Cart_Step4_index_tab_for_me") }}
            </div>
            <div
              :class="{ 'cart-tab-active': forFriend }"
              class="cart-tab"
              @click="setForFriend(true)"
            >
              {{ $t("Cart_Step4_index_tab_for_friend") }}
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="cart-step4">
        <b-col
          v-for="(payment, i) in paymentTypes"
          :key="`cart-step4-payment__${i}`"
          cols="12"
          md="5"
          xl="4"
        >
          <b-container v-if="payment.type == 'kpay'">
            <b-row
              :class="{ 'active': selectedPaymentType.id === payment.id }"
              class="cart-payment__kaspi"
              @click="selectPaymentType(payment)"
            >
              <b-col>
                <img src="@/assets/images/payments/kaspi.svg" alt="Kaspi.kz" />
                <span>{{ $t('Cart_Step4_index_choose_kaspi_payment') }}</span>
              </b-col>
            </b-row>
          </b-container>

          <div
            v-else
            :class="{
              'cart-step__tab-active': selectedPaymentType.id === payment.id,
              'cart-step__tab-inactive': false,
            }"
            class="cart-step__tab"
            style="align-items: center; justify-content: center"
            @click="selectPaymentType(payment)"
          >
            <div class="cart-step__tab-title">
              {{ payment.name }}
            </div>
          </div>
        </b-col>
      </b-row>

      <self
        @createOrder="createOrder"
        v-show="!forFriend"
        :payment-type="selectedPaymentType"
      />

      <friend v-show="forFriend" @createOrder="createOrder" />
      <warningModal
        :filial_name="activeFilial"
        :last_time_interval="end_interval"
        :fisrt_time_interval="first_interval"
      />
      <WaitOrderAnswerModal />
      <TimeConfirmModal @createOrder="createOrder" />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Self from "./Self.vue";
import Friend from "./Friend.vue";
import warningModal from "@/components/Modals/warningModal.vue";
import WaitOrderAnswerModal from "@/components/Cart/Step4/WaitOrderAnswerModal.vue";
import TimeConfirmModal from "@/components/Cart/Step4/TimeConfirmModal.vue";
import { mapState } from "vuex";

export default {
  name: "Step4",
  components: {
    Self,
    Friend,
    warningModal,
    WaitOrderAnswerModal,
    TimeConfirmModal,
  },
  data() {
    return {
      cashPayment: false,
      isTimeValid: false,
      start_interval: "",
      end_interval: "",
      first_interval: "",
      addressFlat: "",
    };
  },

  computed: {
    ...mapState({
      selectedPaymentType: (state) => state.paymentTypes.selectedPaymentType,
      isDelivery: (state) => state.cart.isDelivery,
      hours: (state) => state.cart.pickupTimes,
      activeFilial: (state) => state.filial.activeFilial,
      deliveryDates: (state) => state.cart.deliveryDates,
      selectedDeliveryDate: (state) => state.cart.selectedDeliveryDate,
      pickupDates: (state) => state.cart.pickupDates,
      selectedPickupDate: (state) => state.cart.selectedPickupDate,
    }),
    paymentTypes() {
      const types = this.$store.state.paymentTypes.paymentTypes || [];
      return types.filter((item) => this.checkPrepayment(item));
    },
    forFriend: {
      get() {
        return this.$store.state.order.isForFriend;
      },
      set(isForFriend) {
        this.$store.commit("order/SET_FOR_FRIEND", isForFriend);
      },
    },
    cantOrder() {
      return (
        !this.$store.getters["stopList/canPickup"] &&
        !this.$store.getters["stopList/canDelivery"]
      );
    },
  },

  async created() {
    await this.$store.dispatch("paymentTypes/getPaymentTypes");
    this.selectPaymentType(this.paymentTypes[0]);
    //this.$bvModal.show("TimeConfirmModal");
  },
  // отключил чтобы при переключении между доставкой и самовывозом, метод оплаты не скидывался на первый элемент
  // watch: {
  //   isDelivery() {
  //     this.selectPaymentType(this.paymentTypes[0]);
  //   },
  // },
  methods: {
    setForFriend(value) {
      this.forFriend = value;
      this.selectPaymentType(this.paymentTypes[0]);
    },
    selectPaymentType(paymentType) {
      this.$store.commit("paymentTypes/SET_SELECTED_PAYMENT_TYPE", paymentType);
    },
    checkPrepayment(paymentType) {
      return (
        (paymentType.delivery && this.isDelivery) ||
        (paymentType.pickup && !this.isDelivery)
      );
    },
    isCorrectTimeInterval() {
      const intervalParts = this.hours[this.hours.length - 1].split(" - ");
      const startIntervalParts = this.hours[0].split(" - ");
      this.first_interval = startIntervalParts[0];
      this.start_interval = intervalParts[0];
      this.end_interval = intervalParts[1];

      const currentDate = new Date();
      const startDate = new Date(
        currentDate.toDateString() + " " + this.start_interval
      );
      const endDate = new Date(
        currentDate.toDateString() + " " + this.end_interval
      );

      // Сравниваем текущее время с временем начала и конца интервала
      return currentDate >= startDate && currentDate <= endDate;
    },
    createOrder(skipTimeConfirmationModal = false) {
      this.$store.commit("order/SET_ERRORS", {});

      if (!skipTimeConfirmationModal) {
        //const today = dayjs().format("DD.MM.YYYY");
        const tomorrow = dayjs().add(1, "day").format("DD.MM.YYYY");

        if (
          (this.isDelivery &&
            //this.deliveryDates[0].value == today &&
            this.selectedDeliveryDate == tomorrow) ||
          (!this.isDelivery &&
            //this.pickupDates[0].value == today &&
            this.selectedPickupDate == tomorrow)
        ) {
          this.$bvModal.show("TimeConfirmModal");
          return;
        }
      }

      this.$bvModal.hide("warningModal");
      const scrollOptions = {
        offset: -70,
      };
      const notifyOptions = {
        group: "foo",
        title: this.$t("Cart_Step4_error"),
        text: "",
        type: "error",
      };
      //
      // console.log("addressFlat.value " + !addressFlat.value)
      //
      // if(!addressFlat.value) {
      //   // toast.error(t('address_must_flat'));
      //   // const element = document.getElementById('cart-step-2');
      //   // element?.scrollIntoView(true);
      //   // return address_must_flat
      //   notifyOptions.text = this.$t('address_must_flat');
      //   this.$scrollTo("#cart-step-2", 1000, scrollOptions);
      //   this.$notify(notifyOptions);
      //   return;
      // }

      // if (response.status !== 401 || response.status !== 422) {
      //   this.$scrollTo("#cart-step-2", 1000, scrollOptions);
      // }


      this.$bvModal.show("WaitOrderAnswerModal");
      this.$store.commit("order/SET_LOADING", true);
      this.$store
        .dispatch("order/createOrder")
        .then((response) => {
          if (response.data.order_id !== undefined) {
            this.$store.commit("cart/RESET_STATE");
            this.$store.commit("order/RESET_STATE");
            this.$store.commit("paymentTypes/RESET_STATE");
            this.$store.commit("stopList/SET_STOP_LIST", {});
            this.$router.push({
              name: "Order",
              params: { orderId: response.data.order_id },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          const response = error.response || {};
          this.$store.commit("order/SET_ERRORS", response.data.errors || {});
          if (response.status === 401) {
            this.$scrollTo("#cart-step-1", 1000, scrollOptions);
            notifyOptions.text = this.$t("Cart_Step4_index_write_contacts");
            this.$notify(notifyOptions);
            return;
          }
          if (response.status === 403) {
            this.$scrollTo("#cart-step-1", 1000, scrollOptions);
            notifyOptions.text = this.$t(
              "Cart_Step4_index_write_activiation_sms"
            );
            this.$notify(notifyOptions);
            return;
          }
          if (response.status === 422) {
            const errors = response.data.errors || {};
            if (response.data.message) {
              notifyOptions.text = this.$t(response.data.message);
              this.$scrollTo("#cart-step-2", 1000, scrollOptions);
              this.$notify(notifyOptions);
              return;
            }
            if ("address.house" in errors) {
              notifyOptions.text = this.$t("Cart_Step4_index_write_address");
              this.$scrollTo("#cart-step-2", 1000, scrollOptions);
              this.$notify(notifyOptions);
              return;
            }
            if ("address.street" in errors) {
              notifyOptions.text = this.$t("Cart_Step4_index_write_address");
              this.$scrollTo("#cart-step-2", 1000, scrollOptions);
              this.$notify(notifyOptions);
              return;
            }
            if ("address.flat" in errors) {
              notifyOptions.text = this.$t("address_must_flat");
              this.$scrollTo("#cart-step-2", 1000, scrollOptions);
              this.$notify(notifyOptions);
              return;
            }
            if ("shop_id" in errors) {
              notifyOptions.text = this.$t("Cart_Step4_index_choose_shop");
              this.$scrollTo("#cart-step-2", 1000, scrollOptions);
              this.$notify(notifyOptions);
              return;
            }
            if ("friend.name" in errors || "friend.phone" in errors) {
              notifyOptions.text = this.$t(
                "Cart_Step4_index_write_name_friend"
              );
              this.$scrollTo("#cart-step-4", 300, scrollOptions);
              this.$notify(notifyOptions);
              return;
            }
            if (errors.next_day !== undefined) {
              notifyOptions.text = this.$t(
                "CatalogProduct_label_have_next_day"
              );
              this.$scrollTo("#cart-step-3", 1000, scrollOptions);
              this.$notify(notifyOptions);
              return;
            }
            if ("cart_id" in errors) {
              this.$bvModal.show("cartModal");
            }
          }
          if (response.status === 400) {
            if (
              response.data.message["error.products_out_of_stock"] !== undefined
            ) {
              notifyOptions.text = this.$t("Cart_Step4_index_out_of_stock");
              this.$scrollTo("#cart-step-2", 1000, scrollOptions);
              this.$notify(notifyOptions);
            }
          }
        })
        .finally(() => {
          this.$bvModal.hide("WaitOrderAnswerModal");
          setTimeout(() => {
            this.$store.commit("order/SET_LOADING", false);
          }, 3000);
        });
    },
  },
};
</script>
