<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <div class="q-preloader q-preloader__cart-item">
        <b-skeleton type="avatar" height="60px" width="60px"></b-skeleton>
        <div class="q-preloader__cart-side">
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
        </div>
      </div>
      <div class="q-preloader q-preloader__cart-item">
        <b-skeleton type="avatar" height="60px" width="60px"></b-skeleton>
        <div class="q-preloader__cart-side">
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
        </div>
      </div>
      <div class="q-preloader q-preloader__cart-item">
        <b-skeleton type="avatar" height="60px" width="60px"></b-skeleton>
        <div class="q-preloader__cart-side">
          <b-skeleton width="100%"></b-skeleton>
          <b-skeleton width="100%"></b-skeleton>
        </div>
        <b-skeleton width="90%" class="mt-4"></b-skeleton>
        <b-skeleton width="90%" type="button" class="mt-2"></b-skeleton>
      </div>
    </template>
  </b-skeleton-wrapper>
</template>

<script>
export default {
    name: "CartPreloader",
    props: {
        loading: Boolean,
    },
}
</script>
