<template>
  <b-container class="cart">
    <b-row>
      <b-col class="cart-header">
        {{ $t('Cart_title') }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="9">
        <b-row>
          <b-col v-if="products && products.length">
            <step1/>
            <step2/>
            <step3/>
            <step4 ref="step4"/>
          </b-col>
          <b-col v-else>
            <div class="q-card cart-step">
              <b-row>
                <b-col cols="12" class="justify-content-center">
                  <div class="cart__no-data">
                    <img src="@/assets/icons/cart.png" alt="cart-icon">
                    <p>{{ $t('Cart_empty_cart') }}</p>
                    <router-link to="/" class="q-btn q-btn-teal">{{ $t('Cart_btn2back') }}</router-link>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <side
          v-if="products && products.length"
          @createOrder="createOrder"
      />
    </b-row>
    <cart-modal />
  </b-container>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";
import Step1 from "@/components/Cart/Step1.vue";
import Step2 from "@/components/Cart/Step2";
import Step3 from "@/components/Cart/Step3.vue";
import Step4 from "@/components/Cart/Step4/index";
import Side from "@/components/Cart/Side.vue";
import CartModal from "@/components/Cart/CartModal";
import {mapState} from "vuex";

export default {
  name: "Cart",
  components: {
    FormGroup,
    Step1,
    Step2,
    Step3,
    Step4,
    Side,
    CartModal,
  },
  metaInfo: {
    title: 'Оформление заказа | Доставка кондитерских изделий в Алмате',
    meta: [
      {vmid: 'description', property: 'description', content: 'Qulpynai кабинет'},
      {vmid: 'og:title', property: 'og:title', content: 'Cabinet Qulpynai'},
      {vmid: 'og:description', property: 'og:description', content: 'Qulpynai кабинет'},
    ],
  },
  mounted() {
    this.$store.dispatch('stopList/getCartStopList');
  },
  computed: {
    ...mapState({
      products: state => state.cart.products,
    }),
  },
  methods: {
    createOrder() {
      this.$refs.step4.createOrder();
    }
  },
}
</script>
