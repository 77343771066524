<template>
  <div id="cart-step-1" class="q-card cart-step">
    <div class="cart-step__title">
      {{ $t('Cart_Step1_header') }}
    </div>
    <b-row v-if="showCheck && !showCode">
      <b-col
          v-for="(field, i) in checkFields"
          :key="`cart-step1-field__${i}`"
          cols="12"
          md="4"
      >
        <form-group
            v-model="form[field.name]"
            :error="errors[field.name]"
            :type="field.type"
            :label="$t(field.label)"
            :placeholder="$t(field.placeholder)"
        />
      </b-col>
      <b-col cols="12">
        <button class="q-btn q-btn-teal" @click="signUp">
          {{ $t('Cart_Step1_btn2accept') }}
        </button>
      </b-col>
    </b-row>
    <b-row v-if="currentUser">
      <b-col cols="12" md="5" xl="4" class="mb-5">
        <div class="cart-step__card">
          <div class="cart-step__card-title">{{ currentUser.name }}</div>
          <div
              v-if="currentUser.birthdate"
              class="cart-step__card-date"
          >
            {{ currentUser.birthdate }}
          </div>
          <div class="cart-step__card-phone">+{{ currentUser.phone }}</div>
          <div v-if="currentUser.verified" class="cart-step__card-approved">{{ $t('Cart_Step1_number2accept') }}</div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="showCode && !currentUser">
      <b-col cols="12" md="9" class="mb-2 mt-4">
        <div class="cart-step__title">{{ $t('Cart_Step1_check2number') }}</div>
        <phone-approve
            :phone="phone"
            :error="errors.code"
            align-content="left"
            @sendCode="verify"
            @resendCode="resendCode"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";
import PhoneApprove from "@/components/Auth/PhoneApproveForm.vue"

export default {
  name: "Step1",
  components: {
    FormGroup,
    PhoneApprove,
  },
  computed: {
    showCheck() {
      return !this.currentUser
    },
    showCode() {
      return (this.currentUser && !this.currentUser.verified) || this.loginCode
    },
    phone() {
      return this.currentUser ? this.currentUser.phone : this.form.phone
    }
  },
  data() {
    return {
      loading: false,
      check: false,
      loginCode: false,
      needRegister: false,
      code: '',
      form: {},
      errors: {},
      checkFields: [
        {
          name: 'phone',
          type: 'phone',
          label: 'Cart_Step1_label_number',
          placeholder: '',
        },
        {
          name: 'name',
          label: 'Cart_Step1_label_name',
          placeholder: 'Cart_Step1_placeholder_name',
        },
      ],

    }
  },
  methods: {
    signUp() {
      let {form} = this;
      form.is_registration = true;
      form.phone = (form.phone || '').replace(/\D+/g, "");
      this.loading = true;
      this.$store.dispatch("auth/register", form)
          .then(response => {
            this.loginCode = true
            this.errors = {};
          })
          .catch(error => {
            this.needRegister = true
            if (this.check) {
              this.errors = ((error.response || {}).data || {}).errors || {}
            }else {
              this.checkFields.push(...this.registerFields)
            }
          })
          .finally(() => {
            this.loading = false;
            this.check = true;
          })
    },
    verify(code) {
      if (this.currentUser) {
        this.$store.dispatch('auth/sendActivationCode', code)
            .then(response => {
              this.loginCode = false
            })
            .catch(() => {
              this.$set(this.errors, 'code', true)
              this.errors.code = true;
            })
            .finally(() => {
              this.loading = false;
            })
      }else {
        let user = {
          code: code,
          phone: (this.form.phone || '').replace(/\D+/g, ""),
        }
        this.$store.dispatch('auth/loginByCode', user)
            .then(() => {
              this.loginCode = false
            })
            .catch(error => {
              this.$set(this.errors, 'code', true)
              this.errors.code = true;
            })
            .finally(() => {
              this.loading = false;
            })
      }
    },
    async resendCode() {
      if (this.currentUser) {
          try {
          await this.$store.dispatch('auth/getActivationCode');
        } catch (e) {
          console.error(e);
        }
      }else {
        this.signUp()
      }
    },

    setCode(code) {
      this.code = code;
    },
  },
}
</script>
