import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);

import Cart from "@/views/Cart";
import Order from "@/views/Order";
import Cabinet from "@/views/Cabinet";
import Blog from "@/views/Blog";
import BlogPost from "@/components/Blog/BlogPost";
import Promotions from "@/views/Promotions";
import PromoPost from "@/components/Promo/PromoPost";
import NotFound from "@/views/NotFound";
import Contacts from "@/views/Contacts";
import Help from "@/views/Help";
import About from "@/views/About";
import AboutUs from "@/components/About/We";
import AboutVacancy from "@/components/About/Vacancies";
import AboutPartners from "@/components/About/Partners";
import Bonuses from "@/views/Bonuses";
import Whatsapp from "@/views/Whatsapp";
import Legacy from "@/views/Legacy";
import ProductItem from "@/views/ProductItem";
import Policy from '@/views/Policy.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart,
    },
    {
        path: '/order/:orderId',
        name: 'Order',
        component: Order,
    },
    {
        path: '/cabinet',
        name: 'Cabinet',
        component: Cabinet,
        meta: {
            auth: true,
        }
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog,
    },
    {
        path: '/blog/:id',
        name: 'BlogPost',
        component: BlogPost,
    },
    {
        path: '/promo',
        name: 'Promotions',
        component: Promotions,
    },
    {
        path: '/promo/:id',
        name: 'PromoPost',
        component: PromoPost,
    },
    {
        path: '*',
        name: 'Not Found',
        component: NotFound,
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts,
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        children: [
            {
                path: '/',
                component: AboutUs,
            },
            {
                path: 'vacancies',
                component: AboutVacancy,
            },
            {
                path: 'partners',
                component: AboutPartners,
            },
        ],
    },
    {
        path: '/bonuses',
        name: 'Bonuses',
        component: Bonuses,
    },
    {
        path: '/policy',
        name: 'Policy',
        component: Policy,
    },
    {
        path: '/whatsapp',
        name: 'Whatsapp',
        component: Whatsapp,
    },
    {
        path: '/legacy',
        name: 'Legacy',
        component: Legacy,
    },
    {
        path: '/product/:id',
        name: 'ProductItem',
        component: ProductItem,
    },
];

const router = new VueRouter({
  mode: 'history',
  routes,
   scrollBehavior(to, from, savedPosition) {
       if(to.path !== from.path){
            return { x: 0, y: 0 };
        }
    },
});
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.auth)) {
        if (localStorage.getItem('token') == null) {
            next({
                path: '/',
                params: { nextUrl: to.fullPath }
            })
        }
    }
    next()
})

export default router
