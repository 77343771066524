<template>
  <div class="product-modal__stock">
    <div class="product-modal__stock-title">
      {{ $t("Modals_Product_Stock_have_shops") }}
      <span>{{ product.shops }} </span>
    </div>
    <div
      v-if="product.missing_several && !product.missing_all"
      class="cart-step__pickup-status text-warning red"
    >
      {{ $t("Modals_Product_Stock_yellow") }}
    </div>
    <div v-if="product.missing_all" class="cart-step__pickup-status   yellow">
      {{ $t("Modals_Product_Stock_red") }}
    </div>
    <div class="q-scrollbar product-modal__stock-items mt-4">
      <div
        v-for="(shop, i) in stopList"
        :key="`shop-${i}`"
        class="product-modal__stock-item"
      >
        <div
          class="product-modal__stock-item-address"
          :class="[setColorByAmount(shop.amount)]"
        >
          {{ shop.name }}
        </div>
        <span class="product-modal__stock-item-status__text">{{$t(setTextByAmount(shop.amount))}}</span>
        <!--        <div class="product-modal__stock-item-count">
          {{ shop.amount }}

        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProductModalStock",
  computed: {
    ...mapState({
      stopList: (state) => state.products.modalProduct.stop_list,
      product: (state) => state.products.modalProduct,
    }),
  },
  methods: {
    setColorByAmount(value) {
      if (value < 1) {
        return "text-danger";
      } else if (value > 2 && value < 9) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
    setTextByAmount(value){
      if (value < 1) {
        return "little_bit_products"
      } else if (value > 2 && value < 9) {
        return "little_left";
      } else {
        return "a_lot_of";
      }
    }
  },
};
</script>
<style>
.text-danger {
  color: red;
}
.text-warning {
  color: yellow;
}
.text-primary {
  color: blue;
}
.product-modal__stock-item-status__text{
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.05em;
}
</style>
