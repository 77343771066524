<template>
  <b-modal
    ref="cabinetBdayModal"
    id="cabinetBdayModal"
    size="md"
    hide-header
    hide-footer
    body-class="custom-modal"
  >
    <div class="custom-modal__close-button" @click="hideModal"></div>
    <b-row>
      <b-col cols="12">
        <div class="cabinet-modal">
          <div class="cabinet-modal__head">
            {{ $t('Cabinet_CabinetBday_changeProfile') }}
          </div>
          <div class="cabinet-modal__body">
            <div class="cabinet-modal__form">
              <form-group
                v-for="(field, i) in fields"
                v-model="form[field.name]"
                :key="`update-info-modal-field__${i}`"
                :error="errors[field.name] && errors[field.name][0]"
                :label="$t(field.label)"
                :placeholder="$t(field.placeholder)"
                :type="field.type"
              />
            </div>
            <div class="cabinet-modal__footer">
              <button
                class="q-btn q-btn-teal cabinet-modal__button"
                @click="submit"
              >
                {{ $t('Cabinet_CabinetBday_btn2save') }}
              </button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
    import FormGroup from "@/components/FormGroup/FormGroup.vue";
    export default {
        name: "CabinetBdayModal",
        components: {
            FormGroup,
        },
        data() {
            return {
                form: {},
                errors: {},
                fields: [
                    {
                        name: 'birthdate',
                        type: 'date',
                        label: 'Cabinet_CabinetBday_label_01',
                        placeholder: 'Cabinet_CabinetBday_placeholder_01',
                    },
                ],
            }
        },

        methods: {
            hideModal() {
                this.form = {};
                this.errors = {};
                this.$bvModal.hide('cabinetBdayModal');
            },
            submit() {
                this.$store.dispatch('auth/updateUser', this.form)
                    .then(response => {
                      this.$store.commit('auth/SET_USER', response.data);
                      this.hideModal();
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 422) {
                            this.errors = error.response.data.errors;
                        }
                    })
            },
        },
    }
</script>

<style scoped>

</style>
