<template>
  <div v-if="history && history.length" class="q-card cabinet-card">
    <div class="cabinet-card__head">
      <div class="cabinet-card__title">
        {{ $t("Cabinet_CabinetOrders_my_orders") }}
      </div>
      <div class="cabinet-orders__arrows">
        <div class="cabinet-orders__arrows-left"></div>
        <div class="cabinet-orders__arrows-right"></div>
      </div>
    </div>
    <swiper class="swiper popular-carousel" :options="swiperOption">
      <swiper-slide v-for="(item, i) in history" :key="`order-item__${i}`">
        <div class="cabinet-orders__item">
          <div class="cabinet-orders__card">
            <div class="cabinet-orders__card-number">
              {{ $t("Cabinet_CabinetOrders_number_order") }}
              <span class="q-bold">Q-{{ item.id }}</span>
            </div>
            <button
              v-if="item.status !== 'Error'"
              class="q-btn cabinet-orders__card-status"
              @click="setOrder(item.id)"
            >
              {{ $t("Cabinet_CabinetOrders_btn2check_status") }}
            </button>

            <div class="cabinet-orders__card-content">
              <div class="cabinet-orders__card-text q-bold">
                <span>{{ $t(statuses[item.status]) }}</span>
              </div>
              <div class="cabinet-orders__card-text">
                {{ $t("Cabinet_CabinetOrders_ordered") }} {{ item.date }}
              </div>
              <!--              <div class="cabinet-orders__card-text">Заказ на 11.01.2021</div>-->
              <!--              <div class="cabinet-orders__card-text">Доставим в течение 13:30 - 14:00</div>-->
              <div class="cabinet-orders__card-text q-bold">
                {{ $t("Cabinet_CabinetOrders_order_sum") }}
                {{ numberWithSpaces(item.amount) }} ₸
              </div>
            </div>
            <div class="cabinet-orders__card-toggle" @click="toggle(i)">
              {{
                toggled !== i
                  ? $t("Cabinet_CabinetOrders_open2list")
                  : $t("Cabinet_CabinetOrders_hide2list")
              }}
            </div>
            <div v-show="toggled === i" class="cabinet-orders__card-list">
              <div
                v-for="(product, i) in item.products"
                class="cabinet-orders__card-list-item"
              >
                <div class="q-bold">{{ product.name }}</div>
                <!--                <div>Торты</div>-->
              </div>
              <!--              <div class="cabinet-orders__card-list-item">-->
              <!--                <div class="q-bold">Доставка по городу 500 тг.</div>-->
              <!--                <div>Услуги</div>-->
              <!--              </div>-->
            </div>
            <button
              v-if="item.payment_url && !item.payed_at"
              class="q-btn cabinet-orders__card-invoice"
            >
              <a :href="item.payment_url" class="cabinet-pay-button">{{
                $t("Cabinet_CabinetOrders_pay")
              }}</a>
            </button>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="cabinet-orders__items"></div>
  </div>
  <div v-else class="q-card cabinet-card">
    <div class="cabinet-card__title">
      {{ $t("Cabinet_CabinetOrders_last_orders") }}
    </div>
    <div class="cabinet-card__text">
      {{ $t("Cabinet_CabinetOrders_zaglushka") }}
    </div>
    <button class="q-btn q-btn-teal cabinet-card__button">
      {{ $t("Cabinet_CabinetOrders_btn2menu") }}
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CabinetOrders",
  data() {
    return {
      toggled: -1,
      swiperOption: {
        // slidesPerView: 4,
        centered: true,
        spaceBetween: 15,
        navigation: {
          nextEl: ".cabinet-orders__arrows-right",
          prevEl: ".cabinet-orders__arrows-left",
        },
        breakpoints: {
          1440: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          960: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
      },
      statuses: {
        New: "Cabinet_CabinetOrders_status_processed",
        Unconfirmed: "Cabinet_CabinetOrders_status_unconfirmed",
        WaitCooking: "Cabinet_CabinetOrders_status_waitcooking",
        ReadyForCooking: "Cabinet_CabinetOrders_status_readyforcooking",
        CookingCompleted: "Cabinet_CabinetOrders_status_cookingcompleted",
        Waiting: "Cabinet_CabinetOrders_status_waiting",
        OnWay: "Cabinet_CabinetOrders_status_onway",
        Delivered: "Cabinet_CabinetOrders_status_delivered",
        Closed: "Cabinet_CabinetOrders_status_closed",
        Cancelled: "Cabinet_CabinetOrders_status_cancelled",
        Error: "Cabinet_CabinetOrders_status_error",
      },
    };
  },

  computed: {
    ...mapState({
      history: (state) => state.order.history,
    }),
  },

  methods: {
    toggle(i) {
      if (i === this.toggled) {
        this.toggled = -1;
        return;
      }
      this.toggled = i;
    },
    orderError(status) {
      return status === "Error";
    },
    setOrder(id) {
      this.$store.dispatch("order/getOrder", id).then(() => {
        this.$scrollTo("#cabinetOrder", 1000, { offset: -70 });
      });
    },
  },
};
</script>

<style scoped>
.cabinet-pay-button {
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
}
</style>
