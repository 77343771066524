import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "@/axios";
import { i18n } from "@/i18n/i18n";

import {
  FormSelectPlugin,
  LayoutPlugin,
  DropdownPlugin,
  CarouselPlugin,
  FormRatingPlugin,
  ModalPlugin,
  TooltipPlugin,
  CollapsePlugin,
  SkeletonPlugin,
  ProgressPlugin,
} from "bootstrap-vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueTheMask from "vue-the-mask";
import YmapPlugin from "vue-yandex-maps";
import vSelect from "vue-select";
import Meta from "vue-meta";
import VueScrollactive from "vue-scrollactive";
import VCalendar from "v-calendar";
import VueCookies from "vue-cookies";
import Notifications from "vue-notification";
import VueScrollTo from "vue-scrollto";

//TODO: get from .env
const YmapSettings = {
  apiKey: "97650fc1-1923-4f9b-ac19-291883495139",
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1",
};

//mixin
import GlobalMixin from "./mixins/global.js";

//use
Vue.use(Meta);
Vue.use(FormSelectPlugin);
Vue.use(LayoutPlugin);
Vue.use(DropdownPlugin);
Vue.use(CarouselPlugin);
Vue.use(FormRatingPlugin);
Vue.use(ModalPlugin);
Vue.use(TooltipPlugin);
Vue.use(CollapsePlugin);
Vue.use(SkeletonPlugin);
Vue.use(ProgressPlugin);
Vue.use(VueAwesomeSwiper);
Vue.use(VueTheMask);
Vue.use(YmapPlugin, YmapSettings);
Vue.use(VueScrollactive);
Vue.use(VCalendar);
Vue.use(VueCookies);
Vue.use(Notifications);
Vue.use(VueScrollTo);

Vue.component("v-select", vSelect);

Vue.mixin(GlobalMixin);

//css
import "normalize.css";
import "./assets/style/bootstrap.scss";
import "swiper/css/swiper.css";
import "vue-select/dist/vue-select.css";

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

new Vue({
  router,
  axios,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
