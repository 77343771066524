<template>
  <div v-if="!success">
    <div class="review-modal__title">{{ $t('Modals_Review_Add_write2review') }}</div>

    <form class="review-modal__form">
      <b-form-rating
        v-model="review.rating"
        variant="warning"
        class="review-modal__form-rating"
      ></b-form-rating>
      <form-group
        v-model="review.review"
        :error="errors.review"
        :label="$t('Modals_Review_Add_your_review')"
        :placeholder="$t('Modals_Review_Add_write2comment')"
      />
    </form>

    <button
      class="q-btn q-btn-teal review-modal__form-button"
      @click="submit"
    >
      {{ $t('Modals_Review_Add_next') }}
    </button>
  </div>
  <div v-else class="review-modal__greeting">
    <div class="review-modal__greeting-thumb">👍🏻</div>
    <div class="review-modal__greeting-title">{{$t('Modals_Review_Add_thank_you')}}</div>
    <div class="review-modal__greeting-text">{{ $t('Modals_Review_Add_waiting') }}</div>
    <button
      class="q-btn review-modal__greeting-button"
      @click="hideReviewModal"
    >
      {{ $t('Modals_Review_Add_close') }}
    </button>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";

export default {
    name: "Add",
    props: {
        success: Boolean,
    },
    components: {
        FormGroup,
    },

    data() {
        return {
            review: {
              rating: 5
            },
            errors: {},
        }
    },

    methods: {
        hideReviewModal() {
            this.$emit('hideModal');
        },
        async submit() {
            try {
                await this.$emit('submit', this.review);
            } catch (error) {
                this.errors = ((error.response || {}).data || {}).errors || {};
            }
        },
    },
}
</script>
