<template>
  <div
    :class="{
      'cart-stock__item-green': marker === 'green',
      'cart-stock__item-yellow': marker === 'yellow',
      'cart-step__shop-stock': step
    }"
    class="cart-stock__item-cells"
  >
    <div class="cart-stock__item-cell"></div>
    <div class="cart-stock__item-cell"></div>
    <div class="cart-stock__item-cell"></div>
    <div class="cart-stock__item-cell"></div>
    <div class="cart-stock__item-cell"></div>
  </div>
</template>

<script>
export default {
    name: "CartStock",
    props: {
        marker: String,
        step: Boolean,
    },
}
</script>
