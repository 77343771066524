<template>
  <b-container class="about-us">
    <b-row>
      <b-col cols="12" class="about-us__header">
        <h2>{{ title }}</h2>
        <div class="q-tab">
          <router-link
            to="/about"
            :class="{'q-tab__item-active': $route.path === '/about'}"
            class="q-tab__item"
          >
            {{ $t('About_we_01') }}
          </router-link>
          <router-link
            to="/about/vacancies"
            class="q-tab__item"
            :class="{'q-tab__item-active': $route.path === '/about/vacancies'}"
          >
            {{ $t('About_vacancy_01') }}
          </router-link>
          <router-link
            to="/about/partners"
            class="q-tab__item"
            :class="{'q-tab__item-active': $route.path === '/about/partners'}"
          >
            {{ $t('About_partners_01') }}
          </router-link>
        </div>
      </b-col>
      <router-view></router-view>
    </b-row>
  </b-container>
</template>

<script>
  export default {
      name: 'About',
      data() {
          return {}
      },
      computed: {
          //temp
          title() {
              const { path } = this.$route;
              if (path === '/about') return this.$t('About_we_01');
              else if (path === '/about/partners') return this.$t('About_partners_01');
              return this.$t('About_vacancy_01');
          },
      },
  }
</script>
