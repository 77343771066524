<template>
  <div class="cart-step__pickup">
    <div
      class="q-issue"
      v-if="filialMarker(selectedFilial && selectedFilial.id) === 'yellow'"
    >
      <div>
        {{ $t("Cart_Step2_pickup_attention_for_delivery") }}
        <br />
        <span @click="$scrollTo('#cart-step-3', 1000, { offset: -70 })">{{
          $t("Cart_Step2_pickup_out_of_stock_01")
        }}</span>
        {{ $t("Cart_Step2_pickup_out_of_stock_02") }}
      </div>
    </div>
    <div
      class="q-issue"
      v-if="filialMarker(selectedFilial && selectedFilial.id) === 'red'"
    >
      {{ $t("Cart_Step2_pickup_out_of_stock_03") }}
      <br />{{ $t("Cart_Step2_pickup_out_of_stock_04") }}
    </div>
    <b-row>
      <b-col cols="12" md="5" xl="4">
        <form-group
          v-model="selectedDate"
          :options="dates"
          type="select2"
          :label="$t('Cart_Step2_pickup_date_01')"
          :placeholder="$t('Cart_Step2_pickup_date_02')"
        />
      </b-col>
      <b-col cols="12" md="5" xl="4">
        <form-group
          v-model="selectedTime"
          :options="hours"
          type="select2"
          :label="$t('Cart_Step2_pickup_time_01')"
          :placeholder="$t('Cart_Step2_pickup_time_02')"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" class="cart-step__subtitle">
        {{ $t("Cart_Step2_pickup_choose2check") }}
      </b-col>
      <b-col cols="12" md="4">
        <div v-if="!selectedFilial" class="cart-step__pickup-status">
          {{ $t("Cart_Step2_pickup_out_of_stock_05") }}
        </div>
        <div v-else-if="selectedFilial.id">
          <div
            v-if="filialMarker(selectedFilial && selectedFilial.id) === 'green'"
            class="cart-step__pickup-status approve"
          >
            {{ $t("Cart_Step2_pickup_stock_green") }}
          </div>
          <div
            v-else-if="
              filialMarker(selectedFilial && selectedFilial.id) === 'yellow'
            "
            class="cart-step__pickup-status yellow"
          >
            {{ $t("Cart_Step2_pickup_stock_yellow") }}
          </div>
          <div v-else class="cart-step__pickup-status red">
            {{ $t("Cart_Step2_pickup_stock_red") }}
          </div>
        </div>
        <div v-else>
          <div class="cart-step__pickup-status red">
            {{ $t("Cart_Step2_pickup_choose2shop") }}
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="8">
        <div class="cart-stock__items">
          <div class="cart-stock__item">
            <cart-stock marker="green" />
            <div class="cart-stock__item-text">
              {{ $t("Cart_Step2_pickup_stock_green_02") }}
            </div>
          </div>
          <div class="cart-stock__item">
            <cart-stock marker="yellow" />
            <div class="cart-stock__item-text">
              {{ $t("Cart_Step2_pickup_stock_yellow_02") }}
            </div>
          </div>
          <div class="cart-stock__item">
            <cart-stock />
            <div class="cart-stock__item-text">
              {{ $t("Cart_Step2_pickup_stock_red_02") }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="(filial, i) in filials"
        v-show="filial.visible"
        :key="`filial--${i}`"
        cols="12"
        md="6"
      >
        <div
          :class="[
            {
              'cart-step__shop-active': selectedFilial.id === filial.id,
              'cart-step__shop-inactive': isEmptyFilial(filial.id),
            },
          ]"
          class="cart-step__shop"
          @click="
            checkEndWork(filial) || selectedDate !== today
              ? selectFilial(filial)
              : null
          "
        >
          <cart-stock :marker="filialMarker(filial.id)" step />
          <div class="cart-step__shop-title">{{ filial.name }}</div>
          <div class="filial_close_text" v-if="!checkEndWorkWithDate(filial)">
            {{ $t("branch_close") }}
          </div>
          <div
            class="cart-step__shop-text"
            :class="[
              !checkEndWorkWithDate(filial)
                ? 'mt-3'
                : 'cart-step__shop-text__address',
            ]"
          >
            {{ filial.address }}
          </div>
          <div class="cart-step__shop-text">{{ filial.phone }}</div>
          <div class="cart-step__shop-text">
            {{ filial.start_work }} - {{ filial.end_work }}
          </div>
          <div v-if="isEmptyFilial(filial.id)" class="cart-step__shop-text">
            {{ $t("Cart_Step2_pickup_change2date") }}
          </div>
        </div>
      </b-col>
    </b-row>
    <form-group
      v-model="comment"
      :label="$t('Cart_Step2_pickup_comment_label')"
      :placeholder="$t('Cart_Step2_pickup_comment_placeholder')"
      :max-value="30"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import FormGroup from "@/components/FormGroup/FormGroup.vue";
import CartStock from "../CartStock";
import { mapState } from "vuex";

export default {
  name: "Pickup",
  components: {
    FormGroup,
    CartStock,
  },
  data() {
    return {
      today: dayjs().format("DD.MM.YYYY"),
    };
  },
  computed: {
    ...mapState({
      filials: (state) => state.filial.filials,
      dates: (state) => state.cart.pickupDates,
      hours: (state) => state.cart.pickupTimes,
    }),
    selectedFilial: {
      get() {
        return this.$store.state.filial.activeFilial;
      },
      set(filial) {
        this.$store.commit("filial/SET_ACTIVE_FILIAL", filial);
      },
    },
    selectedDate: {
      get() {
        return this.$store.state.cart.selectedPickupDate;
      },
      set(selectedDate) {
        this.$store.commit("cart/SET_SELECTED_PICKUP_DATE", selectedDate);
      },
    },
    selectedTime: {
      get() {
        return this.$store.state.cart.selectedPickupTime;
      },
      set(selectedTime) {
        this.$store.commit("cart/SET_SELECTED_PICKUP_TIME", selectedTime);
      },
    },
    comment: {
      get() {
        return this.$store.state.order.comment;
      },
      set(comment) {
        return this.$store.commit("order/SET_COMMENT", comment);
      },
    },
  },
  mounted() {
    //this.$store.commit("cart/SET_SELECTED_PICKUP_DATE", this.dates[0]);
  },
  methods: {
    filialMarker(filialId) {
      return this.$store.getters["stopList/filialMarker"](filialId);
    },
    selectFilial(filial) {
      if (this.filialMarker(filial.id) === "red") return;
      this.selectedFilial = filial;
    },
    isEmptyFilial(id) {
      return this.filialMarker(id) === "red";
    },
    checkEndWork(filial) {
      const current = new Date();
      const endWorkTime = new Date();
      const [hours, minutes] = filial.end_work.split(":");
      endWorkTime.setHours(hours);
      endWorkTime.setMinutes(minutes);
      return current <= endWorkTime;
    },
    checkEndWorkWithDate(filial) {
      const current = new Date();
      const endWorkTime = new Date();
      const [hours, minutes] = filial.end_work.split(":");
      endWorkTime.setHours(hours);
      endWorkTime.setMinutes(minutes);
      if (current <= endWorkTime) {
        return true;
      } else {
        if (this.selectedDate !== "Сегодня") {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>
<style>
.filial_close {
  opacity: 0.2;
}
.filial_close_text {
  font-weight: 500;
  font-size: 12px;
}
.cart-step__shop {
  position: relative;
}
.cart-step__shop-text__address {
  margin-top: 49px;
}
</style>
