<template>
  <b-modal
    ref="productModal"
    id="productModal"
    size="xl"
    hide-header
    @hide="hideModal"
    hide-footer
    body-class="product-modal"
  >
    <div class="product-modal__close-button" @click="hideModal"></div>
    <div v-if="product && product.id">
      <b-row>
        <b-col order="1" order-lg="0">
          <general v-bind="product"/>
        </b-col>
        <b-col order="0" order-lg="1">
          <button class="q-btn q-btn-teal" @click="redirectToProduct">{{ $t('open_marking') }}</button>
          <images
              :can_decor="product.can_decor"
              :images="product.images"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="6">
          <reviews
              :count="product.reviews_count"
              :reviews="product.reviews"
              :rating="product.rating"
          />
          <nutrition v-bind="product"/>
        </b-col>
        <b-col>
          <stock />
        </b-col>
      </b-row>
    </div>
    <div v-else class="d-flex justify-content-center">
      <div style="width: 10rem; height: 10rem;" class="main-spinner spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </b-modal>
</template>

<script>
import General from "./Product/General.vue";
import Images from "./Product/Images.vue";
import Stock from "./Product/Stock.vue";
import Nutrition from "./Product/Nutrition.vue";
import Reviews from "./Product/Reviews.vue";
import { mapState } from "vuex";

export default {
    name: "ProductModal",
    components: {
        General,
        Images,
        Stock,
        Nutrition,
        Reviews,
    },
    computed: {
        ...mapState({
            product: state => state.products.modalProduct,
        }),
    },
    methods: {
        hideModal() {
            this.$refs['productModal'].hide();
            this.$router.replace({query:{...this.$route.query,id:undefined}});
        },
        redirectToProduct() {
          this.$router.push(`/product/${this.product.id}`)
          this.hideModal()
        }
    },
}
</script>
