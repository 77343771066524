<template>
  <div class="q-card order-card">
    <div
      class="tt-confirm-order__kaspi"
      v-if="
        order.payment !== undefined &&
        ['kspi', 'kpay'].includes(order.payment.type) &&
        !order.payed_at
      "
    >
      <div class="tt-confirm-order__kaspi-info">
        <h5>{{ $t("OrderPage_cmpt_Payment_how_to_pay2title") }}</h5>
        <div>{{ $t("ConfirmationOrder_kaspi_how_to_pay_1") }}</div>
        <div
          v-html="
            $t('ConfirmationOrder_kaspi_how_to_pay_2').replace(
              '${order.id}',
              order.id
            )
          "
        ></div>
      </div>

      <form action="https://kaspi.kz/online" method="post">
        <input
          type="hidden"
          name="TranId"
          :value="order.payment_unique_number"
        />
        <input type="hidden" name="OrderId" :value="order.id" />
        <input type="hidden" name="Amount" :value="order.amount * 100" />
        <input type="hidden" name="Service" value="QulpynaiBakery" />
        <input type="hidden" name="returnUrl" :value="getReturnUrl()" />
        <input type="hidden" name="Signature" value="" />
        <button
          type="submit"
          class="cart-payment__kaspi"
          style="border: 0px; padding: 15px 30px"
        >
          <img src="@/assets/images/payments/kaspi.svg" alt="Kaspi.kz" />
          <span>Kaspi.kz</span>
        </button>
      </form>
    </div>
    <div class="order-card__order-number">
      {{ $t("Order_OrderCard_number") }}
      <span>Q-{{ order.id }}</span>
    </div>
    <div
      class="order-card__head-date"
      v-if="!isCashPayment() && !orderWasPaid()"
    >
      <div class="order-card__head-hour">
        <span>{{ $t("Order_OrderCard_order_has_not_been_paid") }}</span>
      </div>
    </div>
    <div class="order-card__head-date" v-else-if="canceled">
      <div class="order-card__head-hour">
        <span>{{ $t("Order_OrderCard_order_cancelled") }}</span>
      </div>
    </div>
    <div class="order-card__head-date" v-else-if="order.status === 'Error'">
      <div class="order-card__head-hour">
        {{ $t("Order_OrderCard_order_Error") }}
      </div>
    </div>
    <div class="order-card__head-date" v-else-if="order.is_delivery">
      <div class="order-card__head-hour">
        {{ $t("Order_OrderCard_delivery_date") }}
        <span> {{ order.delivery_time_interval }}</span>
      </div>
      <div class="order-card__head-day">
        <div class="order-card__head-label">
          {{ $t("Order_OrderCard_hday") }}
        </div>
        {{ order.delivery_date }}
      </div>
    </div>
    <div class="order-card__head-date" v-else>
      <div class="order-card__head-hour">
        {{ $t("Order_OrderCard_pickup_date") }} <span> {{ deliveryDate }}</span>
      </div>
      <div class="order-card__head-day">
        <div class="order-card__head-label">
          {{ $t("Order_OrderCard_date") }}
        </div>
        {{ order.delivery_date }}
      </div>
    </div>

    <order-timeline :cabinet="cabinet" :order="order" :canceled="canceled" />
    <div class="order-card__info" v-if="order.is_delivery">
      <div class="order-card__info-item">
        <div class="order-card__info-item-key">
          {{ $t("Order_OrderCard_adress") }}
        </div>
        <div class="order-card__info-item-value">
          {{ order.delivery_address }}
        </div>
      </div>
      <div class="order-card__info-item">
        <div class="order-card__info-item-key">
          {{ $t("Order_OrderCard_time_delivery") }}:
        </div>
        <div class="order-card__info-item-value">
          {{ order.delivery_date }} / {{ order.delivery_time_interval }}
        </div>
      </div>
      <div
        class="order-card__info-item"
        v-if="order.friend_name || order.friend_phone"
      >
        <div class="order-card__info-item-key">
          {{ $t("Cart_Step4_index_tab_for_friend") }}:
        </div>
        <div class="order-card__info-item-value">
          {{ order.friend_name }} {{ order.friend_phone }}
        </div>
      </div>
      <div
        class="order-card__info-item"
        v-if="order.order_type === 'DeliveryByCourier' && order.comment"
      >
        <div class="order-card__info-item-key">
          {{ $t("Cart_Step2_delivery_comment_label") }}:
        </div>
        <div class="order-card__info-item-value">{{ order.comment }}</div>
      </div>

      <!--      <div class="order-card__info-item">-->
      <!--        <div class="order-card__info-item-key">Примечания к адресу:</div>-->
      <!--        <div class="order-card__info-item-value">вниз на лево там на право и первый подьезд</div>-->
      <!--      </div>-->
    </div>
    <div class="order-card__info" v-else>
      <div class="order-card__info-item">
        <div class="order-card__info-item-key">
          {{ $t("Order_OrderCard_pickup_adress") }}
        </div>
        <div class="order-card__info-item-value">
          {{ order.filial_address }}
        </div>
      </div>
      <div class="order-card__info-item">
        <div class="order-card__info-item-key">
          {{ $t("Order_OrderCard_time_work") }}
        </div>
        <div class="order-card__info-item-value">
          {{ order.filial_work_time }}
        </div>
      </div>
      <div class="order-card__info-item">
        <div class="order-card__info-item-key">
          {{ $t("Order_OrderCard_time_delivery") }}
        </div>
        <div class="order-card__info-item-value">
          {{ order.delivery_date }} / {{ order.delivery_time_interval }}
        </div>
      </div>
    </div>

    <div class="order-card__items" v-if="order.cart">
      <div class="order-card__items-title">
        {{ $t("Order_OrderCard_your_order") }}
      </div>
      <div class="order-card__item" v-for="product in order.cart.products">
        <div class="order-card__item-img">
          <img v-if="product.image" :src="product.image" alt="product" />
          <img v-else src="@/assets/images/default_product.png" alt="product" />
        </div>
        <div class="order-card__item-content">
          <div class="order-card__item-title">{{ product.name }}</div>
        </div>
        <div class="order-card__item-count">
          {{ product.quantity }} {{ $t("Order_OrderCard_unit") }}
        </div>
        <div class="order-card__item-price">
          {{ numberWithSpaces(product.price) }} ₸
        </div>
      </div>
    </div>
    <div class="order-card__total">
      <div class="order-card__total-item" v-if="order.is_delivery">
        <div class="order-card__total-item-key">
          {{ $t("Order_OrderCard_price_delivery") }}
        </div>
        <div class="order-card__total-item-value">
          {{ numberWithSpaces(order.delivery_price) }} ₸
        </div>
      </div>
      <div class="order-card__total-item">
        <div class="order-card__total-item-key">
          {{ $t("Order_OrderCard_sum") }}
        </div>
        <div class="order-card__total-item-value">
          {{ numberWithSpaces(order.amount) }} ₸
        </div>
      </div>
    </div>
    <div class="order-card__footer q-desktop-only">
      <a
        v-if="showPayButton()"
        class="q-btn q-btn-red"
        @click="chooseTypeOfPayment"
        :disabled="isDisabledPayButton"
      >
        {{ $t("order_pay_order") }}
      </a>
      <router-link v-else to="/cabinet" class="q-btn q-btn-red">{{
        $t("Order_OrderCard_cabinet")
      }}</router-link>
      <router-link to="/" class="q-btn">{{
        $t("Order_OrderCard_back")
      }}</router-link>
    </div>
  </div>
</template>

<script>
import OrderTimeline from "./OrderTimeline.vue";
import { mapState } from "vuex";

export default {
  name: "OrderCard",
  props: {
    cabinet: Boolean,
  },
  components: {
    OrderTimeline,
  },
  data() {
    return {
      isDisabledPayButton: false,
    };
  },
  async created() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://widget.tiptoppay.kz/bundles/widget.js"
    );
    document.head.appendChild(recaptchaScript);
    if (!this.cabinet)
      setTimeout(() => {
        this.chooseTypeOfPayment();
      }, 1000);
  },
  computed: {
    ...mapState({
      order: (state) => state.order.order,
    }),
    canceled() {
      return this.order.status_id === 11;
    },
    deliveryDate() {
      const { delivery_time_interval } = this.order;
      return delivery_time_interval && delivery_time_interval.split(" ")[2];
    },
  },
  methods: {
    showPayButton() {
      if (
        !this.isDisabledPayButton &&
        this.order.payment?.type == "mcard" &&
        !this.order.payed_at
      )
        return true;

      return (
        this.order.payment_url &&
        !this.order.payed_at &&
        !this.order.payment_url_expired
      );
    },
    orderWasPaid() {
      return this.order.payed_at;
    },
    isCashPayment() {
      return this.order.payment?.type === "cash";
    },
    getReturnUrl() {
      return window.location.origin + "/order/" + this.order.id;
    },
    chooseTypeOfPayment() {
      if (this.order.payment?.type === "mcard" && !this.order.payed_at)
        this.payCP();
    },
    payCP() {
      var widget = new tiptop.Widget({
        language: this.$i18n.locale == "kk" ? "kk_KZ" : "ru-RU",
      });
      widget.pay(
        "charge", // или 'charge'|auth
        {
          publicId: this.order.filial.cloudpayments_pk_key,
          description: "Qulpynai.kz " + this.order.id,
          amount: this.order.amount,
          currency: "KZT",
          invoiceId: this.order.id,
          skin: "mini",
          autoClose: 3,
        },
        {
          onSuccess: (options) => {
            this.isDisabledPayButton = true;
          },
          onFail: (reason, options) => {},
        }
      );
    },
  },
};
</script>

<style scoped></style>
