<template>
  <b-row>
    <b-col cols="12" lg="6">
      <div class="review-modal__body">
        <div
          class="review-modal__back-button"
          @click="$emit('hideReviewModal')"
        >
          {{ $t('Modals_Review_Show_back') }}
        </div>
        <div>
          <div class="review-modal__title">{{ $t('Modals_Review_Show_review') }}</div>
          <div class="review-modal__author">{{$t('Modals_Review_Show_from')}} {{ review.author }}</div>
          <div class="review-modal__form">
            <b-form-rating
              v-model="review.rating"
              variant="warning"
              class="review-modal__form-rating"
              disabled
              size="lg"
            ></b-form-rating>
          </div>
          <div class="review-modal__text">
            {{ review.review }}
          </div>
          <div class="review-modal__button q-btn" @click="writeReview">
            {{ $t('Modals_Review_Show_for_product') }}
          </div>
        </div>
      </div>
    </b-col>
    <b-col class="q-desktop-only">
      <div class="review-modal__image"></div>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "Show",

    computed: {
        ...mapState({
            review: state => state.reviews.modalReview,
        }),
    },

    methods: {
        writeReview() {
            this.$store.commit('reviews/SET_REVIEW', {});
        },
    },
}
</script>
