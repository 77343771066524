<template>
  <b-col cols="12" lg="4">
    <div v-if="currentUser && !currentUser.email" class="q-card email-subscription">
      <div class="email-subscription__title">
        {{ $t('Cabinet_CabinetProfile_subsc_title') }}
      </div>
      <form-group
          v-for="(field, i) in fields"
          v-model="form[field.name]"
          :key="`update-info-modal-field__${i}`"
          :error="errors[field.name] && errors[field.name][0]"
          :label="$t(field.label)"
          :placeholder="$t(field.placeholder)"
          :type="field.type"
      />
      <div class="q-btn q-btn-teal email-subscription__button" @click="updateUser">{{ $t('Cabinet_CabinetProfile_btn2email') }}</div>
    </div>

    <div v-if="currentUser" class="q-card cabinet-profile">
      <div class="cabinet-profile__head">
        <div class="cabinet-profile__avatar">
          <img src="@/assets/images/ava.png" alt="avatar">
        </div>
        <div class="cabinet-profile__name">{{ currentUser.name }}</div>
      </div>
      <div class="cabinet-profile__bonus">
        <div class="cabinet-profile__bonus-item">
          <div>{{ $t('Cabinet_CabinetProfile_title') }}</div>
          <div class="cabinet-profile__bonus-value">{{ currentUser.wallet_balance }} ₸</div>
        </div>
        <div class="cabinet-profile__bonus-item">
          <div>{{ $t('Cabinet_CabinetProfile_title_bday') }}</div>
          <div class="cabinet-profile__bonus-value">{{ currentUser.birthday_wallet_balance }} ₸</div>
        </div>
      </div>
      <div class="cabinet-profile__items">
        <div v-if="currentUser.birthdate" class="cabinet-profile__item">
          <div class="cabinet-profile__item-label">{{ $t('Cabinet_CabinetProfile_bday') }}</div>
          <div class="cabinet-profile__item-date">{{ currentUser.birthdate }}</div>
        </div>
        <div v-if="currentUser.phone" class="cabinet-profile__item">
          <div class="cabinet-profile__item-label">{{ $t('Cabinet_CabinetProfile_number') }}</div>
          <div class="cabinet-profile__item-date">+{{ currentUser.phone }}</div>
        </div>
        <div v-if="currentUser.email" class="cabinet-profile__item">
          <div class="cabinet-profile__item-label">{{ $t('Cabinet_CabinetProfile_email') }}</div>
          <div class="cabinet-profile__item-email">{{ currentUser.email }}</div>
        </div>
      </div>

      <button
          v-if="!currentUser.birthdate"
          class="q-btn cabinet-profile__button"
          @click="showBdayModal"
      >
        {{ $t('Cabinet_CabinetProfile_change2profile') }}
      </button>
      <button class="q-btn cabinet-profile__button" @click="showModal">{{
          $t('Cabinet_CabinetProfile_change2pass')
        }}
      </button>
    </div>
  </b-col>
</template>

<script>
import FormGroup from "@/components/FormGroup/FormGroup.vue";

export default {
  name: "CabinetProfile",
  components: {
    FormGroup,
  },

  methods: {
    showModal() {
      this.$bvModal.show('cabinetModal');
    },
    showBdayModal() {
      this.$bvModal.show('cabinetBdayModal');
    },
    updateUser() {
      this.$store.dispatch('auth/updateUser', this.form)
          .then(response => {
            this.$store.commit('auth/SET_USER', response.data);
            this.hideModal();
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              this.errors = error.response.data.errors;
            }
          })
    }
  },
  data() {
    return {
      form: {},
      errors: {},
      fields: [
        {
          name: 'email',
          label: 'Email',
          placeholder: 'Cart_Step1_placeholder_email',
        },
      ],
    }
  },
}
</script>
