<template>
  <div :class="{ 'q-form-group-error': error }" class="q-form-group">
    <div v-if="label" class="q-form-group__label">
      {{ label }}
    </div>

    <div v-if="type === 'phone'" class="q-form-group__phone">
      <input
        v-model="newValue"
        v-mask="'+7 (7##) ###-##-##'"
        type="tel"
        placeholder="+7 (7__) ___-__-__"
        class="form-input form-input-phone"
        @input="valueChange"
      />
    </div>

    <div v-else-if="type === 'password'" class="q-form-group__password">
      <input
        v-model="newValue"
        type="password"
        class="form-input form-input-password"
        :placeholder="placeholder"
        @input="valueChange"
      />
    </div>

    <div v-else-if="type === 'select'" class="q-form-group__select">
      <v-select
        v-model="newValue"
        :clearable="false"
        :options="options"
        :searchable="false"
        :placeholder="placeholder"
        @input="valueChange"
      >
        <div slot="no-options">{{ $t("FormGroup_without_option") }}</div>
      </v-select>
    </div>

    <div v-else-if="type === 'select2'" class="q-form-group__select">
      <b-form-select
        v-model="newValue"
        :options="options"
        @change="valueChange"
      />
    </div>

    <div v-else-if="type === 'date'">
      <v-date-picker
        v-model="date"
        @input="valueChange"
        :masks="masks"
        :input-debounce="1"
      >
        <template
          v-slot="{ inputValue, togglePopover, inputEvents, updateValue }"
        >
          <div class="q-form-group__date">
            <button
              class="q-form-group__date__button"
              @click="togglePopover({ placement: 'bottom' })"
            ></button>
            <input
              ref="masked"
              type="text"
              class="form-input form-input-date"
              @change="valueChange(inputValue, updateValue)"
              :placeholder="$t('FormGroup_placeholder_hday')"
              v-on="inputEvents"
              :value="inputValue"
            />
          </div>
        </template>
      </v-date-picker>
    </div>
    <div v-else class="q-form-group__common">
      <input
        v-model="newValue"
        :id="id"
        :placeholder="placeholder"
        :maxlength="maxValue"
        type="text"
        class="form-input"
        @input="valueChange"
        :autocomplete="autocomplete ?? 'on'"
        @blur="$emit('blur')"
        @focus="$emit('focus')"
      />
    </div>

    <div v-if="error" class="q-form-group__error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import QDropdown from "@/components/QDropdown/QDropdown.vue";
import Cleave from "cleave.js";

export default {
  name: "FormGroup",
  props: {
    error: String,
    label: String,
    id: String,
    placeholder: String,
    type: String,
    value: [String, Number],
    options: Array,
    maxValue: Number,
    autocomplete: { type: String, default: "on" },
  },
  components: {
    QDropdown,
  },

  data() {
    return {
      date: null,
      newValue: null,
      masks: {
        input: "DD.MM.YYYY",
        mode: "date",
      },
    };
  },
  mounted() {
    if (this.$refs.masked) {
      const c = new Cleave(this.$refs.masked, {
        date: true,
        datePattern: ["d", "m", "Y"],
        delimiter: ".",
      });
    }
    if (this.value) {
      this.newValue = this.value;
    }
  },

  methods: {
    valueChange(value, updateValue = null) {
      if (value && updateValue && value.length === 10) {
        const parts = value.split(".");
        const date = new Date(parts[2], parts[1] - 1, parts[0]);
        updateValue(date, { hidePopover: false, formatInput: true });
      }
      if (value && typeof value.getMonth === "function") {
        value = [value.getDate(), value.getMonth() + 1, value.getFullYear()]
          .map((n) => (n < 10 ? `0${n}` : `${n}`))
          .join(".");
      }
      if (typeof value === "string") this.$emit("input", value);
      else this.$emit("input", this.newValue);
    },
  },
  watch: {
    value: function (newValue) {
      if (newValue) {
        this.newValue = newValue;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/components/form/vue-select.scss";
</style>
