<template>
  <div
    v-if="!cabinet"
    :class="{
      'order-card__progress-four': order.order_type === 'DeliveryByClient',
    }"
    class="order-card__progress"
  >
    <div
      class="order-card__progress-item"
      :class="{
        colored: order.status_id >= 2,
      }"
    ></div>
    <div
      class="order-card__progress-item"
      :class="{
        colored: order.status_id >= 4,
      }"
    ></div>
    <div
      class="order-card__progress-item"
      :class="{
        colored: order.status_id >= 5,
      }"
    ></div>
    <div
      v-if="order.order_type !== 'DeliveryByClient'"
      class="order-card__progress-item"
      :class="{
        colored: order.status_id >= 7,
      }"
    ></div>
    <div
      class="order-card__progress-item"
      :class="{
        colored: order.status_id >= 8,
      }"
    ></div>
  </div>
  <div
    v-else-if="order.status_id !== 11"
    :class="{
      'order-card__timeline-four': order.order_type === 'DeliveryByClient',
    }"
    class="order-card__timeline"
  >
    <div
      class="order-card__timeline-item"
      :class="{
        'order-card__timeline-item-current': order.status_id === 2,
        'order-card__timeline-item-green': order.status_id > 2,
      }"
    >
      <div
        class="order-card__timeline-icon order-page__status-icon order-page__status-icon-step1"
      ></div>
      <div class="order-card__timeline-title">
        {{ $t("Order_OrderTimeline_Order_Accept") }}
        <p v-if="!(order.payment.type === 'cash') && !order.payed_at">
          {{ $t("Order_OrderTimeline_Order_Wait_Pay") }}
        </p>
        <p v-else-if="!(order.payment.type === 'cash') && order.payed_at">
          {{ $t("Order_OrderTimeline_Order_Payed") }}
        </p>
      </div>
    </div>
    <div
      class="order-card__timeline-item"
      :class="{
        'order-card__timeline-item-current': order.status_id === 4,
        'order-card__timeline-item-green': order.status_id > 4,
      }"
    >
      <div
        class="order-card__timeline-icon order-page__status-icon order-page__status-icon-step2"
      ></div>
      <div class="order-card__timeline-title">
        {{ $t("Order_OrderTimeline_Order_Waiting") }}
      </div>
    </div>
    <div
      class="order-card__timeline-item"
      :class="{
        'order-card__timeline-item-current': order.status_id === 5,
        'order-card__timeline-item-green': order.status_id > 5,
      }"
    >
      <div
        class="order-card__timeline-icon order-page__status-icon order-page__status-icon-step3"
      ></div>
      <div class="order-card__timeline-title">
        {{ $t("Order_OrderTimeline_Order_Ready") }}
      </div>
    </div>
    <div
      class="order-card__timeline-item"
      v-if="order.is_delivery"
      :class="{
        'order-card__timeline-item-current': order.status_id === 7,
        'order-card__timeline-item-green': order.status_id > 7,
      }"
    >
      <div
        class="order-card__timeline-icon order-page__status-icon order-page__status-icon-step4"
      ></div>
      <div class="order-card__timeline-title">
        {{ $t("Order_OrderTimeline_Order_Delivery") }}
      </div>
    </div>
    <div
      class="order-card__timeline-item"
      :class="{
        'order-card__timeline-item-current': order.status_id === 8,
        'order-card__timeline-item-green': order.status_id > 8,
      }"
    >
      <div
        class="order-card__timeline-icon order-page__status-icon order-page__status-icon-step5"
      ></div>
      <div class="order-card__timeline-title">
        {{
          order.is_delivery
            ? $t("Order_OrderTimeline_Order_Delivered")
            : $t("Order_OrderTimeline_Order_Closed")
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderTimeline",
  props: {
    cabinet: Boolean,
    order: Object,
    canceled: Boolean,
  },
  mounted() {
    this.$nextTick(() => {});
  },
};
</script>
