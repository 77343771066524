<template>
  <b-col cols="12" lg="3">
    <div class="q-card cart-side">
      <div class="cart-side__header">
        <div>{{ $t('Cart_Side_sostav_order') }}</div>
        <span>{{ quantity }}</span>
      </div>
      <div v-if="filialMarker(selectedFilial && selectedFilial.id) === 'yellow'" class="cart-side__body">
        <div class="cart-side__error">
          <div>
            {{ $t('Cart_Side_out_of_stock_01') }}
            <br><br>
            <span @click="$scrollTo('#cart-step-3', 1000, {offset: -140})">{{ $t('Cart_Side_out_of_stock_02') }}</span>
            {{ $t('Cart_Side_out_of_stock_03') }}
            <span @click="$scrollTo('#cart-step-2', 1000, {offset: -140})">{{ $t('Cart_Side_out_of_stock_04') }}</span>
          </div>
        </div>
      </div>
      <div v-if="filialMarker(selectedFilial && selectedFilial.id) === 'red'" class="cart-side__body">
        <div class="cart-side__error">
          <div>
            {{ $t('Cart_Side_out_of_stock_05') }}
            <br><br>
            <span @click="$scrollTo('#cart-step-2', 1000, {offset: -140})">{{ $t('Cart_Side_out_of_stock_06') }}</span>
          </div>
        </div>
      </div>
      <cart-preloader :loading="loading"/>
      <div v-if="!loading">
        <div class="cart-side__items">
          <div
              v-for="(item, i) in products"
              :key="`cart-side-item__${i}`"
              class="cart-side__item"
          >
            <div class="next-day-block" :title="$t('CatalogProduct_label_have_next_day')">
              <span v-if="item.next_day">
                <img src="../../assets/icons/next-day.svg" alt/>
              </span>
            </div>
            <div class="cart-side__item-img">
              <img v-if="item.image" :src="item.image" :alt="item.name">
              <img v-else src="@/assets/images/default_product.png" :alt="item.name">
            </div>
            <div class="cart-side__item-content">
              <div class="cart-side__item-content-head">
                <div class="cart-side__item-title">
                  {{ item.name }}
                </div>
                <div class="cart-product__clear cart-item__clear" @click="removeProduct(item)"></div>
              </div>
              <div class="cart-side__item-content-body">
                <div
                    v-if="productIsOutOfStock(item.id)"
                    class="cart-side__item-out"
                >
                  {{ $t('Cart_Side_out_of_stock_07') }}
                </div>
                <div
                    v-else
                    class="cart-item__count cart-side__item-count"
                >
                  <div
                      class="cart-item__count-decrease"
                      @click="decrementProduct(item)"
                  >-
                  </div>
                  <div class="cart-item__count-value">{{ item.quantity }}</div>
                  <div
                      class="cart-item__count-increase"
                      @click="addProduct(item)"
                  >+
                  </div>
                </div>
                <div class="cart-side__item-price">{{ item.amount }} ₸</div>
              </div>
            </div>
          </div>
        </div>
        <div class="cart-side__price">
          <div class="cart-side__price-title">
            {{ $t('Cart_Side_price_to_pay_01') }}
          </div>
          <div class="cart-side__price-item">
            <div class="cart-side__price-item-key">
              {{ $t('Cart_Side_price_to_pay_02') }}
            </div>
            <div class="cart-side__price-item-value">
              {{ numberWithSpaces(amount) }} ₸
            </div>
          </div>
          <div v-if="isDelivery && deliveryPrice !== null" class="cart-side__price-item">
            <div class="cart-side__price-item-key">
              {{ $t('Cart_Side_tab_for_delivery') }}
            </div>
            <div v-if="deliveryPrice === 0" class="cart-side__price-item-value cart-side__price-item-value-green">
              {{ $t('Cart_Side_delivery_for_free') }}
            </div>
            <div v-else class="cart-side__price-item-value">
              {{ deliveryPrice }}
            </div>
          </div>
          <div v-if="isDelivery" class="cart-side__price-item">
            <div class="cart-side__price-item-key">
              {{ $t('Cart_Side_delivery_date') }}
            </div>
            <div class="cart-side__price-item-value">
              {{ deliveryDate }}
            </div>
          </div>
          <div v-if="isDelivery" class="cart-side__price-item">
            <div class="cart-side__price-item-key">
              {{ $t('Cart_Side_delivery_time') }}
            </div>
            <div class="cart-side__price-item-value">
              {{ deliveryTime }}
            </div>
          </div>
          <div v-if="!isDelivery" class="cart-side__price-item">
            <div class="cart-side__price-item-key">
              {{ $t('Cart_Side_pickup_date') }}
            </div>
            <div class="cart-side__price-item-value">
              {{ pickupDate }}
            </div>
          </div>
          <div v-if="!isDelivery" class="cart-side__price-item">
            <div class="cart-side__price-item-key">
              {{ $t('Cart_Side_pickup_time') }}
            </div>
            <div class="cart-side__price-item-value">
              {{ pickupTime }}
            </div>
          </div>
        </div>
        <div class="cart-side__footer">
          <span>{{ $t('Cart_Side_total_price') }}</span>
          <div class="cart-side__footer-total">
            <span v-if="isDelivery && deliveryPrice">{{ numberWithSpaces(amount + deliveryPrice) }} ₸</span>
            <span v-else>{{ numberWithSpaces(amount) }} ₸</span>
          </div>
        </div>
<!--        <div class="cart-side__footer q-mobile-only justify-content-center">-->
<!--          <button class="q-btn q-btn-red cart-step4__button" @click="createOrder">-->
<!--            {{ $t('Cart_Step4_Self_btn2order') }}-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>
  </b-col>
</template>

<script>
import CartPreloader from "./CartPreloader.vue";
import {mapState} from "vuex";

export default {
  name: "Side",
  components: {
    CartPreloader,
  },

  computed: {
    ...mapState({
      amount: state => state.cart.amount,
      products: state => state.cart.products,
      quantity: state => state.cart.productsQuantity,
      selectedFilial: state => state.filial.activeFilial,
      loading: state => state.cart.loading,
      isDelivery: state => state.cart.isDelivery,
      deliveryPrice: state => state.cart.deliveryPrice,
      deliveryDate: state => state.cart.selectedDeliveryDate,
      deliveryTime: state => state.cart.selectedDeliveryTime,
      pickupTime: state => state.cart.selectedPickupTime,
      pickupDate: state => state.cart.selectedPickupDate,
    }),
  },

  methods: {
    addProduct(product) {
      this.$store.dispatch('cart/addProduct', product);
    },
    decrementProduct(product) {
      this.$store.dispatch('cart/decrementProduct', product);
    },
    removeProduct(product) {
      this.$store.dispatch("cart/removeProduct", product);
    },
    filialMarker(filialId) {
      return this.$store.getters["stopList/filialMarker"](filialId)
    },
    productIsOutOfStock(productId) {
      return this.$store.getters["stopList/productIsOutOfStock"](productId)
    },
    createOrder() {
      this.$emit('createOrder')
    }
  },
}
</script>

<style lang="scss" scoped>
.next-day-block {
  position: absolute;
  left: 60px;
  cursor: pointer;

  img {
    width: 15px;
  }
}
</style>
