import axios from 'axios';

const state = {
    blog: [],
    promotion: [],
    loading: false,
    article: {},
};

const getters = {};

const mutations = {
    setArticles(state, payload) {
        state[payload.type] = payload.items && payload.items.articles;
    },
    setArticle(state, payload) {
        state.article = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
};

const actions = {
    async getArticles({state, commit}, type) {
        commit('setLoading', true);
        try {
            const { data } = await axios.get('/article', {
                params: { type, }
            });
            commit('setArticles', {type, items: data})
        } catch (e) {
            console.error(e);
        } finally {
            commit('setLoading', false);
        }
    },
    async getArticle({ commit }, id) {
        try {
            const { data } = await axios.get(`/article/${id}`);
            commit('setArticle', data);
        } catch (e) {
            console.error(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
