import axios from 'axios';

const state = {
    products: [],
    novelties: [],

    modalProduct: {},
    modalProducts: [],
};

const getters = {
    product_sections() {
        return state.products.map(section => {
            const item = {};

            item.name = section.name;
            item.slug = section.slug;
            return item;
        });
    },
};

const mutations = {
    SET_PRODUCTS(state, products) {
        state.products = products.categories;
        state.novelties = products.novelties;
    },
    SET_MODAL_PRODUCT(state, product) {
        state.modalProduct = product
    },
};

const actions = {
    getProducts({ commit }) {
        axios
            .get('product/list')
            .then(response => {
                commit('SET_PRODUCTS', response.data)
            });
    },
    getProduct({ commit }, id, stock) {
        commit('SET_MODAL_PRODUCT', {});
        axios.get('product/'+id).then(
            response => {
                state.modalProducts[id] = response.data;
                commit('SET_MODAL_PRODUCT', response.data);
            }
        )
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
