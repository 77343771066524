<template>
  <b-row>
    <b-col class="about-us__carousel">
      <iframe
          width="100%" height="510"
          src="https://www.youtube.com/embed/oHb9EyDZpUQ?si=_UedxA4YdxkFZJQZ&autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          autoplay="1">
      </iframe>
    </b-col>
  </b-row>
</template>

<script>
export default {
    name: "Carousel",
    data() {
        return {
            
        }
    },
}
</script>
