<template>
  <div
    :class="{'sticky': navStick}"
    class="sticky-nav"
  >
    <b-container>
      <b-row class="justify-content-between">
        <div v-if="!isCart" class="sticky-nav__left">
          <router-link to="/?section=all" class="sticky-nav__logo">
            <img
              v-show="navStick"
              alt="logo"
              src="@/assets/images/logo.svg"
            >
            <img
              v-show="!navStick"
              alt="logo"
              src="@/assets/images/logo-full.svg"
            >
          </router-link>
          <scrollactive
            v-if="isMain"
            :offset="80"
            class="sticky-nav__links"
          >
            <a
                @click="sectionClear"
                :class="[!$route.query.section ? 'text-red' : '' || $route.query.section === 'all' ? 'text-red' : '']"
                class="scrollactive-item sticky-nav__link ml-135"
            >
              {{ $t('Cart_Step3_btn2show') }}
            </a>
            <a
              v-for="(section, i) in sections"
              :key="`sticky-nav-item__${i}`"
              :class="[section.slug === elements[$route.query.section] ? 'text-red' : '']"
              @click="sectionHandler(section)"
              class="scrollactive-item sticky-nav__link"
            >
              {{ section.name }}
            </a>
          </scrollactive>
          <scrollactive
            v-else
            :offset="80"
            class="sticky-nav__links"
          >
            <a
                @click="sectionClear"
                :class="[!$route.query.section ? 'text-red' : '']"
                class="scrollactive-item sticky-nav__link ml-135"
            >
              {{ $t('Cart_Step3_btn2show') }}
            </a>
            <router-link
              v-for="(section, i) in sections"
              :key="`sticky-nav-item__${i}`"
              :class="[section.slug === elements[$route.query.section] ? 'text-red' : '']"
              @click="sectionHandler(section)"
              class="scrollactive-item sticky-nav__link"
            >
              {{ section.name }}
            </router-link>
          </scrollactive>
        </div>

        <div v-else class="sticky-nav__left">
          <router-link to="/?section=all" class="sticky-nav__logo">
            <img
              v-show="navStick"
              alt="logo"
              src="@/assets/images/logo.svg"
            >
            <img
              v-show="!navStick"
              alt="logo"
              src="@/assets/images/logo.svg"
            >
          </router-link>
          <scrollactive
            :offset="70"
            active-class="sticky-nav__breadcrumb-active"
            class="sticky-nav__links"
          >
            <a
              v-for="(item, i) in cartSections"
              :key="`nav-cart-section__${i}`"
              :href="item.link"
              :class="{'ml-135': !navStick && i === 0}"
              class="sticky-nav__breadcrumb scrollactive-item"
            >{{ $t(item.name) }}</a>
          </scrollactive>
        </div>
        <div class="sticky-nav__right">
          <main-nav-auth />
          <main-nav-cart />
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MainNavAuth from "./MainNavAuth.vue";
import MainNavCart from "./MainNavCart.vue";
import { mapGetters } from "vuex";

export default {
    name: "MainNavSticky",
    props: {
        isCart: Boolean,
        navStick: Boolean,
    },
    components: {
        MainNavAuth,
        MainNavCart,
    },

    data() {
        return {
          elements: {torty: 'torty', deserty: 'deserty', pirogi: 'pirogi', vypecka: 'vypecka'},
            cartSections: [
                {
                    name: 'MainNavMobile_step1_contacts',
                    link: '#cart-step-1',
                },
                {
                    name: 'MainNavMobile_step2_sposob',
                    link: '#cart-step-2',
                },
                {
                    name: 'MainNavMobile_step3_cart',
                    link: '#cart-step-3',
                },
                {
                    name: 'MainNavMobile_step4_pay',
                    link: '#cart-step-4',
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            sections: 'products/product_sections',
        }),
        isMain() {
            return this.$route.path === '/';
        },
    },
  methods:{
      sectionHandler(section){
        this.$router.push({path: '/', query:{section: section.slug}})
        setTimeout(() => {
          const element = document.getElementById(section.slug);
          if (element) {
            const offset = 130;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
        }, 100);

      },
      sectionClear(){
        this.$router.replace({query:{...this.$route.query,section:'all'}});
      }
  }
}
</script>

<style scoped>
.text-red{
  color: #E10714!important;
}
</style>
