<template>
  <div>
    <div
        v-if="product.stock !== undefined"
        class="catalog-product__stock"
        v-b-tooltip.hover
    >
      <div class="catalog-product__stock-cell"
           :class="{
            'catalog-product__stock-cell-colored': product.stock === 'red',
            'catalog-product__stock-cell-yellow': product.stock === 'yellow',
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"
      ></div>
      <div class="catalog-product__stock-cell"
           :class="{
            'catalog-product__stock-cell-colored': product.stock === 'red',
            'catalog-product__stock-cell-yellow': product.stock === 'yellow',
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
      <div class="catalog-product__stock-cell"
           :class="{
            'catalog-product__stock-cell-yellow': product.stock === 'yellow',
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
      <div class="catalog-product__stock-cell"
           :class="{
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
      <div class="catalog-product__stock-cell"
           :class="{
            'catalog-product__stock-cell-green': product.stock === 'green'
          }"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "CartStock",
  props: {
    product: Object,
  },
}
</script>
<style scoped>
.catalog-product__stock{
  right: -15px!important;
}
@media (max-width: 1022px) {
  .catalog-product__stock{
    display: flex;
    position: absolute;
    top: -26px;
    right: 35px!important;
    cursor: pointer;
  }
}
</style>